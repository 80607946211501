import * as React from 'react';
import Button from '@mui/material/Button';
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, Stack, TextField, Typography } from '@mui/material';
import StatsCard from '../components/StatsCard';
import PeriodStats from '../models/PeriodStats';
import { getFunnelList, getFunnels, getOverviewCharts, getStats, sendDeleteAccountRequest } from '../Api';
import FunnelDto from '../models/dtos/FunnelDto';
import FunnelsCard from '../components/FunnelsCard';
import FullOverviewDto from '../models/dtos/FullOverviewDto';
import OverviewChartsDto from '../models/dtos/OverviewChartsDto';
import { useNavigate } from 'react-router-dom';

function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email);
  }

export default function DeleteMyAccountPage() {
    const [state, setState] = React.useState({ dialogOpen: false, dialogMsg: "", email: "", message: "", dialogTitle: "" });

    const navigate = useNavigate();

    const handleDialogClose = () => {
        setState(prevState => ({...prevState, dialogOpen: false}));
    }

    const onSendClicked = async () => {
        if (!isValidEmail(state.email)){
            setState(prevState => ({...prevState, dialogTitle:"Error", dialogMsg: "Please provide valid email address", dialogOpen: true}));
            return;
        }

        if (state.message.length < 10){
            setState(prevState => ({...prevState, dialogTitle:"Error", dialogMsg: "The message is too short! Please keep in mind that the details you are providing will help us to identify and delete your account", dialogOpen: true}));
            return;
        }

        let result = await sendDeleteAccountRequest(state.email, state.message);
        if (result){
            setState(prevState => ({...prevState, message: "", email:"", dialogTitle:"Success", dialogOpen: true, dialogMsg: "Your request was successfully sent. It will be processed within 48 hours during working days. We may contact you if any additional information is needed to identify account for deletion"}));
        } else {
            setState(prevState => ({...prevState, dialogTitle:"Error", dialogOpen: true, dialogMsg: "It is not possible to process your request right now. Maybe there is another active request for this email. Please wait once its processed or come back a bit later"}));
        }
    }

    return (
        <div>
            <Stack gap="50px 50px" sx={{ mx: 5, my: 5 }} justifyContent='center' alignItems='center'>

                <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4 }} alignItems='center'>
                    <Grid item xs={0} sm={0} md={1} />
                    <Grid item xs={2}>
                        <Typography variant="h3">Delete my account form</Typography>
                    </Grid>
                    <Box width="100%" />
                    <Grid item xs={0} sm={0} md={1} />
                    <Grid item xs={2}>
                        <Typography variant="h6">Your email address for contact:</Typography>
                    </Grid>
                    <Box width="100%" />
                    <Grid item xs={0} sm={0} md={1} />
                    <Grid item xs={2} key="betupId">
                        <TextField label="user@email.com" type="email" variant="filled" value={state.email} fullWidth={true}
                            onChange={e => setState(prevState => ({ ...prevState, email: e.target.value }))} />
                    </Grid>
                    <Box width="100%" />
                    <Grid item xs={0} sm={0} md={1} />
                    <Grid item xs={2}>
                        <Typography sx={{ fontStyle: 'italic' }} variant="h6">Message (provide any information which can help us to identify and delete your account. If you still have access to your account, please use in-app account deletion functionality to simplify the process ):</Typography>
                    </Grid>
                    <Box width="100%" />
                    <Grid item xs={0} sm={0} md={1} />
                    <Grid item xs={2}>
                        <TextField label="message" type="email" variant="filled" multiline={true} rows={5} value={state.message} fullWidth={true}
                            onChange={e => setState(prevState => ({ ...prevState, message: e.target.value }))} />
                    </Grid>
                    <Grid item xs={2} alignItems='center' container>
                    </Grid>
                    <Box width="100%" />
                    <React.Fragment><Grid item xs={0} sm={0} md={1} />
                        <Grid item xs={2}>
                            <Typography variant="h5">After you press 'Confirm' button, the request for account deletion will be sent to us for review. Usually, it is processed within 48 hours during working days. After the request is processed, you will no longer be able to access your BETUP account. This action cannot be undone. If you want to cancel your request, please contact us via contact@betup.org.  </Typography></Grid>
                        <Box width='100%' />
                        <Grid item xs={0} sm={0} md={1} />
                        <Grid item xs={2} key="deleteMatchContainer">
                            <Button variant="contained" fullWidth={true} style={{ height: '54px' }} onClick={() => onSendClicked()}>Send request</Button>
                        </Grid>
                    </React.Fragment>
                </Grid>

            </Stack >
            <Dialog
                open={state.dialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {state.dialogTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {state.dialogMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Got it</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}