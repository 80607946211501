import * as React from 'react';
import Button from '@mui/material/Button';
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Slider, Stack, Switch, TextField, Typography } from '@mui/material';
import { checkStoreCode, createBanner, createShopProduct, deleteBanner, disableShopProduct, disableVideoPlacement, enableShopProduct, enableVideoPlacement, getPromoBanner, getShopEffects, getShopProduct, getVideoPlacement, putBanner, putMatch, updateShopProduct, updateVideoPlacement } from '../Api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import VideoPlacementDto from '../models/dtos/VideoPlacementDto';
import VideoPlacementCard from '../components/VideoPlacementCard';
import VideoRewardOptionsDto from '../models/dtos/VideoRewardOptionsDto';


export default function EditVideoPlacementPage() {
    let editedvideoPlacementId = useParams()['id'];
    let parsedEditedVideoPlacementId = editedvideoPlacementId ?? '';

    const [state, setState] = React.useState({
        videoPlacementPresent: false,
        rewardTypes: ['Betcoins', 'OpenBets'],
        useStaticRewardValue: false,
        videoPlacement: {
            placementId: ''
        } as VideoPlacementDto, dialogOpen: false, dialogMsg: '[]',
    });

    const navigate = useNavigate()
    let isEditMode = editedvideoPlacementId != undefined;


    React.useEffect(() => {


        const fetchData = async () => {

            if (isEditMode) {
                let item = await getVideoPlacement(parsedEditedVideoPlacementId);
                if (item != null) {
                    setState(prevState => ({
                        ...prevState,
                        useStaticRewardValue: item?.rewardOptions.multiplier == null,
                        selectedRewardType: item?.rewardOptions.type,
                        videoPlacement: item ?? { placementId: 'Empty' } as VideoPlacementDto,
                        videoPlacementPresent: true
                    }));
                }
            }
        }

        fetchData();
    }, [])

    const onCreateClicked = async () => {
        var hasError = false;
        let errorMsg = '';


        let rewardOptions = {
            continueForMaxAmountExceeded : state.videoPlacement.rewardOptions.continueForMaxAmountExceeded,
            definedValue: state.useStaticRewardValue ? (state.videoPlacement.rewardOptions.definedValue ?? 0) : null,
            multiplier: state.useStaticRewardValue ? null : state.videoPlacement.rewardOptions.multiplier,
            maxAmount: state.useStaticRewardValue ? state.videoPlacement.rewardOptions.definedValue : state.videoPlacement.rewardOptions.maxAmount,
            type: state.videoPlacement.rewardOptions.type
        } as VideoRewardOptionsDto;

        if (rewardOptions.definedValue && (rewardOptions.definedValue < 1 || rewardOptions.definedValue > 1_000_000_000)) {
            hasError = true;
            errorMsg = "Reward amount should be within 1 and 1,000,000,000 betcoins";
        } else if (!state.useStaticRewardValue && (state.videoPlacement.rewardOptions.maxAmount < 1 || state.videoPlacement.rewardOptions.maxAmount > 1_000_000_000)) {
            hasError = true;
            errorMsg = "Reward max amount should be within 1 and 1,000,000,000 betcoins";
        } else if (!state.useStaticRewardValue && (!state.videoPlacement.rewardOptions.multiplier || state.videoPlacement.rewardOptions.multiplier < 0.1 || state.videoPlacement.rewardOptions.multiplier > 10)) {
            hasError = true;
            errorMsg = "Reward multiplier value should be within 0.1 and 10";
        } else if (!state.videoPlacement.oneTimeOffer && (state.videoPlacement.intervalInHours == 0 || state.videoPlacement.maxTimesPerInterval == 0)) {
            hasError = true;
            errorMsg = "For non one-time-offers interval in hours & max times per interval should not be 0. To hide the offer use 'Disable video placement' button below.'";
        }


        if (!hasError) {

            // Creation of new video placements is not currently supported in dashboard because there is no defined way how it can be automatically picked up on the client side
            let result = await updateVideoPlacement(state.videoPlacement.placementId, state.videoPlacement.intervalInHours, state.videoPlacement.maxTimesPerInterval, state.videoPlacement.oneTimeOffer, rewardOptions, state.videoPlacement.enabled);

            hasError = !result;

            if (!result) {
                errorMsg = "Server error!";
            }
        }

        if (hasError) {
            setState({ ...state, dialogMsg: errorMsg, dialogOpen: true });
        }
        else
            navigate('/video-placements')
    }

    const onRewardTypeSelected = (effectName: string) => {
        let effect = state.rewardTypes.find(x => x == effectName);
        if (effect) {
            setState(prev => ({ ...prev, selectedRewardType: effect, videoPlacement: { ...prev.videoPlacement, rewardOptions: { ...prev.videoPlacement.rewardOptions, type: effect!! } } }));
        } else {
            setState(prev => ({ ...prev, selectedRewardType: effect, videoPlacement: { ...prev.videoPlacement, rewardOptions: { ...prev.videoPlacement.rewardOptions, type: 'None' } } }));
        }

    }

    const onEnableToggleClicked = async () => {
        var hasError = false;
        let errorMsg = '';

        let result = false;

        if (state.videoPlacement.enabled) {
            result = await disableVideoPlacement(state.videoPlacement.placementId);
        } else {
            result = await enableVideoPlacement(state.videoPlacement.placementId);
        }

        hasError = !result;

        if (!result) {
            errorMsg = "Server error!";
        }

        if (hasError) {
            setState({ ...state, dialogMsg: errorMsg, dialogOpen: true });
        }
        else
            navigate('/video-placements')
    }

    const handleDialogClose = () => {
        setState({ ...state, dialogOpen: false });
    }

    return (
        <div>
            <Stack gap="50px 50px" sx={{ mx: 5, my: 5 }} justifyContent='center' alignItems='center'>

                <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4, lg: 6 }} alignItems='center'>
                    <Grid item xs={0} sm={0} md={1} lg={2} />
                    <Grid item xs={2}>
                        <Typography variant="h3">{isEditMode ? "Edit video placement" : "Add new shop item"}</Typography>
                    </Grid>
                    <Box width="100%" />
                    <Grid item xs={0} sm={0} md={1} lg={2} />
                    <Grid item xs={2}>
                        <Typography variant="h6">Video placement details:</Typography>
                    </Grid>
                    <Box width="100%" />
                    {!state.videoPlacementPresent && isEditMode ?
                        <React.Fragment>
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="progress"><CircularProgress size={64} style={{ marginBottom: '32px' }} /></Grid>
                        </React.Fragment> : <React.Fragment>
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="placementId">
                                <TextField label="placementId" variant="filled" value={state.videoPlacement.placementId || ''} fullWidth={true}
                                    onChange={e => { setState(prevState => ({ ...prevState, videoPlacement: { ...prevState.videoPlacement, storeCode: e.target.value } })); }} />
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="activeContainer">
                            <Divider/><br/>
                                <Typography variant="h6">Reward configuration:</Typography>
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={1} key="selectEffect">
                                <Typography variant="subtitle1">Reward type:</Typography>
                                <FormControl fullWidth>
                                    <Select
                                        labelId="banner-simple-select-label"
                                        id="location-simple-select"
                                        value={state.videoPlacement?.rewardOptions?.type ?? 'None'}
                                        onChange={e => onRewardTypeSelected(e.target.value)}
                                        label="Reward type">
                                        {
                                            state.rewardTypes.map(x => <MenuItem value={x} key={x}>{x}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="activeContainer">
                                <FormControlLabel control={<Switch disabled={state.videoPlacement.rewardOptions?.type != 'Betcoins'} checked={state.useStaticRewardValue} onChange={e => setState(prevState => ({ ...prevState, useStaticRewardValue: e.target.checked }))} />} label="Define static reward amount" />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="betcoins">
                                <TextField type='number' disabled={!state.useStaticRewardValue || state.videoPlacement.rewardOptions.type != 'Betcoins'} variant="filled" value={state.videoPlacement.rewardOptions.definedValue || ''} fullWidth={true} label='Static reward value'
                                    onChange={e => { setState(prevState => ({ ...prevState, videoPlacement: { ...prevState.videoPlacement, rewardOptions: { ...prevState.videoPlacement.rewardOptions, definedValue: parseInt(e.target.value) } } })); }} />
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="rewardMultiplier">
                                <Typography variant="h6">Reward multiplier (basing on referenced value): {(state.videoPlacement.rewardOptions.multiplier ?? 0)} times</Typography>
                                <Slider disabled={state.useStaticRewardValue || state.videoPlacement.rewardOptions.type != 'Betcoins'} valueLabelDisplay='off' min={1} max={100} aria-label="Reward multiplier" value={((state.videoPlacement.rewardOptions.multiplier ?? 0) * 10)} onChange={(e, newValue) => { setState(prev => ({ ...prev, videoPlacement: { ...prev.videoPlacement, rewardOptions: { ...prev.videoPlacement.rewardOptions, multiplier: newValue as number / 10 } } })) }} />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="maxAmount">
                                <TextField type='number' disabled={state.useStaticRewardValue || state.videoPlacement.rewardOptions.type != 'Betcoins'} variant="filled" value={state.videoPlacement.rewardOptions.maxAmount || ''} fullWidth={true} label='Max possible reward amount'
                                    onChange={e => { setState(prevState => ({ ...prevState, videoPlacement: { ...prevState.videoPlacement, rewardOptions: { ...prevState.videoPlacement.rewardOptions, maxAmount: parseInt(e.target.value) } } })); }} />
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="activeContainer">
                                <FormControlLabel disabled={state.useStaticRewardValue || state.videoPlacement.rewardOptions.type != 'Betcoins'} control={<Switch checked={!state.videoPlacement.rewardOptions.continueForMaxAmountExceeded} onChange={e => setState(prevState => ({ ...prevState, videoPlacement: { ...prevState.videoPlacement, rewardOptions: { ...prevState.videoPlacement.rewardOptions, continueForMaxAmountExceeded: !e.target.checked } } }))} />} label="Do not show offer if calculated amount is exceeded" />
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="activeContainer">
                                <Divider/><br/>
                                <Typography variant="h6">Time limits:</Typography>
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="activeContainer">
                                <FormControlLabel control={<Switch checked={state.videoPlacement.oneTimeOffer} onChange={e => setState(prevState => ({ ...prevState, videoPlacement: { ...prevState.videoPlacement, oneTimeOffer: e.target.checked } }))} />} label="Is One Time Offer" />
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="effectDuration">
                                <Typography variant="h6">Time Interval: {(state.videoPlacement.intervalInHours ?? 0)} hours</Typography>
                                <Slider disabled={state.videoPlacement.oneTimeOffer} valueLabelDisplay='auto' min={1} max={120} aria-label="Effect duration in days" value={(state.videoPlacement.intervalInHours ?? 0)} onChange={(e, newValue) => { setState(prev => ({ ...prev, videoPlacement: { ...prev.videoPlacement, intervalInHours: newValue as number } })) }} />
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="effectDuration">
                                <Typography variant="h6">Number of times per interval: {(state.videoPlacement.maxTimesPerInterval ?? 0)} times</Typography>
                                <Slider disabled={state.videoPlacement.oneTimeOffer} valueLabelDisplay='auto' min={1} max={100} aria-label="Effect duration in days" value={(state.videoPlacement.maxTimesPerInterval ?? 0)} onChange={(e, newValue) => { setState(prev => ({ ...prev, videoPlacement: { ...prev.videoPlacement, maxTimesPerInterval: newValue as number } })) }} />
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2}>
                                <Typography variant="h6">Preview:</Typography>
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2}>
                                <VideoPlacementCard {...{ item: { ...state.videoPlacement }, showEdit: false }} />
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={isEditMode ? 1 : 2} key="addMatchContainer">
                                <Button variant="contained" fullWidth={true} style={{ height: '54px' }} onClick={() => onCreateClicked()}>{isEditMode ? "Update video placement" : "Add video placement"}</Button>
                            </Grid>
                            {isEditMode ?
                                <Grid item xs={1} key="deleteMatchContainer">
                                    <Button variant="outlined" fullWidth={true} style={{ height: '54px' }} onClick={() => onEnableToggleClicked()}>{state.videoPlacement.enabled ? "Disable video placement" : "Enable video placement"}</Button>
                                </Grid> : <React.Fragment />}
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="goToBannersContainer">
                                <Button variant="outlined" fullWidth={true} style={{ height: '54px' }} onClick={() => navigate('/video-placements/' + state.videoPlacement.placementId + '/stats')}>Show stats for this video placement</Button>
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="goToBannersContainer">
                                <Button variant="outlined" fullWidth={true} style={{ height: '54px' }} onClick={() => navigate('/video-placements')}>Show all video placements</Button>
                            </Grid>
                        </React.Fragment>}
                </Grid>

            </Stack >
            <Dialog
                open={state.dialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    Error
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {state.dialogMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Got it</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}