import * as React from 'react';
import Button from '@mui/material/Button';
import { Box, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from '@mui/material';
import { createBanner, createOrUpdateTemplate, deleteBanner, deleteTemplate, getPromoBanner, getTemplate, putBanner, putMatch } from '../Api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PromoBannerDto from '../models/dtos/PromoBannerDto';
import { getLocalizationsCount, getLocalizedString, getLocalizedStringFor } from '../utils/LocalizationUtil';
import Localization from '../models/Localization';
import PromoBannerCard from '../components/PromoBannerCard';
import TemplateDto from '../models/dtos/TemplateDto';
import MappedTemplateDto from '../models/MappedTemplateDto';
import LocalizedTemplateDto from '../models/dtos/LocalizedTemplateDto';
import LocalizedOfferDto from '../models/dtos/LocalizedOfferDto';


const mapTemplate = (template: TemplateDto) => {
    let a = Object.keys(template.localizations).map(x => ({ x: template.localizations[x].title }));
    let titles = {} as Localization;
    let subtitles = {} as Localization;
    let iconUrls = {} as Localization;
    Object.keys(template.localizations).forEach(x => {
        titles[x] = template.localizations[x].title;
        subtitles[x] = template.localizations[x].subtitle;
        iconUrls[x] = template.localizations[x].iconUrl;
    }
    )
    return {
        id: template.id,
        titles: titles,
        subtitles: subtitles,
        iconUrls: iconUrls,
    } as MappedTemplateDto
}

const unmapTemplate = (template: MappedTemplateDto) => {
    let localizations = {} as LocalizedTemplateDto;

    Object.keys(template.titles).forEach(x => {
        if (localizations[x] == undefined)
            localizations[x] = {} as LocalizedOfferDto;
        localizations[x].title = template.titles[x];
    });
    Object.keys(template.titles).forEach(x => {
        if (localizations[x] == undefined)
        localizations[x] = {} as LocalizedOfferDto;
        localizations[x].subtitle = template.subtitles[x];
    });
    Object.keys(template.titles).forEach(x => {
        if (localizations[x] == undefined)
        localizations[x] = {} as LocalizedOfferDto;
        localizations[x].iconUrl = template.iconUrls[x];
    });
    return {
        id: template.id,
        localizations: localizations
    } as TemplateDto;
}


export default function AddFunnelLocalization() {
    const imgPlaceholder = 'https://static.thenounproject.com/png/3674270-200.png';

    let titlePlaceholder = { en: 'Title placeholder' };
    const descriptionPlaceholder = { en: 'Description placeholder text. Replace it with what you want' }
    const callToActionPlaceholder = { en: 'Call to action' }
    const imgUrlsPlaceholder = { en: imgPlaceholder }

    const [state, setState] = React.useState({
        templatePresent: false,
        titlesString: JSON.stringify(titlePlaceholder),
        subtitlesString: JSON.stringify(descriptionPlaceholder),
        iconUrlsString: JSON.stringify(imgUrlsPlaceholder),
        languages: ['en'],
        previewLanguage: 'en',
        template: {
            id: '',
            titles: titlePlaceholder,
            subtitles: descriptionPlaceholder,
            iconUrls: imgUrlsPlaceholder
        } as MappedTemplateDto, dialogOpen: false, dialogMsg: '[]',
    });
    const navigate = useNavigate()
    let editedBannerId = useParams()['id'] as string;
    let isEditMode = editedBannerId != undefined;


    React.useEffect(() => {

        const fetchData = async () => {

            if (editedBannerId != undefined && editedBannerId != null) {
                let template = await getTemplate(editedBannerId);
                if (template != null) {
                    let mappedTemplate = mapTemplate(template);
                    setState(prevState => ({
                        ...prevState,
                        template: mappedTemplate ?? {} as PromoBannerDto,
                        templatePresent: true,
                        titlesString: JSON.stringify(mappedTemplate?.titles),
                        subtitlesString: JSON.stringify(mappedTemplate?.subtitles),
                        iconUrlsString: JSON.stringify(mappedTemplate?.iconUrls)
                    }));
                    updateLanguageList([mappedTemplate.titles, mappedTemplate.subtitles, mappedTemplate.iconUrls])
                }
            }
        }

        fetchData();
    }, [])

    const onCreateClicked = async () => {
        var hasError = false;
        let errorMsg = '';

        if (state.template.id == undefined || state.template.id.length < 3 || state.template.id.length > 30 || new RegExp('[A-Za-z]+').test(state.template.id) == false) {
            hasError = true;
            errorMsg = "Localization name should be between 3 and 30 characters and contain only latin characters";
        } else if (getLocalizationsCount(state.template.titles) == 0) {
            hasError = true;
            errorMsg = "Localization should have at least single title localization";
        } else if (getLocalizationsCount(state.template.subtitles) == 0) {
            hasError = true;
            errorMsg = "Localization should have at least single subtitle localization";
        } else if (getLocalizationsCount(state.template.iconUrls) == 0) {
            hasError = true;
            errorMsg = "Localization should have at least single iconUrl localization";
        }

        if (!hasError) {
            let result = await createOrUpdateTemplate(unmapTemplate(state.template));

            hasError = !result;

            if (!result) {
                errorMsg = "Server error!";
            }
        }

        if (hasError) {
            setState({ ...state, dialogMsg: errorMsg, dialogOpen: true });
        }
        else
            navigate('/funnels/localizations')
    }

    const onDeleteClicked = async () => {
        var hasError = false;
        let errorMsg = '';

        if (!hasError) {
            let result = await deleteTemplate(state.template.id);

            hasError = !result;

            if (!result) {
                errorMsg = "Server error!";
            }
        }

        if (hasError) {
            setState({ ...state, dialogMsg: errorMsg, dialogOpen: true });
        }
        else
            navigate('/funnels/localizations')
    }

    const handleDialogClose = () => {
        setState({ ...state, dialogOpen: false });
    }

    const setTitles = (value: string) => {
        setState(prevState => ({ ...prevState, titlesString: value }));
        try {
            let result = JSON.parse(value) as Localization
            setState(prevState => ({ ...prevState, template: { ...prevState.template, titles: result } }))
            updateLanguageList([state.template.subtitles, result, state.template.iconUrls])
        } catch (e) {
            setState(prevState => ({ ...prevState, template: { ...prevState.template, titles: {} } }))
            updateLanguageList([state.template.titles, state.template.subtitles, state.template.iconUrls])
        }
    }

    const setDescriptions = (value: string) => {
        setState(prevState => ({ ...prevState, subtitlesString: value }));
        try {
            let result = JSON.parse(value) as Localization
            setState(prevState => ({ ...prevState, template: { ...prevState.template, subtitles: result } }))
            updateLanguageList([state.template.titles, result, state.template.iconUrls])
        } catch (e) {
            setState(prevState => ({ ...prevState, template: { ...prevState.template, subtitles: {} } }))
            updateLanguageList([state.template.titles, state.template.subtitles, state.template.iconUrls])
        }
    }

    const setImgUrls = (value: string) => {
        setState(prevState => ({ ...prevState, iconUrlsString: value }));
        try {
            let result = JSON.parse(value) as Localization
            setState(prevState => ({ ...prevState, template: { ...prevState.template, iconUrls: result } }))
            updateLanguageList([state.template.titles, state.template.subtitles, result])
        } catch (e) {
            setState(prevState => ({ ...prevState, template: { ...prevState.template, iconUrls: {} } }))
            updateLanguageList([state.template.titles, state.template.subtitles, state.template.iconUrls])
        }
    }

    const onLanguageSelected = (value: string) => {
        setState(prevState => ({ ...prevState, previewLanguage: value }));
    }

    const updateLanguageList = (localizations: Localization[]) => {
        let languages = ['en']

        localizations.forEach(localization => {
            Object.keys(localization).forEach(key => {
                if (!languages.includes(key))
                    languages.push(key)
            })
        })
        setState(prevState => ({ ...prevState, languages: languages }))
    }

    return (
        <div>
            <Stack gap="50px 50px" sx={{ mx: 5, my: 5 }} justifyContent='center' alignItems='center'>

                <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4, lg: 6 }} alignItems='center'>
                    <Grid item xs={0} sm={0} md={1} lg={2} />
                    <Grid item xs={2}>
                        <Typography variant="h3">{isEditMode ? "Edit localization template" : "Add new localization template"}</Typography>
                    </Grid>
                    <Box width="100%" />
                    <Grid item xs={0} sm={0} md={1} lg={2} />
                    <Grid item xs={2}>
                        <Typography variant="h6">Localization template:</Typography>
                    </Grid>
                    <Box width="100%" />
                    {!state.templatePresent && isEditMode ?
                        <React.Fragment>
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="progress"><CircularProgress size={64} style={{ marginBottom: '32px' }} /></Grid>
                        </React.Fragment> : <React.Fragment>
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="localizationId">
                                <TextField label="Localization id (only letters)" variant="filled" value={state.template.id || ''} fullWidth={true}
                                    onChange={e => { setState(prevState => ({ ...prevState, template: { ...prevState.template, id: e.target.value } })); }} />
                            </Grid>
                            <Box width="100%" />

                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2}>
                                <Typography variant="h6">Localization details:</Typography>
                            </Grid>

                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="titles">
                                <TextField label={`titles (${getLocalizationsCount(state.template.titles)} parsed)`} variant="filled" value={state.titlesString} fullWidth={true}
                                    onChange={e => setTitles(e.target.value)} />
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="subtitles">
                                <TextField label={`subtitles (${getLocalizationsCount(state.template.subtitles)} parsed)`} variant="filled" value={state.subtitlesString} fullWidth={true}
                                    onChange={e => setDescriptions(e.target.value)} />
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="iconUrls">
                                <TextField label={`iconUrls (${getLocalizationsCount(state.template.iconUrls)} parsed)`} variant="filled" value={state.iconUrlsString} fullWidth={true}
                                    onChange={e => setImgUrls(e.target.value)} />
                            </Grid>

                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2}>
                                <Typography variant="h6">Preview:</Typography>
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={1} key="languageSelect">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Language for preview:</InputLabel>
                                    <Select
                                        labelId="banner-simple-select-label"
                                        id="banner-simple-select"
                                        value={state.previewLanguage}
                                        onChange={e => onLanguageSelected(e.target.value)}
                                        label="Banner">
                                        {
                                            state.languages.map((x) => <MenuItem value={x} key={x}>{x}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="localizationPreview">
                                <Card sx={{ width: '100%' }} >
                                    <CardContent>
                                        <img src={getLocalizedStringFor("", state.previewLanguage, state.template.iconUrls)} width={160} height={128} />
                                        <Typography gutterBottom variant="h6" component="div">
                                            Title: {getLocalizedStringFor("", state.previewLanguage, state.template.titles)}
                                        </Typography>
                                        <Typography gutterBottom variant="body1" component="div">
                                            Subtitle: {getLocalizedStringFor("", state.previewLanguage, state.template.subtitles)}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={isEditMode ? 1 : 2} key="addMatchContainer">
                                <Button variant="contained" fullWidth={true} style={{ height: '54px' }} onClick={() => onCreateClicked()}>{isEditMode ? "Update localization" : "Add localization"}</Button>
                            </Grid>
                            {isEditMode ?
                                <Grid item xs={1} key="deleteMatchContainer">
                                    <Button variant="outlined" fullWidth={true} style={{ height: '54px' }} onClick={() => onDeleteClicked()}>Delete localization</Button>
                                </Grid> : <React.Fragment />}
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="goToBannersContainer">
                                <Button variant="outlined" fullWidth={true} style={{ height: '54px' }} onClick={() => navigate('/funnels/localizations')}>Show all funnel localizations</Button>
                            </Grid>
                        </React.Fragment>}
                </Grid>

            </Stack >
            <Dialog
                open={state.dialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    Error
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {state.dialogMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Got it</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}