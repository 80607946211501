import * as React from 'react';
import Button from '@mui/material/Button';
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from '@mui/material';
import { createBanner, deleteBanner, getPromoBanner, putBanner, putMatch } from '../Api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PromoBannerDto from '../models/dtos/PromoBannerDto';
import { getLocalizationsCount, getLocalizedString, getLocalizedStringFor } from '../utils/LocalizationUtil';
import Localization from '../models/Localization';
import PromoBannerCard from '../components/PromoBannerCard';


export default function AddPromoBannerPage() {
    const imgPlaceholder = 'https://static.thenounproject.com/png/3674270-200.png';

    let titlePlaceholder = { en: 'Title placeholder' };
    const descriptionPlaceholder = { en: 'Description placeholder text. Replace it with what you want' }
    const callToActionPlaceholder = { en: 'Call to action' }
    const imgUrlsPlaceholder = { en: imgPlaceholder }

    const [state, setState] = React.useState({
        bannerPresent: false,
        titlesString: JSON.stringify(titlePlaceholder),
        descriptionsString: JSON.stringify(descriptionPlaceholder),
        imgUrlsString: JSON.stringify(imgUrlsPlaceholder),
        callToActionsString: JSON.stringify(callToActionPlaceholder),
        languages: ['en'],
        previewLanguage: 'en',
        locations: ['Header', 'Bets'],
        banner: {
            id: '',
            name: '',
            titles: titlePlaceholder,
            descriptions: descriptionPlaceholder,
            callToActions: callToActionPlaceholder,
            imgUrls: imgUrlsPlaceholder,
            location: 'Bets'
        } as PromoBannerDto, dialogOpen: false, dialogMsg: '[]',
    });
    const navigate = useNavigate()
    let editedBannerId = useParams()['id'] as string;
    let isEditMode = editedBannerId != undefined;


    React.useEffect(() => {

        const fetchData = async () => {

            if (editedBannerId != undefined && editedBannerId != null) {
                let banner = await getPromoBanner(editedBannerId);
                if (banner != null) {
                    setState(prevState => ({
                        ...prevState,
                        banner: banner ?? { location: 'Bets' } as PromoBannerDto,
                        bannerPresent: true,
                        titlesString: JSON.stringify(banner?.titles),
                        descriptionsString: JSON.stringify(banner?.descriptions),
                        callToActionsString: JSON.stringify(banner?.callToActions),
                        imgUrlsString: JSON.stringify(banner?.imgUrls)
                    }));
                    updateLanguageList([banner.titles, banner.descriptions, banner.imgUrls, banner.callToActions])
                }
            }
        }

        fetchData();
    }, [])

    const onCreateClicked = async () => {
        var hasError = false;
        let errorMsg = '';

        if (state.banner.name == undefined || state.banner.name.length < 1) {
            hasError = true;
            errorMsg = "No valid banner name is provided!";
        } else if (getLocalizationsCount(state.banner.titles) == 0) {
            hasError = true;
            errorMsg = "Promo should have at least single title localization";
        } else if (getLocalizationsCount(state.banner.descriptions) == 0) {
            hasError = true;
            errorMsg = "Promo should have at least single description localization";
        } else if (getLocalizationsCount(state.banner.titles) == 0) {
            hasError = true;
            errorMsg = "Promo should have at least single call to action localization";
        }

        if (!hasError) {
            let result = isEditMode ?
                await putBanner(state.banner.id, state.banner.name, state.banner.titles, state.banner.descriptions, state.banner.imgUrls, state.banner.callToActions, state.banner.location) :
                await createBanner(state.banner.name, state.banner.titles, state.banner.descriptions, state.banner.imgUrls, state.banner.callToActions, state.banner.location)

            hasError = !result;

            if (!result) {
                errorMsg = "Server error!";
            }
        }

        if (hasError) {
            setState({ ...state, dialogMsg: errorMsg, dialogOpen: true });
        }
        else
            navigate('/promo-banners')
    }

    const onDeleteClicked = async () => {
        var hasError = false;
        let errorMsg = '';

        if (!hasError) {
            let result = await deleteBanner(state.banner.id);

            hasError = !result;

            if (!result) {
                errorMsg = "Server error!";
            }
        }

        if (hasError) {
            setState({ ...state, dialogMsg: errorMsg, dialogOpen: true });
        }
        else
            navigate('/promo-banners')
    }

    const handleDialogClose = () => {
        setState({ ...state, dialogOpen: false });
    }

    const setTitles = (value: string) => {
        setState(prevState => ({ ...prevState, titlesString: value }));
        try {
            let result = JSON.parse(value) as Localization
            setState(prevState => ({ ...prevState, banner: { ...prevState.banner, titles: result } }))
            updateLanguageList([state.banner.descriptions, state.banner.callToActions, result, state.banner.imgUrls])
        } catch (e) {
            setState(prevState => ({ ...prevState, banner: { ...prevState.banner, titles: {} } }))
            updateLanguageList([state.banner.titles, state.banner.descriptions, state.banner.imgUrls, state.banner.callToActions])
        }
    }

    const setDescriptions = (value: string) => {
        setState(prevState => ({ ...prevState, descriptionsString: value }));
        try {
            let result = JSON.parse(value) as Localization
            setState(prevState => ({ ...prevState, banner: { ...prevState.banner, descriptions: result } }))
            updateLanguageList([state.banner.titles, state.banner.callToActions, result, state.banner.imgUrls])
        } catch (e) {
            setState(prevState => ({ ...prevState, banner: { ...prevState.banner, descriptions: {} } }))
            updateLanguageList([state.banner.titles, state.banner.descriptions, state.banner.imgUrls, state.banner.callToActions])
        }
    }

    const setCallToActions = (value: string) => {
        setState(prevState => ({ ...prevState, callToActionsString: value }));
        try {
            let result = JSON.parse(value) as Localization
            setState(prevState => ({ ...prevState, banner: { ...prevState.banner, callToActions: result } }))
            updateLanguageList([state.banner.titles, state.banner.descriptions, result, state.banner.imgUrls])
        } catch (e) {
            setState(prevState => ({ ...prevState, banner: { ...prevState.banner, callToActions: {} } }))
            updateLanguageList([state.banner.titles, state.banner.descriptions, state.banner.imgUrls, state.banner.callToActions])
        }
    }

    const setImgUrls = (value: string) => {
        setState(prevState => ({ ...prevState, imgUrlsString: value }));
        try {
            let result = JSON.parse(value) as Localization
            setState(prevState => ({ ...prevState, banner: { ...prevState.banner, imgUrls: result } }))
            updateLanguageList([state.banner.titles, state.banner.descriptions, result, state.banner.callToActions])
        } catch (e) {
            setState(prevState => ({ ...prevState, banner: { ...prevState.banner, imgUrls: {} } }))
            updateLanguageList([state.banner.titles, state.banner.descriptions, state.banner.imgUrls, state.banner.callToActions])
        }
    }

    const onLanguageSelected = (value: string) => {
        setState(prevState => ({ ...prevState, previewLanguage: value }));
    }

    const updateLanguageList = (localizations: Localization[]) => {
        let languages = ['en']

        localizations.forEach(localization => {
            Object.keys(localization).forEach(key => {
                if (!languages.includes(key))
                    languages.push(key)
            })
        })
        setState(prevState => ({ ...prevState, languages: languages }))
    }

    return (
        <div>
            <Stack gap="50px 50px" sx={{ mx: 5, my: 5 }} justifyContent='center' alignItems='center'>

                <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4, lg: 6 }} alignItems='center'>
                    <Grid item xs={0} sm={0} md={1} lg={2} />
                    <Grid item xs={2}>
                        <Typography variant="h3">{isEditMode ? "Edit promo banner" : "Add new promo banner"}</Typography>
                    </Grid>
                    <Box width="100%" />
                    <Grid item xs={0} sm={0} md={1} lg={2} />
                    <Grid item xs={2}>
                        <Typography variant="h6">Banner details:</Typography>
                    </Grid>
                    <Box width="100%" />
                    {!state.bannerPresent && isEditMode ?
                        <React.Fragment>
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="progress"><CircularProgress size={64} style={{ marginBottom: '32px' }} /></Grid>
                        </React.Fragment> : <React.Fragment>
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="bannerName">
                                <TextField label="bannerName" variant="filled" value={state.banner.name || ''} fullWidth={true}
                                    onChange={e => { setState(prevState => ({ ...prevState, banner: { ...prevState.banner, name: e.target.value } })); }} />
                            </Grid>
                            <Box width="100%" />

                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2}>
                                <Typography variant="h6">Promo details:</Typography>
                            </Grid>

                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="titles">
                                <TextField label={`titles (${getLocalizationsCount(state.banner.titles)} parsed)`} variant="filled" value={state.titlesString} fullWidth={true}
                                    onChange={e => setTitles(e.target.value)} />
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="descriptions">
                                <TextField label={`descriptions (${getLocalizationsCount(state.banner.descriptions)} parsed)`} variant="filled" value={state.descriptionsString} fullWidth={true}
                                    onChange={e => setDescriptions(e.target.value)} />
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="callToActions">
                                <TextField label={`callToActions (${getLocalizationsCount(state.banner.callToActions)} parsed)`} variant="filled" value={state.callToActionsString} fullWidth={true}
                                    onChange={e => setCallToActions(e.target.value)} />
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="imgUrls">
                                <TextField label={`imgUrls (${getLocalizationsCount(state.banner.imgUrls)} parsed)`} variant="filled" value={state.imgUrlsString} fullWidth={true}
                                    onChange={e => setImgUrls(e.target.value)} />
                            </Grid>

                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={1} key="locationSelect">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-location-select-label">{state.banner.location ?? "Select location"}</InputLabel>
                                    <Select
                                        labelId="banner-simple-select-label"
                                        id="location-simple-select"
                                        value={state.banner.location}
                                        onChange={e => setState(prev => ({ ...prev, banner: { ...prev.banner, location: e.target.value } }))}
                                        label="Location">
                                        {
                                            state.locations.map(x => <MenuItem value={x} key={x}>{x}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2}>
                                <Typography variant="h6">Preview:</Typography>
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={1} key="languageSelect">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Language for preview:</InputLabel>
                                    <Select
                                        labelId="banner-simple-select-label"
                                        id="banner-simple-select"
                                        value={state.previewLanguage}
                                        onChange={e => onLanguageSelected(e.target.value)}
                                        label="Banner">
                                        {
                                            state.languages.map((x) => <MenuItem value={x} key={x}>{x}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2}>
                                <PromoBannerCard {...{ banner: state.banner, showEdit: false, previewLanguage: state.previewLanguage }} />
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={isEditMode ? 1 : 2} key="addMatchContainer">
                                <Button variant="contained" fullWidth={true} style={{ height: '54px' }} onClick={() => onCreateClicked()}>{isEditMode ? "Update banner" : "Add banner"}</Button>
                            </Grid>
                            {isEditMode ?
                                <Grid item xs={1} key="deleteMatchContainer">
                                    <Button variant="outlined" fullWidth={true} style={{ height: '54px' }} onClick={() => onDeleteClicked()}>Delete banner</Button>
                                </Grid> : <React.Fragment />}
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="goToBannersContainer">
                                <Button variant="outlined" fullWidth={true} style={{ height: '54px' }} onClick={() => navigate('/promo-banners')}>Show all banners</Button>
                            </Grid>
                        </React.Fragment>}
                </Grid>

            </Stack >
            <Dialog
                open={state.dialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    Error
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {state.dialogMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Got it</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}