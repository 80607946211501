import * as React from 'react';
import Button from '@mui/material/Button';
import { Box, Card, CardActions, CardContent, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { getPromoBanners, getTemplates } from '../Api';
import { useNavigate } from 'react-router-dom';
import PromoBannerDto from '../models/dtos/PromoBannerDto';
import PromoBannerCard from '../components/PromoBannerCard';
import TemplateDto from '../models/dtos/TemplateDto';

export const formatRevenue = (revenue: number) => {
    return revenue.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
}

export default function FunnelLocalizationsPage() {
    const [state, setState] = React.useState({
        templatesLoaded: false,
        templates: [] as TemplateDto[]
    });

    const navigate = useNavigate()


    React.useEffect(() => {

        const fetchData = async () => {
            let templates = await getTemplates();
            setState(prevState => ({ ...prevState, templates: templates, templatesLoaded: true }));
        }

        fetchData();
    }, [])

    return (
        <Stack gap="50px 50px" sx={{ mx: 5, my: 5 }} justifyContent='center' alignItems='center'>
            <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4, lg: 5 }} justifyContent="flex-end">
                <Grid item xs={1} key="addButton" alignContent="center">
                    <Button variant="contained" fullWidth={true} style={{ height: '54px' }} onClick={() => navigate('/funnels/localizations/create')}>Add funnel localization</Button>
                </Grid>
                <Grid item xs={1} key="matchesButton" alignContent="center">
                    <Button variant="outlined" fullWidth={true} style={{ height: '54px' }} onClick={() => navigate('/funnels')}>Go to funnels</Button>
                </Grid>
            </Grid>
            <Typography variant="h3">Funnel localizations</Typography>
            {!state.templatesLoaded ? <Grid item xs={2}><CircularProgress size='25%' style={{ marginBottom: '32px', marginLeft: '35%' }} /></Grid> : <div />}
            <Grid container spacing={5} columns={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 4 }}>
                <Box width='100%' />
                {
                    state.templates.map(x =>
                        <Grid item xs={1} key={x.id}>
                            <Card sx={{ width: '100%' }} >
                                <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                        {x.id}
                                    </Typography>
                                    <img src={x.localizations['en']?.iconUrl ?? ''} width={160} height={128} />
                                    <Typography gutterBottom variant="h6" component="div">
                                        Title: {x.localizations['en']?.title ?? ''}
                                    </Typography>
                                    <Typography gutterBottom variant="body1" component="div">
                                        Subtitle: {x.localizations['en']?.subtitle ?? ''}
                                    </Typography>
                                </CardContent>
                                <CardActions >
                                    <Button size="small" color="primary" variant="outlined" sx={{ marginLeft: 'auto' }} onClick={e => navigate('/funnels/localizations/' + x.id + '/edit')}>
                                        Edit
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>)
                }
            </Grid>
        </Stack >
    );
}