import { Button, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import BetlistShortDto from "../models/dtos/BetlistShortDto";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getBalancePaginated, getBetlistsPaginated } from "../Api";
import BalanceHistoryRecordDto from "../models/dtos/BalanceHistoryRecordDto";

interface BalanceTableProps {
    userId: number,
    limit: number
}

export default function BetlistsTable(props: BalanceTableProps) {

    const [state, setState] = React.useState({
        records: [] as BalanceHistoryRecordDto[],
        loaded: false
    });


    const fetchData = async () => {
        let records = await getBalancePaginated(props.userId, 0, props.limit);
        setState(prevState => ({ ...prevState, records: records?.items ?? [], loaded: records != null }));
    }

    const navigate = useNavigate();

    const getBalanceBackground = (oldBalance: number, newBalance: number) => {
        if (oldBalance > newBalance)
            return '#FFCCCB';
        else
            return 'lightGreen';
    }

    React.useEffect(() => {
        fetchData();
    }, []);

    return (
        !state.loaded ? <CircularProgress size='100px' style={{ marginBottom: '32px' }} /> : <Table>
            <TableHead>
                <TableRow>
                    <TableCell align="left">Reason</TableCell>
                    <TableCell align="left">Change</TableCell>
                    <TableCell align="left">When</TableCell>
                    <TableCell align="left">Old balance</TableCell>
                    <TableCell align="left">New balance</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {state.records.map(item => (
                    <React.Fragment>
                        <TableRow>
                            <TableCell sx={{ background: getBalanceBackground(item.oldValue, item.newValue) }}>
                                {item.reason}
                            </TableCell>
                            <TableCell sx={{ background: getBalanceBackground(item.oldValue, item.newValue) }}>
                                <b>{item.oldValue > item.newValue ? '-' : '+'}{item.change}</b>
                            </TableCell>
                            <TableCell sx={{ background: getBalanceBackground(item.oldValue, item.newValue) }}>
                                {new Date(item.date).toLocaleString()}
                            </TableCell>
                            <TableCell sx={{ background: getBalanceBackground(item.oldValue, item.newValue) }}>
                                {item.oldValue}
                            </TableCell>
                            <TableCell sx={{ background: getBalanceBackground(item.oldValue, item.newValue) }}>
                                {item.newValue}
                            </TableCell>
                        </TableRow>
                    </React.Fragment>
                ))}
            </TableBody>
        </Table>);
}