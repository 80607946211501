import * as React from 'react';
import Button from '@mui/material/Button';
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Slider, Stack, Switch, TextField, Typography } from '@mui/material';
import { checkStoreCode, createBanner, createShopProduct, deleteBanner, disableShopProduct, enableShopProduct, getPromoBanner, getShopEffects, getShopProduct, putBanner, putMatch, updateShopProduct } from '../Api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PromoBannerDto from '../models/dtos/PromoBannerDto';
import { getLocalizationsCount, getLocalizedFromString, getLocalizedString, getLocalizedStringFor } from '../utils/LocalizationUtil';
import Localization from '../models/Localization';
import PromoBannerCard from '../components/PromoBannerCard';
import ShopItemDto from '../models/dtos/ShopItemDto';
import ShopItemCard from '../components/ShopItemCard';
import ShopEffectDto from '../models/dtos/ShopEffectDto';


export default function AddShopItemPage() {
    const imgPlaceholder = 'https://static.thenounproject.com/png/3674270-200.png';

    let titlePlaceholder = {};
    const descriptionPlaceholder = { en: 'Description placeholder text. Replace it with what you want' }
    const callToActionPlaceholder = { en: 'Call to action' }
    let editedShopItemId = useParams()['id'];
    let parsedEditedShopItemId = parseInt(editedShopItemId ?? '0');

    const [state, setState] = React.useState({
        shopItemPresent: false,
        languages: ['en'],
        previewLanguage: 'en',
        titles: titlePlaceholder,
        types: ['Regular', 'Banner'],
        effects: [] as ShopEffectDto[],
        selectedEffect: undefined as (string | undefined),
        shopItem: {
            id: parsedEditedShopItemId,
            betcoins: 0,
            effectDuration: 0,
            titleText: '{}',
            effectId: undefined,
            iconUrl: imgPlaceholder,
            type: 'Regular',
            deleted: false,
            noAds: false,
            interestFactor: 0
        } as ShopItemDto, dialogOpen: false, dialogMsg: '[]',
    });

    const navigate = useNavigate()
    let isEditMode = editedShopItemId != undefined;


    React.useEffect(() => {


        const fetchData = async () => {

            if (isEditMode) {
                let item = await getShopProduct(parsedEditedShopItemId);
                if (item != null) {
                    setState(prevState => ({
                        ...prevState,
                        selectedEffect: item!.effectName,
                        shopItem: item ?? { type: 'Regular' } as ShopItemDto,
                        shopItemPresent: true,
                        titlesString: item?.titleText ?? "{}",
                    }));
                    if (item?.titleText != null) {
                        updateLanguageList([JSON.parse(item.titleText)]);
                    }

                    if (item.titleText != null) {
                        setTitles(item.titleText);
                    }
                }
            }

            let effects = await getShopEffects();
            if (effects != null) {
                setState(prevState => ({
                    ...prevState, effects: effects!
                }));
            }
        }

        fetchData();
    }, [])

    const onCreateClicked = async () => {
        var hasError = false;
        let errorMsg = '';

        if (state.selectedEffect) {

        }

        if (state.shopItem.betcoins < 0 || state.shopItem.betcoins > 10000000000) {
            hasError = true;
            errorMsg = "Betcoins value should be within 0 - 10000000000 range!";
        } else if (!state.shopItem.storeCode) {
            hasError = true;
            errorMsg = "The store code you provided is too short! shop item should have at least single title localization";
        } else if ((await checkStoreCode(state.shopItem.storeCode!, state.shopItem.id))?.isUsed == false) {
            hasError = true;
            errorMsg = "The store code you provided is already used for another product!";
        } else if (!state.shopItem.visualPrice || !(new RegExp("[0-9]+\.[0-9]+").test(state.shopItem.visualPrice))) {
            hasError = true;
            errorMsg = "Product price is malformed, it should be floating number value like (XX.XX)";
        } else if (state.shopItem.type != 'Regular' && getLocalizationsCount(state.titles) == 0) {
            hasError = true;
            errorMsg = "Banner shop item should have at least single title localization";
        } else if (state.shopItem.color != undefined && state.shopItem.color?.length > 0 && !(new RegExp("#[a-f0-9]+").test(state.shopItem.color))) {
            hasError = true;
            errorMsg = "Color is malformed!";
        } else if (!hasError) {
            let result = isEditMode ?
                await updateShopProduct(state.shopItem.id, state.shopItem.storeCode!, state.shopItem.type, state.shopItem.betcoins, state.shopItem.visualPrice!, state.shopItem.noAds, state.shopItem.interestFactor!, state.shopItem.effectId, state.shopItem.titleText, state.shopItem.iconUrl, state.shopItem.color, state.shopItem.effectDuration) :
                await createShopProduct(state.shopItem.storeCode!, state.shopItem.type, state.shopItem.betcoins, state.shopItem.visualPrice!, state.shopItem.noAds, state.shopItem.interestFactor!, state.shopItem.effectId, state.shopItem.titleText, state.shopItem.iconUrl, state.shopItem.color, state.shopItem.effectDuration);

            hasError = !result;

            if (!result) {
                errorMsg = "Server error!";
            }
        }

        if (hasError) {
            setState({ ...state, dialogMsg: errorMsg, dialogOpen: true });
        }
        else
            navigate('/shop')
    }

    const onEffectSelected = (effectName: string) => {
        let effect = state.effects.find(x => x.name == effectName);
        if (effect) {
            setState(prev => ({ ...prev, selectedEffect: effect?.name, shopItem: { ...prev.shopItem, effectId: effect!.id, effectName: effect!.name, effectDuration: prev.shopItem.effectDuration ?? 0 } }));
        } else {
            setState(prev => ({ ...prev, selectedEffect: undefined, shopItem: { ...prev.shopItem, effectId: undefined, effectName: undefined } }));
        }

    }

    const onEnableToggleClicked = async () => {
        var hasError = false;
        let errorMsg = '';

        let result = false;

        if (!state.shopItem.deleted) {
            result = await disableShopProduct(state.shopItem.id);
        } else {
            result = await enableShopProduct(state.shopItem.id);
        }

        hasError = !result;

        if (!result) {
            errorMsg = "Server error!";
        }

        if (hasError) {
            setState({ ...state, dialogMsg: errorMsg, dialogOpen: true });
        }
        else
            navigate('/shop')
    }

    const handleDialogClose = () => {
        setState({ ...state, dialogOpen: false });
    }

    const setTitles = (value: string) => {
        setState(prevState => ({ ...prevState, shopItem: { ...prevState.shopItem, titleText: value } }));
        try {
            let result = JSON.parse(value) as Localization
            setState(prevState => ({ ...prevState, titles: result, shopItem: { ...prevState.shopItem } }))
            updateLanguageList([result])
        } catch (e) {
            setState(prevState => ({ ...prevState, titles: {}, banner: { ...prevState.shopItem } }))
            updateLanguageList([state.titles])
        }
    }

    const setIconUrl = (value: string) => {
        setState(prevState => ({ ...prevState, shopItem: { ...prevState.shopItem, iconUrl: value } }));
    }

    const onLanguageSelected = (value: string) => {
        setState(prevState => ({ ...prevState, previewLanguage: value }));
    }

    const updateLanguageList = (localizations: Localization[]) => {
        let languages = ['en']

        localizations.forEach(localization => {
            Object.keys(localization).forEach(key => {
                if (!languages.includes(key))
                    languages.push(key)
            })
        })
        setState(prevState => ({ ...prevState, languages: languages }))
    }

    return (
        <div>
            <Stack gap="50px 50px" sx={{ mx: 5, my: 5 }} justifyContent='center' alignItems='center'>

                <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4, lg: 6 }} alignItems='center'>
                    <Grid item xs={0} sm={0} md={1} lg={2} />
                    <Grid item xs={2}>
                        <Typography variant="h3">{isEditMode ? "Edit shop item" : "Add new shop item"}</Typography>
                    </Grid>
                    <Box width="100%" />
                    <Grid item xs={0} sm={0} md={1} lg={2} />
                    <Grid item xs={2}>
                        <Typography variant="h6">Shop item details:</Typography>
                    </Grid>
                    <Box width="100%" />
                    {!state.shopItemPresent && isEditMode ?
                        <React.Fragment>
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="progress"><CircularProgress size={64} style={{ marginBottom: '32px' }} /></Grid>
                        </React.Fragment> : <React.Fragment>
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="storeCode">
                                <TextField label="storeCode" variant="filled" value={state.shopItem.storeCode || ''} fullWidth={true}
                                    onChange={e => { setState(prevState => ({ ...prevState, shopItem: { ...prevState.shopItem, storeCode: e.target.value } })); }} />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="betcoins">
                                <TextField type='number' label="betcoins" variant="filled" value={state.shopItem.betcoins || ''} fullWidth={true}
                                    onChange={e => { setState(prevState => ({ ...prevState, shopItem: { ...prevState.shopItem, betcoins: parseInt(e.target.value) } })); }} />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="color">
                                <TextField label="color" variant="filled" value={state.shopItem.color || ''} fullWidth={true}
                                    onChange={e => { setState(prevState => ({ ...prevState, shopItem: { ...prevState.shopItem, color: e.target.value } })); }} />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="approximate price">
                                <TextField type="number" label="approximative price (in $)" placeholder='0.99' variant="filled" value={state.shopItem.visualPrice || ''} fullWidth={true}
                                    onChange={e => { setState(prevState => ({ ...prevState, shopItem: { ...prevState.shopItem, visualPrice: e.target.value } })); }} />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="titles">
                                <TextField label={`titles (${getLocalizationsCount(state.titles)} parsed)`} variant="filled" value={state.shopItem.titleText} fullWidth={true}
                                    onChange={e => setTitles(e.target.value)} />
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="imgUrls">
                                <TextField label="iconUrl" variant="filled" value={state.shopItem.iconUrl} fullWidth={true}
                                    onChange={e => setIconUrl(e.target.value)} />
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="interestFactor">
                                <Typography variant="h6">Interest factor:</Typography>
                                <Slider valueLabelDisplay='auto' aria-label="Interest factor in %" value={(state.shopItem.interestFactor ?? 0)} onChange={(e, newValue) => { setState(prev => ({ ...prev, shopItem: { ...prev.shopItem, interestFactor: newValue as number } })) }} />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={1} key="selectEffect">
                                <Typography variant="h6">Bonus effect:</Typography>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-location-select-label">None</InputLabel>
                                    <Select
                                        labelId="banner-simple-select-label"
                                        id="location-simple-select"
                                        value={state.selectedEffect}
                                        onChange={e => onEffectSelected(e.target.value)}
                                        label="Effect">
                                        {
                                            state.effects.map(x => <MenuItem value={x.name} key={x.id}>{getLocalizedFromString("No name", "en", x.name)}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="effectDuration">
                                <Typography variant="h6">Effect duration in days:</Typography>
                                <Slider valueLabelDisplay='auto' min={0} max={30} aria-label="Effect duration in days" value={(state.shopItem.effectDuration ?? 0)} onChange={(e, newValue) => { setState(prev => ({ ...prev, shopItem: { ...prev.shopItem, effectDuration: newValue as number } })) }} />
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={1} key="typeselect">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-location-select-label">{state.shopItem.type ?? "Select type"}</InputLabel>
                                    <Select
                                        labelId="banner-simple-select-label"
                                        id="location-simple-select"
                                        value={state.shopItem.type}
                                        onChange={e => setState(prev => ({ ...prev, shopItem: { ...prev.shopItem, type: e.target.value } }))}
                                        label="Type">
                                        {
                                            state.types.map(x => <MenuItem value={x} key={x}>{x}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={1} key="addButton" alignContent="center" alignItems='center'>
                                <FormControlLabel control={<Switch checked={state.shopItem.noAds} onChange={e => setState(prev => ({ ...prev, shopItem: { ...prev.shopItem, noAds: e.target.checked } }))} />} label="Disable ads after purchase" />
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2}>
                                <Typography variant="h6">Preview:</Typography>
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={1} key="languageSelect">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Language for preview:</InputLabel>
                                    <Select
                                        labelId="banner-simple-select-label"
                                        id="banner-simple-select"
                                        value={state.previewLanguage}
                                        onChange={e => onLanguageSelected(e.target.value)}
                                        label="Banner">
                                        {
                                            state.languages.map((x) => <MenuItem value={x} key={x}>{x}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2}>
                                <ShopItemCard {...{ item: { ...state.shopItem, titleText: JSON.stringify(state.titles) }, showEdit: false, previewLanguage: state.previewLanguage }} />
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={isEditMode ? 1 : 2} key="addMatchContainer">
                                <Button variant="contained" fullWidth={true} style={{ height: '54px' }} onClick={() => onCreateClicked()}>{isEditMode ? "Update shop item" : "Add shop item"}</Button>
                            </Grid>
                            {isEditMode ?
                                <Grid item xs={1} key="deleteMatchContainer">
                                    <Button variant="outlined" fullWidth={true} style={{ height: '54px' }} onClick={() => onEnableToggleClicked()}>{state.shopItem.deleted ? "Enable shop item" : "Disable shop item"}</Button>
                                </Grid> : <React.Fragment />}
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="goToBannersContainer">
                                <Button variant="outlined" fullWidth={true} style={{ height: '54px' }} onClick={() => navigate('/shop')}>Show all shop items</Button>
                            </Grid>
                        </React.Fragment>}
                </Grid>

            </Stack >
            <Dialog
                open={state.dialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    Error
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {state.dialogMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Got it</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}