import * as React from 'react';
import Button from '@mui/material/Button';
import { Autocomplete, Box, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography, debounce } from '@mui/material';
import StatsCard from '../components/StatsCard';
import PeriodStats from '../models/PeriodStats';
import { getFunnels, getMatchDetails, getMatchPromoLink, getOverviewCharts, getPromoBanners, getPromoMatch, getPromoMatchCharts, getPromoMatchStats, getPromoMatches, getPromoMatchesCharts, getPromoMatchesStats, getSearchMatches, getStats, putMatch } from '../Api';
import FunnelDto from '../models/dtos/FunnelDto';
import FunnelsCard from '../components/FunnelsCard';
import FullOverviewDto from '../models/dtos/FullOverviewDto';
import OverviewChartsDto from '../models/dtos/OverviewChartsDto';
import PromoMatchDto from '../models/dtos/PromoMatchDto';
import PromoMatchCard from '../components/PromoMatchCard';
import { useNavigate, useParams } from 'react-router-dom';
import PromoBannerDto from '../models/dtos/PromoBannerDto';
import MatchDto from '../models/dtos/MatchDto';
import { ChangeEvent, useCallback } from 'react';
import { getLocalizedString } from '../utils/LocalizationUtil';
import PieStatsCard from '../components/PieStatsCard';
import PromoMatchStat from '../models/dtos/PromoMatchStat';
import { BarChart, LineChart, PieChart } from '@mui/x-charts';
import PromoMatchChart from '../models/dtos/PromoMatchChart';
import ChartRecordDto from '../models/dtos/ChartRecordDto';
import PromoMatchesStat from '../models/dtos/PromoMatchesStat';

export interface Cancelable {
    clear(): void;
}

export default function PromoMatchStatsPage() {
    const [state, setState] = React.useState({
        statsPresent: false,
        isFinished: false,
        periods: ['All', 'Month', 'Week', 'Yesterday', 'Today'],
        period: 'All',
        stats: {} as PromoMatchesStat,
        timeChart: { views: [] as ChartRecordDto[], clicks: [] as ChartRecordDto[] } as PromoMatchChart
    });
    const navigate = useNavigate()

    React.useEffect(() => {

        const fetchData = async () => {
            setState({ ...state });
            let [timeChart, statResult] = await Promise.all([await getPromoMatchesCharts(), await fetchAndApplyStats(state.period)]);
            if (timeChart != null) {
                setState(prev => ({ ...prev, timeChart: timeChart! }));
            }
        }

        fetchData();
    }, [])

    const fetchAndApplyStats = async (period: string) => {
        let result = await getPromoMatchesStats(period)
        if (result != null) {
            setState(prev => ({ ...prev, statsPresent: true, stats: result! }));
        }
    }

    const onPeriodSelected = async (newPeriod: string) => {
        setState(prev => ({ ...state, period: newPeriod, statsPresent: false }));
        await fetchAndApplyStats(newPeriod)
    }

    return (
        <div>
            <Stack gap="50px 50px" sx={{ mx: 5, my: 5 }} justifyContent='center' alignItems='center'>

                <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4, lg: 6 }} alignItems='center'>
                    <Grid item xs={0} sm={0} md={1} lg={2} />
                    <Grid item xs={2}>
                        <Typography variant="h3">Promo matches statistics</Typography>
                    </Grid>
                    <Box width='100%' />
                    <Grid item xs={0} sm={0} md={1} lg={2} />
                    <Grid item xs={2} key="periodSelect">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Period for stats:</InputLabel>
                            <Select
                                labelId="banner-simple-select-label"
                                id="banner-simple-select"
                                value={state.period}
                                onChange={e => onPeriodSelected(e.target.value)}
                                label="Banner">
                                {
                                    state.periods.map((x) => <MenuItem value={x} key={x}>{x}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Box width="100%" />
                    {state.timeChart.clicks.length > 0 ?
                        <React.Fragment>
                            <Grid item xs={2}>
                                <Card sx={{ width: '100%', height: '400px' }} variant='outlined'>
                                    <CardContent>
                                        <Typography variant="h5" display="inline">Timeline</Typography>
                                        <Divider /><br />
                                        <LineChart
                                            xAxis={[{ scaleType: 'time', data: state.timeChart.clicks?.map(x => new Date(x.date)) }] ?? []}
                                            series={[
                                                {
                                                    label: 'Clicks',
                                                    data: state.timeChart.clicks.map(x => x.value),
                                                },
                                                {
                                                    label: 'Views',
                                                    data: state.timeChart.views.map(x => x.value),
                                                }
                                            ]}
                                            height={300}
                                        />

                                    </CardContent>
                                </Card>
                            </Grid></React.Fragment> : <React.Fragment></React.Fragment>
                    }
                    {!state.statsPresent ? <React.Fragment>
                        <Grid item xs={0} sm={0} md={1} lg={2} /><Grid item xs={2} ><CircularProgress size='25%' style={{ marginBottom: '32px', marginLeft: '35%' }} />
                        </Grid></React.Fragment> :
                        <React.Fragment>
                            <Grid item xs={2}>
                                <Card sx={{ width: '100%', height: '400px' }} variant='outlined'>
                                    <CardContent>
                                        <Typography variant="h5" >Views vs Clicks</Typography>
                                        <Divider /><br />

                                        <BarChart
                                            height={300}
                                            series={[
                                                { data: [state.stats.displays, state.stats.clicks], label: 'total', id: 'pvId' },
                                                { data: [state.stats.viewUsers, state.stats.clickUsers], label: 'unique', id: 'uvId' }
                                            ]}
                                            xAxis={[{ data: ['Views', 'Clicks'], scaleType: 'band' }]}
                                        />

                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={2}>
                                <PieStatsCard name='TOP matches by view' stats={state.stats.viewMatches} />
                            </Grid>
                            <Grid item xs={2}>
                                <PieStatsCard name='TOP matches by click' stats={state.stats.clickMatches} />
                            </Grid>
                            <Grid item xs={2}>
                                <PieStatsCard name='Countries by view' stats={state.stats.viewCountries} />
                            </Grid>
                            <Grid item xs={2}>
                                <PieStatsCard name='Languages by view' stats={state.stats.viewLanguages} />
                            </Grid>
                            <Grid item xs={2}>
                                <PieStatsCard name='Clients by view' stats={state.stats.viewClients} />
                            </Grid>
                            <Grid item xs={2}>
                                <PieStatsCard name='Countries by click' stats={state.stats.clickCountries} />
                            </Grid>
                            <Grid item xs={2}>
                                <PieStatsCard name='Languages by click' stats={state.stats.clickLanguages} />
                            </Grid>
                            <Grid item xs={2}>
                                <PieStatsCard name='Clients by click' stats={state.stats.clickClients} />
                            </Grid>
                        </React.Fragment>}
                </Grid>

            </Stack>
        </div >
    );
}