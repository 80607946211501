import * as React from 'react';
import Button from '@mui/material/Button';
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Slider, Stack, Switch, TextField, Typography } from '@mui/material';
import { checkStoreCode, createBanner, createShopProduct, deleteBanner, disableShopProduct, disableVideoPlacement, enableShopProduct, enableVideoPlacement, getPromoBanner, getShopEffects, getShopProduct, getVideoPlacement, getVideoPlacementStats, putBanner, putMatch, updateShopProduct, updateVideoPlacement } from '../Api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import VideoPlacementDto from '../models/dtos/VideoPlacementDto';
import VideoPlacementCard from '../components/VideoPlacementCard';
import VideoRewardOptionsDto from '../models/dtos/VideoRewardOptionsDto';
import VideoPlacementStatsDto from '../models/dtos/VideoPlacementStatsDto';
import PeriodStats from '../models/PeriodStats';
import VideoStatsCard from '../components/VideoStatsCard';


export default function VideoPlacementStatsPage() {
    let editedvideoPlacementId = useParams()['id'];
    let parsedEditedVideoPlacementId = editedvideoPlacementId ?? '';

    const [state, setState] = React.useState({
        videoPlacementPresent: false,
        stats: [] as PeriodStats[], dialogOpen: false, dialogMsg: '[]',
    });

    const navigate = useNavigate()
    let isEditMode = editedvideoPlacementId != undefined;


    React.useEffect(() => {

        const mapStats = (data: VideoPlacementStatsDto) => {
            const result: PeriodStats[] = [];

            result.push({ name: "Rewards paid", today: data.todayRewards + ' Betcoins', week: '', month: data.monthRewards  + ' Betcoins', yesterday: data.yesterdayRewards  + ' Betcoins', chart: data.rewardsChart });
            result.push({ name: "Views", today: data.todayViews + ' views', week: '', month: data.monthViews  + ' views', yesterday: data.yesterdayViews  + ' views', chart: data.viewsChart });
            result.push({ name: "Unique users", today: data.todayUsers + ' users', week: '', month: data.monthUsers  + ' users', yesterday: data.yesterdayUsers  + ' users', chart: data.usersChart });
            return result;
        }

        const fetchData = async () => {

            if (isEditMode) {
                let item = await getVideoPlacementStats(parsedEditedVideoPlacementId);
                if (item != null) {
                    setState(prevState => ({
                        ...prevState,
                        stats: mapStats(item!!),
                        videoPlacementPresent: true
                    }));
                }
            }
        }

        fetchData();
    }, [])



    const handleDialogClose = () => {
        setState({ ...state, dialogOpen: false });
    }

    return (
        <div>
        <Stack gap="50px 50px" sx={{ mx: 5, my: 5 }} justifyContent='center' alignItems='center'>
            <Typography variant="h3">Statistics for {parsedEditedVideoPlacementId}</Typography>
            {state.videoPlacementPresent == false ? <CircularProgress size={64} style={{ marginBottom: '32px' }} /> : <div />}
            <Grid container spacing={5} columns={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 4 }}>{
                state.stats.map(x =>
                    <Grid item xs={1} key={x.name}>
                        <VideoStatsCard name={x.name} today={x.today} yesterday={x.yesterday} month={x.month} chartDates={x.chart.map(c => new Date(c.date))} chartValues={x.chart.map(c => c.value)} />
                    </Grid>)
            }
            </Grid>
            <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4, lg: 5 }} justifyContent="flex-end">
                <Grid item xs={1} key="viewVideoPlacements" alignContent="center">
                    <Button variant="outlined" fullWidth={true} style={{ height: '54px' }} onClick={() => navigate('/video-placements')}>Go back to video placements</Button>
                </Grid>
                <Grid item xs={1} key="viewGeneralStats" alignContent="center">
                    <Button variant="contained" fullWidth={true} style={{ height: '54px' }} onClick={() => navigate('/video-placements/stats')}>View general stats</Button>
                </Grid>
            </Grid>
        </Stack >
            <Dialog
                open={state.dialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    Error
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {state.dialogMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Got it</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}