import * as React from 'react';
import { Box, Card, CardContent, CircularProgress, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { getVideoPlacementsStats } from '../Api';
import { useNavigate } from 'react-router-dom';
import PieStatsCard from '../components/PieStatsCard';
import { BarChart, LineChart } from '@mui/x-charts';
import SummaryVideoPlacementStatsDto from '../models/dtos/SummaryVideoPlacementStatsDto';


export default function VideoPlacementsStatsPage() {
    const [state, setState] = React.useState({
        statsPresent: false,
        isFinished: false,
        periods: ['Month', 'Week', 'Yesterday', 'Today'],
        period: 'Month',
        stats: {} as SummaryVideoPlacementStatsDto
    });
    const navigate = useNavigate()

    React.useEffect(() => {

        const fetchData = async () => {
            await fetchAndApplyStats(state.period);
        }

        fetchData();
    }, [])

    const fetchAndApplyStats = async (period: string) => {
        let result = await getVideoPlacementsStats(period)
        if (result != null) {
            setState(prev => ({ ...prev, statsPresent: true, stats: result! }));
        }
    }

    const onPeriodSelected = async (newPeriod: string) => {
        setState(prev => ({ ...state, period: newPeriod, statsPresent: false }));
        await fetchAndApplyStats(newPeriod)
    }

    return (
        <div>
            <Stack gap="50px 50px" sx={{ mx: 5, my: 5 }} justifyContent='center' alignItems='center'>

                <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4, lg: 6 }} alignItems='center'>
                    <Grid item xs={0} sm={0} md={1} lg={2} />
                    <Grid item xs={2}>
                        <Typography variant="h3">Video placements statistics</Typography>
                    </Grid>
                    <Box width='100%' />
                    <Grid item xs={0} sm={0} md={1} lg={2} />
                    <Grid item xs={2} key="periodSelect">
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Period for stats:</InputLabel>
                            <Select
                                labelId="banner-simple-select-label"
                                id="banner-simple-select"
                                value={state.period}
                                onChange={e => onPeriodSelected(e.target.value)}
                                label="Banner">
                                {
                                    state.periods.map((x) => <MenuItem value={x} key={x}>{x}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Box width="100%" />
                    {!state.statsPresent ? <React.Fragment>
                        <Grid item xs={0} sm={0} md={1} lg={2} /><Grid item xs={2} ><CircularProgress size='25%' style={{ marginBottom: '32px', marginLeft: '35%' }} />
                        </Grid></React.Fragment> :
                        <React.Fragment>
                            <Grid item xs={2}>
                                <Card sx={{ width: '100%', height: '400px' }} variant='outlined'>
                                    <CardContent>
                                        <Typography variant="h5" ><b>Summary</b></Typography><br />
                                        <Divider /><br />
                                        <Typography variant="h5" >Total Views: {state.stats.totalViews}</Typography>
                                        <Typography variant="h5" >Total Unique Users: {state.stats.totalUsers}</Typography>
                                        <Typography variant="h5" >Total Paid: {state.stats.totalRewards} Betcoins</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            {state.stats.viewsChart.length > 0 ?
                                <React.Fragment>
                                    <Grid item xs={2}>
                                        <Card sx={{ width: '100%', height: '400px' }} variant='outlined'>
                                            <CardContent>
                                                <Typography variant="h5" display="inline">Timeline</Typography>
                                                <Divider /><br />
                                                <LineChart
                                                    xAxis={[{ scaleType: 'time', data: state.stats.viewsChart?.map(x => new Date(x.date)) }] ?? []}
                                                    series={[
                                                        {
                                                            label: 'Users',
                                                            data: state.stats.usersChart.map(x => x.value),
                                                        },
                                                        {
                                                            label: 'Views',
                                                            data: state.stats.viewsChart.map(x => x.value),
                                                        }
                                                    ]}
                                                    height={300}
                                                />

                                            </CardContent>
                                        </Card>
                                    </Grid></React.Fragment> : <React.Fragment></React.Fragment>
                            }
                            <Grid item xs={2}>
                                <PieStatsCard name='Views by placement' stats={state.stats.viewsByPlacement} />
                            </Grid>
                            <Grid item xs={2}>
                                <PieStatsCard name='Users by placement' stats={state.stats.usersByPlacement} />
                            </Grid>
                            <Grid item xs={2}>
                                <PieStatsCard name='Rewards by placement' stats={state.stats.rewardsByPlacement} />
                            </Grid>
                        </React.Fragment>}
                </Grid>

            </Stack>
        </div >
    );
}