import { Avatar, Button, CircularProgress, Pagination, Stack, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import BetlistShortDto from "../models/dtos/BetlistShortDto";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { getBalancePaginated, getBetlistsPaginated, getMatchBets } from "../Api";
import BalanceHistoryRecordDto from "../models/dtos/BalanceHistoryRecordDto";
import UserPlacedBetDto from "../models/dtos/UserPlacedBetDto";
import MatchVarietyDto from "../models/dtos/MatchVarietyDto";

interface BalanceTableProps {
    matchId: number,
    varieties : MatchVarietyDto[]
}

export default function BetsForMatchesTable(props: BalanceTableProps) {
    const [state, setState] = React.useState({
        bets: [] as UserPlacedBetDto[],
        currentPage: 0,
        limit: 25,
        loaded: false,
        totalPages: 0
    });


    const fetchData = async (page: number,) => {
        let matchBets = await getMatchBets(props.matchId, page * state.limit, state.limit);
        const totalPages = Math.ceil((matchBets?.totalItems ?? 0) / state.limit);
        setState(prevState => ({ ...prevState, bets: matchBets?.items ?? [], loaded: matchBets != null, totalPages: totalPages }));
    }

    const navigate = useNavigate();

    const getBetlistBackground = (state: number) => {
        switch (state) {
            case 4: return 'lightGreen';
            case 8: return '#FFCCCB';
            case 2: return 'orange';
            default: return 'lightBlue';
        }
    }

    const getBetlistStateText = (state: number) => {
        switch (state) {
            case 4: return 'WON';
            case 8: return 'LOST';
            case 2: return 'PENDING';
            default: return 'RETURNED';
        }
    }

    const onPageChanged = async (p: number) => {
        await fetchData(p - 1);
    }

    const getVarietyText = (varietyId : number) => {
        let variety = props.varieties.find(x => x.id == varietyId);
        if (variety != null)
            return variety.name;
        else
            return "";
    }

    React.useEffect(() => {
        fetchData(0);
    }, []);

    return (
        !state.loaded ? <CircularProgress size='100px' style={{ marginBottom: '32px' }} /> :
            <Stack>
                <Pagination count={state.totalPages} size="medium" onChange={(e, p) => onPageChanged(p)} page={state.currentPage} />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">UserId</TableCell>
                            <TableCell align="left">User</TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">CreatedAt</TableCell>
                            <TableCell align="left">Bet</TableCell>
                            <TableCell align="left">Variety</TableCell>
                            <TableCell align="left">Coefficient</TableCell>
                            <TableCell align="left">Money</TableCell>
                            <TableCell align="left">State</TableCell>
                            <TableCell align="left">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.bets.map(item => (
                            <React.Fragment>
                                <TableRow sx={{ background: getBetlistBackground(item.bet.state) }}>
                                    <TableCell>
                                        {item.user.id}
                                    </TableCell>
                                    <TableCell>
                                        <b>{item.user.name}</b>
                                    </TableCell>
                                    <TableCell>
                                        <Avatar
                                            src={item.user.photoUrl}
                                            sx={{ width: 40, height: 40 }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {new Date(item.bet.createdAt).toLocaleString()}
                                    </TableCell>
                                    <TableCell>
                                        {item.bet.name}
                                    </TableCell>
                                    <TableCell>
                                        {getVarietyText(item.bet.varietyId)}
                                    </TableCell>
                                    <TableCell>
                                        {item.bet.coef ?? 1.0}
                                    </TableCell>
                                    <TableCell>
                                        {item.bet.moneyReturned} / {item.bet.moneyPlaced}
                                    </TableCell>
                                    <TableCell>
                                        {getBetlistStateText(item.bet.state)}
                                    </TableCell>
                                    <TableCell>
                                        <Button component={Link} to={"/users/" + item.user.id + "?tab=bets"} variant="contained" style={{ height: '32px', width: '128px' }}>Go to user</Button>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </Stack>);
}