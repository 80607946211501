import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Rating } from '@mui/material';
import { formatRevenue } from '../Pages/HomePage';

interface FunnelsCardProps {
    name : string,
    todayRevenue : number,
    monthlyRevenue: number,
    monthlyOffers : number,
    todayOffers : number,
    monthlyAcceptedOffers : number,
    todayAcceptedOffers : number,
    acceptanceRate : number
}

export default function FunnelsCard(funnel: FunnelsCardProps) {
    return (
        <Card sx={{ width: '100%' }} >
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {funnel.name}
                    </Typography>
                    <Typography variant="h6" color="text.secondary" sx={{ color: 'darkBlue', textAlign: 'justify' }}>
                        Monthly Revenue: {formatRevenue(funnel.monthlyRevenue)}
                    </Typography>
                    <Typography variant="h6" color="text.secondary" sx={{ color: 'blue', textAlign: 'justify' }}>
                        Today Revenue: {formatRevenue(funnel.todayRevenue)}
                    </Typography>
                    <Typography variant="h6" color="text.secondary" sx={{ color: 'blue', textAlign: 'justify' }}>
                        Offers this month: {funnel.monthlyAcceptedOffers} / {funnel.monthlyOffers}
                    </Typography>
                    <Typography variant="h6" color="text.secondary" sx={{ color: 'blue', textAlign: 'justify' }}>
                        Offers today: {funnel.todayAcceptedOffers} / {funnel.todayOffers}
                    </Typography>
                    
                    
                </CardContent>
        </Card>
    );
}