import { useContext } from "react";
import { Navigate, Route } from "react-router-dom";
import { UserContext } from "../App";
import { AuthorizationState } from "../routes";

export type ProtectedRouteProps = {
    authType: AuthorizationState;
    outlet: JSX.Element;
};

export default function ProtectedRoute({ authType, outlet }: ProtectedRouteProps) {
    let context = useContext(UserContext);
    if (authType == AuthorizationState.Any || (context.isAuthenticated && authType == AuthorizationState.Authorized) || (!context.isAuthenticated && authType == AuthorizationState.Unauthorized)) {
        return outlet;
    } else if (context.isAuthenticated) {
        return <Navigate to="/" />;
    } else {
        return <Navigate to="/login" />
    }
};