import { Button, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import BetlistShortDto from "../models/dtos/BetlistShortDto";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { getBetlistsPaginated, updateBetStateForBetlist } from "../Api";

interface BetlistsTableProps {
    userId: number,
    limit: 100
}

export default function BetlistsTable(props: BetlistsTableProps) {

    const [state, setState] = React.useState({
        betlists: [] as BetlistShortDto[],
        loaded: false
    });

    const onChangeBetStateClicked = async (betlistId: number, id: number) => {

    }

    const getBetlistBackground = (state: number) => {
        switch (state) {
            case 4: return 'lightGreen';
            case 8: return '#FFCCCB';
            case 2: return 'orange';
            default: return 'lightBlue';
        }
    }

    const getBetlistStateText = (state: number) => {
        switch (state) {
            case 4: return 'WON';
            case 8: return 'LOST';
            case 2: return 'PENDING';
            default: return 'RETURNED';
        }
    }

    const fetchData = async () => {
        let betlists = await getBetlistsPaginated(props.userId, 0, props.limit);
        setState(prevState => ({ ...prevState, betlists: betlists?.items ?? [], loaded: betlists != null }));
    }

    const updateBetState = async (betlistId: number, betId: number, betState: number) => {
        let betlist = await updateBetStateForBetlist(props.userId, betlistId, betId, betState);
        setState(prevState => ({ ...prevState, toastMsg: betlist != null ? "Updated betlist" : "Something went wrong!" }));
        if (betlist) {
            let existingBetlist = state.betlists.find(b => b.betlistId == betlistId);
            if (existingBetlist) {
                let index = state.betlists.indexOf(existingBetlist);
                state.betlists[index] = betlist;
                setState(prevState => ({ ...prevState, betlists: state.betlists }));
            }
        }
    }

    const navigate = useNavigate();

    React.useEffect(() => {
        fetchData();
    }, []);

    return (
        !state.loaded ? <CircularProgress size='100px' style={{ marginBottom: '32px' }} /> : <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell align="left">Placed At / Bet name</TableCell>
                    <TableCell align="left">Match</TableCell>
                    <TableCell align="left">Variety</TableCell>
                    <TableCell align="left">State</TableCell>
                    <TableCell align="left">Coef</TableCell>
                    <TableCell align="left">Actions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {state.betlists.map(item => (
                    <React.Fragment>
                        <TableRow>
                            <TableCell  sx={{ background: getBetlistBackground(item.state) }}>
                                {item.betlistId}
                            </TableCell>
                            <TableCell colSpan={3} sx={{ background: getBetlistBackground(item.state) }}>
                            {new Date(item.placedAt).toLocaleString()}
                            </TableCell>
                            <TableCell sx={{ background: getBetlistBackground(item.state) }}>
                                {item.moneyReturned} / {item.moneyPlaced} 🤑
                            </TableCell>
                            <TableCell colSpan={2} sx={{ background: getBetlistBackground(item.state) }}>
                                <b>{item.finalCoefficient}</b>
                            </TableCell>
                        </TableRow>
                        {item.bets.map(bet => (
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>{bet.name}</TableCell>
                                <TableCell><Link to={'/matches/'+bet.matchId}>{bet.homeTeamName} vs {bet.awayTeamName}</Link>&nbsp;({new Date(bet.date).toLocaleString()})</TableCell>
                                <TableCell>{bet.period}</TableCell>
                                <TableCell>{getBetlistStateText(bet.state)}</TableCell>
                                <TableCell>{bet.coefficient}</TableCell>
                                <TableCell>
                                    <Button variant={bet.state == 4 ? 'contained' : 'outlined'} style={{ height: '54px' }} color='success' onClick={() => updateBetState(item.betlistId, bet.id, 4)}>w</Button>
                                    <Button variant={bet.state == 8 ? 'contained' : 'outlined'} style={{ height: '54px' }} color='error' onClick={() => updateBetState(item.betlistId, bet.id, 8)}>L</Button>
                                    <Button variant={bet.state == 1 ? 'contained' : 'outlined'} style={{ height: '54px' }} color='info' onClick={() => updateBetState(item.betlistId, bet.id, 1)}>R</Button>
                                    <Button variant={bet.state == 2 ? 'contained' : 'outlined'} style={{ height: '54px' }} color='warning' onClick={() => updateBetState(item.betlistId, bet.id, 2)}>P</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </React.Fragment>
                ))}
            </TableBody>
        </Table>);
}