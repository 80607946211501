import User from "./models/User";

export const getLocalUser = () => {
    let localUserRecord = localStorage.getItem("localUser");
    if (localUserRecord == null)
        return null;

    return JSON.parse(localUserRecord) as User;
}

export const updateLocalUser = (user: User) => {
    let localUserRecord = JSON.stringify(user);
    localStorage.setItem("localUser", localUserRecord);
}

export const deleteLocalUser = () => {
    localStorage.removeItem("localUser");
}

export const setAccessToken = (accessToken : string) =>{
    localStorage.setItem("accessToken", accessToken);
}

export const removeAccessToken = () =>{
    localStorage.removeItem("accessToken");
}

export const getAccessToken = () => {
    return localStorage.getItem("accessToken");
}

export const checkAuthentication = () => {
    return getAccessToken() != null && getLocalUser() != null;
}