import * as React from 'react';
import Button from '@mui/material/Button';
import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { getPromoBanners, getShopProducts } from '../Api';
import { useNavigate } from 'react-router-dom';
import PromoBannerDto from '../models/dtos/PromoBannerDto';
import PromoBannerCard from '../components/PromoBannerCard';
import ShopItemCard from '../components/ShopItemCard';
import ShopItemDto from '../models/dtos/ShopItemDto';

export const formatRevenue = (revenue: number) => {
    return revenue.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
}

export default function ShopPage() {
    const [state, setState] = React.useState({
        itemsLoaded: false,
        items: [] as ShopItemDto[]
    });

    const navigate = useNavigate()


    React.useEffect(() => {

        const fetchData = async () => {
            let items = await getShopProducts();
            setState(prevState => ({ ...prevState, items: items ?? [], itemsLoaded: true }));
        }

        fetchData();
    }, [])

    return (
        <Stack gap="50px 50px" sx={{ mx: 5, my: 5 }} justifyContent='center' alignItems='center'>
            <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4, lg: 5 }} justifyContent="flex-end">
                <Grid item xs={1} key="addButton" alignContent="center">
                    <Button variant="contained" fullWidth={true} style={{ height: '54px' }} onClick={() => navigate('/shop-items/create')}>Add shop item</Button>
                </Grid>
            </Grid>
            <Typography variant="h3">Shop Items</Typography>
            {!state.itemsLoaded ? <Grid item xs={2}><CircularProgress size='25%' style={{ marginBottom: '32px', marginLeft: '35%' }} /></Grid> : <div />}
            <Grid container spacing={5} columns={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 4 }}>
                <Box width='100%' />
                {
                    state.items.map(x =>
                        <Grid item xs={1} key={x.id}>
                            <ShopItemCard {...{item: x, showEdit: true, previewLanguage: 'en'}} />
                        </Grid>)
                }
            </Grid>
        </Stack >
    );
}