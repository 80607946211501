import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, Button, CardActionArea, CardActions, Divider, Rating } from '@mui/material';
import { formatRevenue } from '../Pages/HomePage';
import PromoMatchDto from '../models/dtos/PromoMatchDto';
import PromoMatchModel from '../models/PromoMatchModel';
import { getLocalizedString, getLocalizedStringFor } from '../utils/LocalizationUtil';
import { useNavigate } from 'react-router-dom';
import PromoBannerDto from '../models/dtos/PromoBannerDto';

interface PromoBannerProps {
    banner: PromoBannerDto,
    previewLanguage: string,
    showEdit: boolean,
    fixedHeight? : boolean,
    onClick? : ()=>void
}

export default function PromoBannerCard(model: PromoBannerProps) {
    const navigate = useNavigate()
    let img = getLocalizedStringFor("https://media.istockphoto.com/id/1435167377/photo/soccer-stadium-field-soccer-background.webp?b=1&s=170667a&w=0&k=20&c=Zvt9E628RczuRjHhFMF6sQLOqQlvFk5MjKoj2gSb7nE=", model.previewLanguage, model.banner.imgUrls);
    let title = getLocalizedStringFor("No title", model.previewLanguage, model.banner.titles);
    let description = getLocalizedStringFor("Description text is missing", model.previewLanguage, model.banner.descriptions);
    let callToAction = getLocalizedStringFor("No call to action text", model.previewLanguage, model.banner.callToActions);
    let height = model.fixedHeight ?? false ? '240px' : model.banner.location == "Bets" ? (model.showEdit ? '240px' : '210px') : (model.showEdit ? '180px' : '160px')

    return (
        <Card sx={{ width: '100%' }} >
            <Typography variant="h6" color="text.secondary" sx={{ color: 'black', textAlign: 'center', margin: '8px' }}>
                {model.banner.name}
            </Typography>
            <Divider />
            <CardContent sx={{height: height}}>
                {model.banner.location == "Bets" ?
                    <React.Fragment>
                        <br />
                        <img src={img} height={64} width={64} style={{ float: 'left', marginRight: '16px', marginTop: '-4px' }} />
                        <b>{title}</b><br />
                        <p dangerouslySetInnerHTML={{ __html: description }} />
                        <Button size="small" variant="outlined" sx={{ textAlign: 'right' }} onClick={model.onClick}>
                            {callToAction}
                        </Button>
                        <br /> <br />
                    </React.Fragment> : <React.Fragment>
                        <br />
                        <div onClick={model.onClick} style={{ background: '#285e8d', padding: '4px', textAlign: 'center', color: 'white', cursor:'pointer' }}><b dangerouslySetInnerHTML={{ __html: description }}></b></div>
                        <br />
                    </React.Fragment>}
                    {model.showEdit ? <Divider /> : <div/>}
            </CardContent>
            {model.showEdit ?
                <CardActions >
                    <Button size="small" color="primary" variant="contained" sx={{ marginLeft: 'auto', color: 'white' }} onClick={e => navigate('/promo-banners/' + model.banner.id + '/edit')}>
                        Edit
                    </Button>
                </CardActions> : <div />}
        </Card>
    );
}