import * as React from 'react';
import Button from '@mui/material/Button';
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography } from '@mui/material';
import { createBanner, deleteBanner, getPromoBanner, getPromoConfig, putBanner, putMatch, updateConfig } from '../Api';
import { useNavigate, useParams } from 'react-router-dom';
import PromoBannerDto from '../models/dtos/PromoBannerDto';
import { getLocalizationsCount, getLocalizedString, getLocalizedStringFor } from '../utils/LocalizationUtil';
import Localization from '../models/Localization';
import { stat } from 'fs';


export default function EditConfigPage() {
    const imgPlaceholder = 'https://static.thenounproject.com/png/3674270-200.png';

    let titlePlaceholder = { en: 'Title placeholder' };
    const descriptionPlaceholder = { en: 'Description placeholder text. Replace it with what you want' }
    const callToActionPlaceholder = { en: 'Call to action' }
    const imgUrlsPlaceholder = { en: imgPlaceholder }

    const [state, setState] = React.useState({
        configPresent: false,
        enabled: true,
        dialogOpen: false,
        dialogMsg: '',
        baseUrl: ''
    });
    const navigate = useNavigate()

    React.useEffect(() => {

        const fetchData = async () => {
            let config = await getPromoConfig()

            if (config != null) {
                setState(prev => ({ ...prev, enabled: config!.enabled, baseUrl: config!.baseUrl, configPresent: true }))
            }
        }

        fetchData();
    }, [])

    const onCreateClicked = async () => {
        var hasError = false;
        let errorMsg = '';

        if (state.baseUrl.length == 0 || (!state.baseUrl.includes("http") && !state.baseUrl.includes("www"))) {
            hasError = true;
            errorMsg = "No valid base url is provided!";
        }

        if (!hasError) {
            let result = await updateConfig(state.enabled, state.baseUrl)

            hasError = !result;

            if (!result) {
                errorMsg = "Server error!";
            }
        }

        if (hasError) {
            setState({ ...state, dialogMsg: errorMsg, dialogOpen: true });
        }
        else
            navigate('/promo-banners')
    }

    const handleDialogClose = () => {
        setState(prev => ({ ...prev, dialogOpen: false }));
    }

    return (
        <div>
            <Stack gap="50px 50px" sx={{ mx: 5, my: 5 }} justifyContent='center' alignItems='center'>

                <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4, lg: 6 }} alignItems='center'>
                    <Grid item xs={0} sm={0} md={1} lg={2} />
                    <Grid item xs={2}>
                        <Typography variant="h3">Edit promo config</Typography>
                    </Grid>
                    <Box width="100%" />
                    <Grid item xs={0} sm={0} md={1} lg={2} />
                    <Grid item xs={2}>
                        <Typography variant="h6">Banner details:</Typography>
                    </Grid>
                    <Box width="100%" />
                    {!state.configPresent ?
                        <React.Fragment>
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="progress"><CircularProgress size={64} style={{ marginBottom: '32px' }} /></Grid>
                        </React.Fragment> : <React.Fragment>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="activeContainer">
                                <FormControlLabel control={<Switch checked={state.enabled} onChange={e => setState(prevState => ({ ...prevState, enabled: e.target.checked }))} />} label="Is Promo Enabled" />
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="base url">
                                <TextField label={"base url"} variant="filled" value={state.baseUrl} fullWidth={true}
                                    onChange={e => setState(prev => ({ ...prev, baseUrl: e.target.value }))} />
                            </Grid>
                            <Box width='100%' />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={1} key="updateConfig">
                                <Button variant="contained" fullWidth={true} style={{ height: '54px' }} onClick={() => onCreateClicked()}>Update config</Button>
                            </Grid>
                            <Grid item xs={1} key="cancel">
                                <Button variant="outlined" fullWidth={true} style={{ height: '54px' }} onClick={() => navigate('/promo-matches')}>Cancel</Button>
                            </Grid>
                        </React.Fragment>}
                </Grid>

            </Stack >
            <Dialog
                open={state.dialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    Error
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {state.dialogMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Got it</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}