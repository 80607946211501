import * as React from 'react';
import Button from '@mui/material/Button';
import { Box, Card, CardActions, CardContent, CircularProgress, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography, debounce } from '@mui/material';
import { cancelOffer, getFunnelList, getOffers, getPromoBanners, getTemplates } from '../Api';
import { useNavigate } from 'react-router-dom';
import PromoBannerDto from '../models/dtos/PromoBannerDto';
import PromoBannerCard from '../components/PromoBannerCard';
import TemplateDto from '../models/dtos/TemplateDto';
import OfferDto from '../models/dtos/OfferDto';
import { DataGrid } from '@mui/x-data-grid/DataGrid/DataGrid';
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import FunnelDto from '../models/dtos/FunnelDto';
import FunnelConfigDto from '../models/dtos/FunnelConfigDto';
import { GridCellParams, GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { useCallback } from 'react';

export const formatRevenue = (revenue: number) => {
    return revenue.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
}

export default function OffersPage() {
    let defaultLimit = 1000;
    const [state, setState] = React.useState({
        funnels: [] as FunnelConfigDto[],
        templatesLoaded: false,
        betupUserId: '',
        funnelId: 'All',
        offset: 0,
        limit: defaultLimit,
        purchased: 'All',
        purchaseOptions: ['All', 'Purchased', 'Not purchased'],
        onlyActive: false,
        funnelOptions: ['All'],
        offers: [] as OfferDto[]
    });

    const navigate = useNavigate();

    const onCancelOfferClicked = async (offerId: string) => {
        await cancelOffer(offerId);
        await fetchData(state.offset, state.limit, state.betupUserId, state.funnelId, state.purchased, state.onlyActive);
    }

    const columns: GridColDef[] = [
        { field: 'userBetupId', headerName: 'UserId', width: 100 },
        {
            field: 'source', headerName: 'Funnel', width: 130, valueGetter: (params: GridValueGetterParams) =>
                state.funnels.find(x => x.id == params.row.source)?.name ?? params.row.source
        },
        { field: 'betcoins', headerName: 'Betcoins', width: 130 },
        { field: 'newPriceSku', headerName: 'Product', width: 150 },
        {
            field: 'active', headerName: 'Active', width: 100, renderCell: (params: GridCellParams) =>
                params.row.purchased == false && new Date(params.row.expiresAt) > new Date() ? <Typography color={"green"} variant='body1'>YES</Typography> : <Typography color={"red"} variant='body1'>NO</Typography>

        },
        {
            field: 'purchased', headerName: 'Purchased', width: 100, renderCell: (params: GridCellParams) =>
                params.row.purchased ? <Typography color={"green"} variant='body1'>YES</Typography> : <Typography color={"red"} variant='body1'>NO</Typography>

        },
        {
            field: 'createdAt', headerName: 'CreatedAt', width: 200, valueGetter: (params: GridValueGetterParams) =>
                new Date(params.row.createdAt).toLocaleString()
        },
        {
            field: 'expiresAt', headerName: 'ExpiresAt', width: 200, valueGetter: (params: GridValueGetterParams) =>
                new Date(params.row.expiresAt).toLocaleString()
        },
        {
            field: 'actions', headerName: 'Actions', width: 150, renderCell: (params: GridCellParams) =>
                params.row.purchased == false && new Date(params.row.expiresAt) > new Date() ? <Button variant="contained" fullWidth={true} style={{ height: '32px' }} onClick={() => onCancelOfferClicked(params.row.id)}>Cancel</Button> : <React.Fragment></React.Fragment>
        },
    ];


    const fetchData = async (offset: number, limit: number, betupUserId: string, funnelId: string, purchased: string, onlyActive: boolean) => {
        let p = purchased == 'All' ? undefined : (purchased == 'Purchased' ? true : false);
        let f = funnelId == 'All' ? undefined : (state.funnels.find(x => x.name == funnelId)?.id ?? undefined);
        let u = betupUserId == '' ? undefined : betupUserId;
        let [funnels, paginatedResponse] = await Promise.all([getFunnelList(), getOffers(offset, limit, u, f, p, onlyActive)]);
        let funnelOptions = ['All'].concat(funnels.map(x => x.name));
        let offers = paginatedResponse?.items ?? [];
        setState(prevState => ({ ...prevState, offers: offers, funnels: funnels, funnelOptions: funnelOptions, templatesLoaded: true }));
    }

    React.useEffect(() => {
        fetchData(state.offset, state.limit, state.betupUserId, state.funnelId, state.purchased, state.onlyActive);
    }, []);

    const handleFunnelSelectionChanged = async (funnel: string) => {
        setState(prevState => ({ ...prevState, funnelId: funnel, templatesLoaded: false }));
        await fetchData(state.offset, state.limit, state.betupUserId, funnel, state.purchased, state.onlyActive);
    }

    const handlePurchasedSelectionChanged = async (purchased: string) => {
        setState(prevState => ({ ...prevState, offset: 0, limit: defaultLimit, purchased: purchased }));
        await fetchData(state.offset, state.limit, state.betupUserId, state.funnelId, purchased, state.onlyActive);
    }

    const onUserSearched = useCallback(debounce((betupUserId: string) => {
        fetchData(state.offset, state.limit, betupUserId, state.funnelId, state.purchased, state.onlyActive);
    }, 500), []);

    const onActiveChanged = async (active: boolean) => {
        setState(prevState => ({ ...prevState, onlyActive: active }));
        fetchData(state.offset, state.limit, state.betupUserId, state.funnelId, state.purchased, active);
    }

    return (
        <Stack gap="50px 50px" sx={{ mx: 5, my: 5 }} justifyContent='center' alignItems='center'>
            <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4, lg: 5 }} justifyContent="flex-end">
                <Grid item xs={1} key="matchesButton" alignContent="center">
                    <Button variant="outlined" fullWidth={true} style={{ height: '54px' }} onClick={() => navigate('/funnels')}>Go to funnels</Button>
                </Grid>
            </Grid>
            <Typography variant="h3">Funnel offers</Typography>
            {!state.templatesLoaded ? <Grid item xs={2}><CircularProgress size='25%' style={{ marginBottom: '32px', marginLeft: '35%' }} /></Grid> : <div />}
            <Grid container spacing={1} columns={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 4 }} alignItems='center'>
                <Grid item xs={1} key="searchField">
                    <TextField type='search' key="betupUserId" label="Enter userId" variant="filled" fullWidth={true} value={state.betupUserId}
                        onChange={e => { setState(prev => ({ ...prev, betupUserId: e.target.value })); onUserSearched(e.target.value); }}
                    />
                </Grid>
                <Grid item xs={1} key="purchaseState" alignContent="center">
                    <FormControl fullWidth={true}>
                        <InputLabel id="demo-simple-select-label">{"Select purchase state"}</InputLabel>
                        <Select
                            fullWidth={true}
                            labelId="banner-simple-select-label"
                            id="min-tier-simple-select"
                            value={state.purchased}
                            onChange={e => handlePurchasedSelectionChanged(e.target.value)}
                            label="Purchased">
                            {
                                state.purchaseOptions.map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={1} key="funnel" alignContent="center">
                    <FormControl fullWidth={true}>
                        <InputLabel id="demo-simple-select-label">{"Select funnel"}</InputLabel>
                        <Select
                            fullWidth={true}
                            labelId="banner-simple-select-label"
                            id="min-tier-simple-select"
                            value={state.funnelId}
                            onChange={e => handleFunnelSelectionChanged(e.target.value)}
                            label="Funnel">
                            {
                                state.funnelOptions.map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={1} key="onlyActive" alignContent="center" alignItems='center'>
                    <FormControlLabel control={<Switch checked={state.onlyActive} onChange={e => onActiveChanged(e.target.checked)} />} label="Show only active offers" />
                </Grid>
                <Box width='100%' />
                <Grid item xs={0} sm={0} md={1} lg={2} />
                <Box width='100%' />
            </Grid>

            <DataGrid sx={{ width: '100%' }}
                rows={state.offers}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 25 },
                    },
                }}
                pageSizeOptions={[5, 10]}

            />
        </Stack >
    );
}