import * as React from 'react';
import Button from '@mui/material/Button';
import { Autocomplete, Box, Card, CardActions, CardContent, CardMedia, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, FormControlLabel, Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, Stack, Switch, TextField, Typography, debounce } from '@mui/material';
import StatsCard from '../components/StatsCard';
import PeriodStats from '../models/PeriodStats';
import { createFunnel, deleteFunnel, deleteMatch, getFunnel, getFunnelOptions, getFunnels, getMatchDetails, getMatchPromoLink, getOverviewCharts, getPromoBanners, getPromoMatch, getPromoMatches, getSearchMatches, getStats, getTemplates, putFunnel, putMatch } from '../Api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PromoBannerDto from '../models/dtos/PromoBannerDto';
import MatchDto from '../models/dtos/MatchDto';
import { ChangeEvent, useCallback } from 'react';
import { getLocalizedString } from '../utils/LocalizationUtil';
import PromoBannerCard from '../components/PromoBannerCard';
import TemplateDto from '../models/dtos/TemplateDto';
import FunnelConfigDto from '../models/dtos/FunnelConfigDto';
import TierInfoDto from '../models/dtos/TierInfoDto';
import FunnelOptions from '../models/dtos/FunnelOptions';
import { stat } from 'fs';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export interface Cancelable {
    clear(): void;
}

export const formatRevenue = (revenue: number) => {
    return revenue.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
}

export default function AddFunnelPage() {
    let id = useParams()['id'] as string
    let isEditMode = id != undefined;

    const [state, setState] = React.useState({
        isEditMode: isEditMode,
        funnelPresent: false,
        dialogOpen: false,
        dialogMsg: '',
        templates: [] as TemplateDto[],
        funnel: { activationEvents: [] as string[], lifetime: 'SixHours', cooldownTime: 'TwelveHours', funnelType: 'Simple', bonusSize: 0.5, enabled: true, dailyLimit: 100 } as FunnelConfigDto,
        options: { tiers: [] as TierInfoDto[], funnelTypes: [] as string[], scopes: [] as string[], activationEvents: [] as string[] } as FunnelOptions
    });
    const imgPlaceholder = 'https://static.thenounproject.com/png/3674270-200.png';
    const navigate = useNavigate()

    React.useEffect(() => {

        const fetchData = async () => {
            if (isEditMode) {
                let [funnel, options, templates] = await Promise.all([getFunnel(id), getFunnelOptions(), getTemplates()]);
                setState(prevState => ({ ...prevState, funnel: funnel, options: options, templates: templates, funnelPresent: true }));
            } else {
                let [options, templates] = await Promise.all([getFunnelOptions(), getTemplates()]);
                setState(prevState => ({ ...prevState, options: options, templates: templates, funnelPresent: true }));
            }

        }

        fetchData();
    }, [])

    const onMinTierSelected = (minTier: string) => {
        setState(prevState => ({ ...prevState, funnel: { ...prevState.funnel, minTier: minTier } }));
    }

    const onTierForNonPurchasersSelected = (tierForNonPurchasers: string) => {
        setState(prevState => ({ ...prevState, funnel: { ...prevState.funnel, tierForNonPurchasers: tierForNonPurchasers } }));
    }

    const onFunnelTypeSelected = (funnelType: string) => {
        setState(prevState => ({ ...prevState, funnelType: { ...prevState, funnelType: funnelType } }));
    }

    const onOfferLifetimeSelected = (scope: string) => {
        setState(prevState => ({ ...prevState, funnel: { ...prevState.funnel, lifetime: scope } }));
    }

    const onCooldowntimeSelected = (scope: string) => {
        setState(prevState => ({ ...prevState, funnel: { ...prevState.funnel, cooldownTime: scope } }));
    }

    const onLocalizationTemplateSelected = (template: string) => {
        setState(prevState => ({ ...prevState, funnel: { ...prevState.funnel, localizationTemplate: template } }));
    }

    const onCreateClicked = async () => {
        var hasError = false;
        let errorMsg = '';

        if (state.funnel.name == undefined || state.funnel.name.length < 3 || state.funnel.name.length > 25) {
            hasError = true;
            errorMsg = "No valid funnel name is specified! It should be between 3 and 25 characters";
        } else 
        if (state.funnel.minTier == undefined || state.funnel.minTier.trim().length == 0) {
            hasError = true;
            errorMsg = "No valid minTier is selected!";
        } else if (state.funnel.tierForNonPurchasers == undefined || state.funnel.tierForNonPurchasers.length == 0) {
            hasError = true;
            errorMsg = "No valid tier for non purchasers is selected!";
        } else if (state.funnel.bonusSize > 5.0 || state.funnel.bonusSize < 0) {
            hasError = true;
            errorMsg = "No valid bonus size (between 0% and 500%) is selected!";
        } else if (state.funnel.localizationTemplate == undefined || state.funnel.localizationTemplate.length == 0) {
            hasError = true;
            errorMsg = "No valid localization template is selected!";
        } else if (state.funnel.activationEvents.length == 0){
            hasError = true;
            errorMsg = "Funnel should have at least 1 activation event!";
        } else if (state.funnel.dailyLimit < 0 && state.funnel.dailyLimit > 10000000){
            hasError = true;
            errorMsg = "Funnel daily limit should be within 0 and 1000000";
        }

        if (!hasError) {
            let result = false;
            if (isEditMode) {
                result = await putFunnel(state.funnel.id, state.funnel);
            } else {
                result = await createFunnel(state.funnel);
            }

            hasError = !result;

            if (!result) {
                errorMsg = "Server error!";
            }
        }

        if (hasError) {
            setState({ ...state, dialogMsg: errorMsg, dialogOpen: true });
        }
        else
            navigate('/funnels')
    }

    const onDeleteClicked = async () => {
        var hasError = false;
        let errorMsg = '';

        if (!hasError) {
            let result = await deleteFunnel(state.funnel.id);

            hasError = !result;

            if (!result) {
                errorMsg = "Server error!";
            }
        }

        if (hasError) {
            setState({ ...state, dialogMsg: errorMsg, dialogOpen: true });
        }
        else
            navigate('/funnels')
    }

    const editLocalizations = async () => {
        navigate('/funnels/localizations');
    }

    const handleDialogClose = () => {
        setState({ ...state, dialogOpen: false });
    }

    const handleActivationEventsChange = (event: SelectChangeEvent<string[]>) => {
        let events = [] as string[];
        if (typeof event.target.value == 'string') {
            events = event.target.value.split('');
        } else {
            events = event.target.value as string[];
        }
        setState(prevState => ({ ...prevState, funnel: { ...prevState.funnel, activationEvents: events } }));
    };

    const onEnabledChanged = async (enabled: boolean) => {
        setState(prevState => ({ ...prevState, funnel: { ...prevState.funnel, enabled: enabled } }));
    }

    const onlyForPurchasersChecked = async (checked : boolean) =>{
        setState(prevState => ({ ...prevState, funnel: { ...prevState.funnel, onlyForPurchasers: checked } }));
    }

    return (
        <div>
            <Stack gap="50px 50px" sx={{ mx: 5, my: 5 }} justifyContent='center' alignItems='center'>

                <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4, lg: 6 }} alignItems='center'>
                    <Grid item xs={0} sm={0} md={1} lg={2} />
                    <Grid item xs={2}>
                        <Typography variant="h3">{isEditMode ? "Edit funnel" : "Add new funnel"}</Typography>
                    </Grid>
                    <Box width="100%" />
                    <Grid item xs={0} sm={0} md={1} lg={2} />
                    <Grid item xs={2}>
                        <Typography variant="h6">Funnel configuration:</Typography>
                    </Grid>
                    {!state.funnelPresent && state.isEditMode ?
                        <React.Fragment>
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="progress"><CircularProgress size={64} style={{ marginBottom: '32px' }} /></Grid>
                        </React.Fragment> : <React.Fragment>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="name">
                                <TextField label="name" variant="filled" value={state.funnel.name || ''} fullWidth={true}
                                    onChange={e => { setState(prevState => ({ ...prevState, funnel: { ...prevState.funnel, name: e.target.value } })); }} />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={1} key="enabled" alignContent="center" alignItems='center'>
                                <FormControlLabel control={<Switch checked={state.funnel.enabled} onChange={e => onEnabledChanged(e.target.checked)} />} label="Enable funnel" />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="bonusSize" alignContent="center" alignItems='center'>
                                <TextField label="bonus in %" type="number" variant="filled" value={Math.round(Math.round(state.funnel.bonusSize * 100))} fullWidth={true}
                                    onChange={e => { const value = parseInt(e.target.value); setState(prevState => ({ ...prevState, funnel: { ...prevState.funnel, bonusSize: value / 100.0 } })); }} />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="dailyLimit" alignContent="center" alignItems='center'>
                                <TextField label="Daily Limit" type="number" variant="filled" value={state.funnel.dailyLimit} fullWidth={true}
                                    onChange={e => { const value = parseInt(e.target.value); setState(prevState => ({ ...prevState, funnel: { ...prevState.funnel, dailyLimit: value } })); }} />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="funnelType">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">{"Select funnelType"}</InputLabel>
                                    <Select
                                        fullWidth={true}
                                        labelId="banner-simple-select-label"
                                        id="pur-tier-simple-select"
                                        value={state.funnel.funnelType}
                                        onChange={e => onFunnelTypeSelected(e.target.value)}
                                        label="FunnelType">
                                        {
                                            state.options.funnelTypes.map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={1} key="onlyForPurchasers" alignContent="center" alignItems='center'>
                                <FormControlLabel control={<Switch checked={state.funnel.onlyForPurchasers} onChange={e => onlyForPurchasersChecked(e.target.checked)} />} label="Only for purchasers" />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="minTier">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">{"Select min tier"}</InputLabel>
                                    <Select
                                        fullWidth={true}
                                        labelId="banner-simple-select-label"
                                        id="min-tier-simple-select"
                                        value={state.funnel.minTier}
                                        onChange={e => onMinTierSelected(e.target.value)}
                                        label="MinTier">
                                        {
                                            state.options.tiers.map(x => <MenuItem key={x.tier} value={x.tier}>{x.tier} ({x.priceInUSD} $)</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="tierForNonPurchasers">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">{"Select tier for non purchasers"}</InputLabel>
                                    <Select
                                        fullWidth={true}
                                        labelId="banner-simple-select-label"
                                        id="pur-tier-simple-select"
                                        value={state.funnel.tierForNonPurchasers}
                                        onChange={e => onTierForNonPurchasersSelected(e.target.value)}
                                        label="NonPurchaserTier">
                                        {
                                            state.options.tiers.map(x => <MenuItem key={x.tier} value={x.tier}>{x.tier}  ({x.priceInUSD} $)</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2}>
                                <Typography variant="h6">Funnel frequency:</Typography>
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="lifetime">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">{"Select offer lifetime"}</InputLabel>
                                    <Select
                                        fullWidth={true}
                                        labelId="banner-simple-select-label"
                                        id="pur-tier-simple-select"
                                        value={state.funnel.lifetime}
                                        onChange={e => onOfferLifetimeSelected(e.target.value)}
                                        label="FunnelType">
                                        {
                                            state.options.scopes.map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="cooldown">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">{"Select offer cooldown time"}</InputLabel>
                                    <Select
                                        fullWidth={true}
                                        labelId="banner-simple-select-label"
                                        id="pur-tier-simple-select"
                                        value={state.funnel.cooldownTime}
                                        onChange={e => onCooldowntimeSelected(e.target.value)}
                                        label="FunnelType">
                                        {
                                            state.options.scopes.map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="activationEvents">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-multiple-checkbox-label">Activation events</InputLabel>
                                    <Select
                                        fullWidth={true}
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={state.funnel.activationEvents}
                                        onChange={handleActivationEventsChange}
                                        input={<OutlinedInput label="Activation events" />}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={MenuProps}
                                    >
                                        {state.options.activationEvents.map((name) => (
                                            <MenuItem key={name} value={name}>
                                                <Checkbox checked={(state.funnel.activationEvents?.indexOf(name) ?? -1) > -1} />
                                                <ListItemText primary={name} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Box width="100%" />
                            <Grid item md={1} lg={2} />
                            <Grid item xs={2}>
                                <Typography variant="h6">Funnel localization:</Typography>
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="localizationTemplate">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">{"Select localization template"}</InputLabel>
                                    <Select
                                        fullWidth={true}
                                        labelId="banner-simple-select-label"
                                        id="pur-tier-simple-select"
                                        value={state.funnel.localizationTemplate}
                                        onChange={e => onLocalizationTemplateSelected(e.target.value)}
                                        label="FunnelType">
                                        {
                                            state.templates.map(x => <MenuItem key={x.id} value={x.id}>{x.id}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={1} lg={2} key="editLocalizations">
                                <Button fullWidth={true} style={{ height: '54px' }} onClick={() => editLocalizations()}>Edit localizations</Button>
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="localizationPreview">
                                <Card sx={{ width: '100%' }} >
                                    <CardContent>
                                        <img src={state.templates.find(x => x.id == state.funnel.localizationTemplate)?.localizations['en']?.iconUrl ?? ''} width={160} height={128} />
                                        <Typography gutterBottom variant="h6" component="div">
                                            Title: {state.templates.find(x => x.id == state.funnel.localizationTemplate)?.localizations['en']?.title ?? ''}
                                        </Typography>
                                        <Typography gutterBottom variant="body1" component="div">
                                            Subtitle: {state.templates.find(x => x.id == state.funnel.localizationTemplate)?.localizations['en']?.subtitle ?? ''}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={isEditMode ? 1 : 2} key="addMatchContainer">
                                <Button variant="contained" fullWidth={true} style={{ height: '54px' }} onClick={() => onCreateClicked()}>{isEditMode ? "Update funnel" : "Add funnel"}</Button>
                            </Grid>
                            {isEditMode ?
                                <Grid item xs={1} key="deleteMatchContainer">
                                    <Button variant="outlined" fullWidth={true} style={{ height: '54px' }} onClick={() => onDeleteClicked()}>Delete funnel</Button>
                                </Grid> : <div />}
                        </React.Fragment>}
                </Grid>

            </Stack >
            <Dialog
                open={state.dialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    Error
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {state.dialogMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Got it</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}