import Localization from "../models/Localization";
import StatsMap from "../models/dtos/StatsMap";

export const getLocalizedString = (defaultValue: string, localization?: Localization) => {
    if (localization == null)
        return defaultValue;

    return localization["en"] ?? localization[Object.keys(localization)[0]]
}

export const getLocalizedStringFor = (defaultValue: string, language: string, localization?: Localization) => {
    if (localization == null)
        return defaultValue;

    return localization[language] ?? localization["en"] ?? localization[Object.keys(localization)[0]]
}

export const getLocalizedFromString = (defaultValue: string, language: string, localizationString?: string) => {
    if (localizationString == null)
        return defaultValue;

    let localization : Localization = JSON.parse(localizationString);

    return localization[language] ?? localization["en"] ?? localization[Object.keys(localization)[0]];
}

export const getLocalizationsCount = (localization?: Localization) => {
    if (localization == null || localization == undefined)
        return 0;

    return Object.keys(localization).length;
}