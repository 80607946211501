// src/routes.ts

// pages

// other
import React, {FC} from "react";
import HomePage from "./Pages/HomePage";
import LoginPage from "./Pages/LoginPage";
import LogoutPage from "./Pages/LogoutPage";
import PromoMatchesPage from "./Pages/PromoMatchesPage";
import AddPromoMatchPage from "./Pages/AddPromoMatchPage";
import PromoMatchStatsPage from "./Pages/PromoMatchStatsPage";
import PromoBannersPage from "./Pages/PromoBannersPage";
import AddPromoBannerPage from "./Pages/AddPromoBannerPage";
import PromoMatchesStatsPage from "./Pages/PromoMatchesStatsPage";
import EditConfigPage from "./Pages/EditConfigPage";
import TopMatchesPage from "./Pages/TopMatchesPage";
import FunnelsConfigPage from "./Pages/FunnelsConfigPage";
import AddFunnelPage from "./Pages/AddFunnelPage";
import FunnelLocalizationsPage from "./Pages/FunnelLocalizationsPage";
import AddFunnelLocalization from "./Pages/AddFunnelLocalization";
import OffersPage from "./Pages/OffersPage";
import DeleteMyAccountPage from "./Pages/DeleteMyAccountPage";
import AccountDeletionRequestsPage from "./Pages/AccountDeletionRequestsPage";
import ShopPage from "./Pages/ShopPage";
import AddShopItemPage from "./Pages/AddShopItemPage";
import EditVideoPlacementPage from "./Pages/EditVideoPlacementPage";
import VideoPlacementsPage from "./Pages/VideoPlacementsPage";
import VideoPlacementStatsPage from "./Pages/VideoPlacementStatsPage";
import VideoPlacementsStatsPage from "./Pages/VideoPlacementsStatsPage";
import UsersPage from "./Pages/UsersPage";
import UserPage from "./Pages/UserPage";
import SupportPage from "./Pages/SupportPage";
import MatchesPage from "./Pages/MatchesPage";
import MatchPage from "./Pages/MatchPage";
import RecalculationRequestsPage from "./Pages/RecalculationRequestsPage";


export enum AuthorizationState {Authorized, Unauthorized, Any};
// interface
interface Route {
    key: string,
    title: string,
    path: string,
    enabled: boolean,
    presentOnNavBar : boolean,
    authType : AuthorizationState,
    component: JSX.Element
}

export const routes: Array<Route> = [
    {
        key: 'home-route',
        title: 'Statistics',
        path: '/',
        enabled: true,
        presentOnNavBar: true,
        component: <HomePage/>,
        authType : AuthorizationState.Authorized
    },
    {
        key: 'promo-matches',
        title: "1XBet promo",
        path: '/promo-matches',
        enabled: true,
        presentOnNavBar: true,
        component: <PromoMatchesPage/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'promo-banners',
        title: "Promo banners",
        path: '/promo-banners',
        enabled: true,
        presentOnNavBar: false,
        component: <PromoBannersPage/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'top-matches',
        title: "TOP matches",
        path: '/top-matches',
        enabled: true,
        presentOnNavBar: false,
        component: <TopMatchesPage/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'matches',
        title: "Matches",
        path: '/matches',
        enabled: true,
        presentOnNavBar: true,
        component: <MatchesPage/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'match-details',
        title: "Match details",
        path: '/matches/:id',
        enabled: true,
        presentOnNavBar: false,
        component: <MatchPage/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'users',
        title: "Users",
        path: '/users',
        enabled: true,
        presentOnNavBar: true,
        component: <UsersPage/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'support',
        title: "Support",
        path: '/support',
        enabled: true,
        presentOnNavBar: true,
        component: <SupportPage/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'recalculations',
        title: "Recalculations",
        path: '/recalculations',
        enabled: true,
        presentOnNavBar: false,
        component: <RecalculationRequestsPage/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'user-info',
        title: "User",
        path: '/users/:id',
        enabled: true,
        presentOnNavBar: false,
        component: <UserPage/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'add-promo-match',
        title: "Create promo match",
        path: '/promo-matches/create',
        enabled: true,
        presentOnNavBar: false,
        component: <AddPromoMatchPage/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'add-promo-banner',
        title: "Create promo banner",
        path: '/promo-banners/create',
        enabled: true,
        presentOnNavBar: false,
        component: <AddPromoBannerPage/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'edit-promo-match',
        title: "Edit promo match",
        path: '/promo-matches/:id/edit',
        enabled: true,
        presentOnNavBar: false,
        component: <AddPromoMatchPage/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'edit-promo-banner',
        title: "Edit promo banner",
        path: '/promo-banners/:id/edit',
        enabled: true,
        presentOnNavBar: false,
        component: <AddPromoBannerPage/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'stats-promo-match',
        title: "Stats for promo match",
        path: '/promo-matches/:id/stats',
        enabled: true,
        presentOnNavBar: false,
        component: <PromoMatchStatsPage/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'stats-promo-matches',
        title: "Stats for promo match",
        path: '/promo-matches/stats',
        enabled: true,
        presentOnNavBar: false,
        component: <PromoMatchesStatsPage/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'config',
        title: "Edit promo config",
        path: '/promo-config',
        enabled: true,
        presentOnNavBar: false,
        component: <EditConfigPage/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'funnels',
        title: "Funnels",
        path: '/funnels',
        enabled: true,
        presentOnNavBar: true,
        component: <FunnelsConfigPage/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'offers',
        title: "Funnel offers",
        path: '/funnels/offers',
        enabled: true,
        presentOnNavBar: false,
        component: <OffersPage/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'funnel-localizations',
        title: "Funnel localizations",
        path: '/funnels/localizations',
        enabled: true,
        presentOnNavBar: false,
        component: <FunnelLocalizationsPage/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'add-funnel-localizations',
        title: "Add Funnel localizations",
        path: '/funnels/localizations/create',
        enabled: true,
        presentOnNavBar: false,
        component: <AddFunnelLocalization/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'edit-funnel-localizations',
        title: "Edit Funnel localizations",
        path: '/funnels/localizations/:id/edit',
        enabled: true,
        presentOnNavBar: false,
        component: <AddFunnelLocalization/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'add-funnel',
        title: "Create funnel",
        path: '/funnels/add',
        enabled: true,
        presentOnNavBar: false,
        component: <AddFunnelPage/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'edit-funnel',
        title: "Edit funnel",
        path: '/funnels/:id/edit',
        enabled: true,
        presentOnNavBar: false,
        component: <AddFunnelPage/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'login',
        title: 'Авторизация',
        path: '/login',
        enabled: true,
        presentOnNavBar: false,
        component: <LoginPage/>,
        authType : AuthorizationState.Unauthorized
    },
    {
        key: 'logout',
        title: 'Logout',
        path: '/logout',
        enabled: true,
        presentOnNavBar: false,
        component: <LogoutPage/>,
        authType : AuthorizationState.Any
    },
    {
        key: 'delete-my-account',
        title: 'Delete my account',
        path: '/delete-my-account',
        enabled: true,
        presentOnNavBar: false,
        component: <DeleteMyAccountPage/>,
        authType : AuthorizationState.Any
    },
    {
        key: 'delete-account-requests',
        title: 'Accounts',
        path: '/delete-account-requests',
        enabled: true,
        presentOnNavBar: false,
        component: <AccountDeletionRequestsPage/>,
        authType : AuthorizationState.Authorized
    },
    {
        key:"shop",
        title:"Shop",
        path:"/shop",
        enabled:true,
        presentOnNavBar:true,
        component: <ShopPage/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'edit-shop-item',
        title: "Edit shop item",
        path: '/shop-items/:id/edit',
        enabled: true,
        presentOnNavBar: false,
        component: <AddShopItemPage/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'create-shop-item',
        title: "Create shop item",
        path: '/shop-items/create',
        enabled: true,
        presentOnNavBar: false,
        component: <AddShopItemPage/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'video-placements',
        title: "Videos",
        path: '/video-placements',
        enabled: true,
        presentOnNavBar: true,
        component: <VideoPlacementsPage/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'edit-video-placement',
        title: "Edit video placement",
        path: '/video-placements/:id/edit',
        enabled: true,
        presentOnNavBar: false,
        component: <EditVideoPlacementPage/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'video-placement-stats',
        title: "Stats for video placement",
        path: '/video-placements/:id/stats',
        enabled: true,
        presentOnNavBar: false,
        component: <VideoPlacementStatsPage/>,
        authType: AuthorizationState.Authorized
    },
    {
        key: 'video-placements-stats',
        title: "Stats for video placement",
        path: '/video-placements/stats',
        enabled: true,
        presentOnNavBar: false,
        component: <VideoPlacementsStatsPage/>,
        authType: AuthorizationState.Authorized
    }
]