import * as React from 'react';
import Button from '@mui/material/Button';
import { Avatar, Box, Card, CardActions, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Pagination, Select, Stack, Switch, TextField, Typography, debounce } from '@mui/material';
import { approveAccountDeletionRequest, assignUserIdToDeletionRequest, cancelOffer, getAccountDeletionRequests, getFunnelList, getOffers, getPromoBanners, getTemplates, getUsersPaginated, paginateMatches, rejectAccountDeletionRequest } from '../Api';
import { useNavigate } from 'react-router-dom';
import PromoBannerDto from '../models/dtos/PromoBannerDto';
import PromoBannerCard from '../components/PromoBannerCard';
import TemplateDto from '../models/dtos/TemplateDto';
import OfferDto from '../models/dtos/OfferDto';
import { DataGrid } from '@mui/x-data-grid/DataGrid/DataGrid';
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import FunnelDto from '../models/dtos/FunnelDto';
import FunnelConfigDto from '../models/dtos/FunnelConfigDto';
import { GridCellParams, GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { useCallback } from 'react';
import AccountDeletionRequestDto from '../models/dtos/AccountDeletionRequestDto';
import { rowSelectionStateInitializer } from '@mui/x-data-grid/internals';
import AccountMainInfoDto from '../models/dtos/AccountMainInfoDto';
import MatchInfoDto from '../models/dtos/MatchInfoDto';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';

export const formatRevenue = (revenue: number) => {
    return revenue.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
}

export default function MatchesPage() {
    let defaultLimit = 25;
    let before = new Date();
    let after = new Date();
    before.setDate(before.getDate() + 7);
    after.setDate(after.getDate() - 7);

    const [state, setState] = React.useState({
        before: before,
        after: after,
        loaded: false,
        currentPage: 0,
        limit: defaultLimit,
        totalPages: 0,
        matchState: 1,
        beforeDisplay: before,
        afterDisplay: after,
        searchTerm: undefined as string | undefined,
        matches: [] as MatchInfoDto[]
    });

    const navigate = useNavigate();

    const onMatchClicked = async (matchId: number) => {
        navigate('/matches/' + matchId);
    }

    const getMatchStateText = (state: number) => {
        switch (state) {
            case 1: return 'Future';
            case 2: return 'Live';
            case 3: return 'Finished';
            default: return 'Processing';
            case 5: return 'Cancelled';
        }
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'id', width: 128 },
        {
            field: 'sport.photo', headerName: 'Sport', width: 100, renderCell: (params: GridCellParams) =>
                <React.Fragment><Avatar
                    src={params.row.sport.photo_url}
                    sx={{ width: 40, height: 40 }}
                /></React.Fragment>
        },
        {
            field: 'league.name', headerName: 'League', width: 150, renderCell: (params: GridCellParams) =>
                <React.Fragment>{params.row.league?.name}</React.Fragment>
        },
        {
            field: 'homeTeam.photo', headerName: 'Home', width: 64, renderCell: (params: GridCellParams) =>
                <React.Fragment><Avatar
                    src={params.row.home_team.photo_url}
                    sx={{ width: 40, height: 40 }}
                /></React.Fragment>
        },
        {
            field: 'homeTeam.name', headerName: '', width: 200, renderCell: (params: GridCellParams) =>
                <React.Fragment>{params.row.home_team?.name}</React.Fragment>
        },
        {
            field: 'awayTeam.photo', headerName: 'Away', width: 64, renderCell: (params: GridCellParams) =>
                <React.Fragment><Avatar
                    src={params.row.away_team.photo_url}
                    sx={{ width: 40, height: 40 }}
                /></React.Fragment>
        },
        {
            field: 'awayTeam.name', headerName: '', width: 200, renderCell: (params: GridCellParams) =>
                <React.Fragment>{params.row.away_team?.name}</React.Fragment>
        },
        {
            field: 'startDate', headerName: 'Date', width: 250, renderCell: (params: GridCellParams) =>
                <React.Fragment>{new Date(params.row.date).toLocaleString()}</React.Fragment>
        },
        {
            field: 'matchState', headerName: 'State', width: 250, renderCell: (params: GridCellParams) =>
                <React.Fragment>{getMatchStateText(params.row.matchState)}</React.Fragment>
        },
        {
            field: 'score', headerName: 'Score', width: 100, renderCell: (params: GridCellParams) =>
                <React.Fragment>{params.row.score_home} - {params.row.score_away}</React.Fragment>
        },
        {
            field: 'actions', headerName: 'Actions', width: 250, renderCell: (params: GridCellParams) =>
                <React.Fragment><Button variant="contained" fullWidth={true} style={{ height: '32px' }} onClick={() => onMatchClicked(params.row.id)}>Details</Button></React.Fragment>
        },
    ];

    const onDateRangeChanged = async (newBefore: Date, newAfter: Date) => {
        setState(prevState => ({ ...prevState, before: newBefore, after: newAfter }));
        await fetchData(0, state.limit, newBefore, newAfter, state.matchState, state.searchTerm);
    }

    const onMatchSearched = useCallback(debounce((searchTerm: string) => {
        fetchData(0, state.limit, state.before, state.after, state.matchState, searchTerm);
    }, 500), []);


    const fetchData = async (currentPage: number, limit: number, before: Date, after: Date, matchState: number, searchTerm?: string) => {
        setState(prevState => ({ ...prevState, loaded: false }));
        let response = await paginateMatches(currentPage * limit, limit, before.toISOString(), after.toISOString(), matchState, !searchTerm || searchTerm.length == 0 ? undefined : searchTerm);
        let matches = response?.items ?? [];
        let totalItems = response?.totalItems ?? 0;
        const totalPages = Math.ceil(totalItems / limit)
        setState(prevState => ({ ...prevState, before: before, after: after, matches: matches, loaded: true, totalPages: totalPages, currentPage: currentPage }));
    }

    const onPageChanged = async (p: number) => {
        await fetchData(p - 1, state.limit, state.before, state.after, state.matchState, state.searchTerm);
    }

    const resetClicked = async () => {
        let before = new Date();
        let after = new Date();
        before.setDate(before.getDate() + 7);
        after.setDate(after.getDate() - 7);
        setState(prevState => ({ ...prevState, currentPage: 0, limit: 25, searchTerm: '', beforeDisplay: before, before: before, afterDisplay: after, after: after }));
        await fetchData(0, state.limit, before, after, state.matchState, undefined);
    }

    React.useEffect(() => {
        fetchData(state.currentPage, state.limit, state.before, state.after, state.matchState, state.searchTerm);
    }, []);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div><Stack gap="50px 50px" sx={{ mx: 5, my: 5 }} justifyContent='center' alignItems='center'>
                <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4, lg: 6 }} alignItems='center'>
                    <Grid item xs={0} sm={1} md={2} lg={4} />
                    <Grid item xs={1} sm={1} md={1} lg={1}>
                        <Button variant="contained" fullWidth={true} style={{ height: '54px' }} onClick={() => navigate('/top-matches')}>TOP Matches</Button>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1}>
                        <Button variant="contained" fullWidth={true} style={{ height: '54px' }} onClick={() => navigate('/recalculations')}>Recalculations</Button>
                    </Grid>
                    <Box width="100%" />
                    <Grid item xs={1} sm={1} md={1} lg={1}>
                        <Typography variant="h3">Matches</Typography>
                    </Grid>
                    <Box width="100%" />
                    <Grid item xs={1} sm={1} md={1} lg={1}>
                        <TextField type='search' key="searchField" label="Enter name of team or league to search" variant="filled" fullWidth={true} value={state.searchTerm}
                            onChange={e => { setState(prev => ({ ...prev, searchTerm: e.target.value })); onMatchSearched(e.target.value); }}
                        />
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1}>
                        <DateTimePicker label='After: ' value={dayjs(state.afterDisplay)} onChange={e => setState(prevState => ({ ...prevState, afterDisplay: e!.toDate() }))} defaultValue={dayjs(state.after)} slotProps={{ textField: { fullWidth: true } }} onAccept={e => onDateRangeChanged(state.before, e!.toDate())} />
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1}>
                        <DateTimePicker label='Before: ' value={dayjs(state.beforeDisplay)} onChange={e => setState(prevState => ({ ...prevState, beforeDisplay: e!.toDate() }))} defaultValue={dayjs(state.before)} slotProps={{ textField: { fullWidth: true } }} onAccept={e => onDateRangeChanged(e!.toDate(), state.after)} />
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1}>
                        <Button variant="contained" fullWidth={true} style={{ height: '54px' }} onClick={resetClicked}>Reset</Button>
                    </Grid>
                    <Box width="100%" />
                </Grid>
                <Pagination count={state.totalPages} size="medium" onChange={(e, p) => onPageChanged(p)} page={state.currentPage +1} />
                {!state.loaded ? <Grid item xs={2}><CircularProgress size='100px' style={{ marginBottom: '32px' }} /></Grid> : <DataGrid sx={{ width: '100%' }}
                    rows={state.matches}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 25 },
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                    hideFooterPagination

                />}
            </Stack >
            </div>
        </LocalizationProvider>
    );
}