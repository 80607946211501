import * as React from 'react';
import Button from '@mui/material/Button';
import { Avatar, Box, Card, CardActions, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Pagination, Select, Stack, Switch, TextField, Typography, debounce, makeStyles } from '@mui/material';
import { approveAccountDeletionRequest, assignUserIdToDeletionRequest, cancelOffer, getAccountDeletionRequests, getFunnelList, getOffers, getPromoBanners, getSupportChatsPaginated, getSupportMessages, getTemplates, getUsersPaginated, rejectAccountDeletionRequest } from '../Api';
import { useNavigate } from 'react-router-dom';
import PromoBannerDto from '../models/dtos/PromoBannerDto';
import PromoBannerCard from '../components/PromoBannerCard';
import TemplateDto from '../models/dtos/TemplateDto';
import OfferDto from '../models/dtos/OfferDto';
import { DataGrid } from '@mui/x-data-grid/DataGrid/DataGrid';
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import FunnelDto from '../models/dtos/FunnelDto';
import FunnelConfigDto from '../models/dtos/FunnelConfigDto';
import { GridCellParams, GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { useCallback } from 'react';
import AccountDeletionRequestDto from '../models/dtos/AccountDeletionRequestDto';
import { rowSelectionStateInitializer } from '@mui/x-data-grid/internals';
import AccountMainInfoDto from '../models/dtos/AccountMainInfoDto';
import SupportMessageWithCommentCountDto from '../models/dtos/SupportMessageWithCommentCountDto';
import SupportChatDto from '../models/dtos/SupportChatDto';
import { Link } from 'react-router-dom';

export const formatRevenue = (revenue: number) => {
    return revenue.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
}

export default function SupportPage() {
    let defaultLimit = 100;
    const [state, setState] = React.useState({
        loaded: false,
        currentPage: 0,
        offset: 0,
        limit: defaultLimit,
        totalPages: 0,
        chats: [] as SupportChatDto[]
    });

    const navigate = useNavigate();

    const onMessageClicked = async (userId: number) => {
        navigate('/users/' + userId + '?tab=chat');
    }

    const onMessageRead = async (userId: number) => {
        let result = await getSupportMessages(userId);
        if (result) {
            let chat = state.chats.find(c => c.correspondentId == userId);
            if (chat) {
                chat.read = true;
                setState(prevState => ({ ...prevState, chats: state.chats }));
            }
        }
    }

    const columns: GridColDef[] = [
        { field: 'correspondentId', headerName: 'userId', width: 128 },
        {
            field: 'photoUrl', headerName: 'avatar ', width: 130, renderCell: (params: GridCellParams) =>
                <React.Fragment><Avatar
                    src={params.row.photoUrl}
                    sx={{ width: 40, height: 40 }}
                /></React.Fragment>
        },
        { field: 'name', headerName: 'name', width: 250 },
        { field: 'lastMessageText', headerName: 'message', width: 300 },
        {
            field: 'lastMessageDate', headerName: 'Date', width: 200, valueGetter: (params: GridValueGetterParams) =>
                new Date(params.row.lastMessageDate).toLocaleString()
        },
        {
            field: 'actions', headerName: 'Actions', width: 500, renderCell: (params: GridCellParams) =>
                <React.Fragment>
                    <Button component={Link} to={"/users/"+params.row.correspondentId+"?tab=chat"} variant="contained" style={{ height: '32px', width:'128px' }}>Chat</Button>&nbsp;
                    {
                        !params.row.read ? <Button variant="outlined" style={{ height: '32px' }} onClick={() => onMessageRead(params.row.correspondentId)}>Mark as read</Button>
                            : <div />
                    }

                </React.Fragment>
        },
    ];


    const fetchData = async (offset: number, limit: number) => {
        setState(prevState => ({ ...prevState, loaded: false }));
        let response = await getSupportChatsPaginated(offset, limit);
        let chats = response?.content ?? [];
        let totalItems = 10_000;
        const totalPages = Math.ceil(totalItems / limit)
        const currentPage = totalItems > 0 ? Math.ceil((offset + limit) / limit) : 1;
        setState(prevState => ({ ...prevState, chats: chats, loaded: true, totalPages: totalPages, currentPage: currentPage }));
    }

    const onPageChanged = async (p: number) => {
        await fetchData((p - 1) * state.limit, state.limit);
    }

    React.useEffect(() => {
        fetchData(state.offset, state.limit);
    }, []);

    return (
        <div><Stack gap="50px 50px" sx={{ mx: 5, my: 5 }} justifyContent='center' alignItems='center'>
            <Typography variant="h3">Support</Typography>
            <Pagination count={state.totalPages} size="medium" onChange={(e, p) => onPageChanged(p)} page={state.currentPage} />
            {!state.loaded ? <Grid item xs={2}><CircularProgress size='100px' style={{ marginBottom: '32px' }} /></Grid> : <DataGrid
                rows={state.chats}
                getRowId={(row) => row.correspondentId}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: state.limit },
                    },
                }}
                isRowSelectable={(params) => false}
                pageSizeOptions={[5, 10]}
                hideFooterPagination
                getRowClassName={(params) => params.row.read ? `messageRead` : `messageNotRead`}
                sx={{
                    width: '100%',
                    "& .messageRead": {
                        backgroundColor: "transparent",
                    },
                    "& .messageNotRead": {
                        backgroundColor: "lightGreen"
                    },
                    "&.Mui-hovered": {
                        background: 'transparent',
                        backgroundColor: "transparent",
                    },
                }}
            />}
        </Stack >
        </div>
    );
}