import * as React from 'react';
import Button from '@mui/material/Button';
import { Autocomplete, Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography, debounce } from '@mui/material';
import StatsCard from '../components/StatsCard';
import PeriodStats from '../models/PeriodStats';
import { deleteMatch, getFunnels, getMatchDetails, getMatchPromoLink, getOverviewCharts, getPromoBanners, getPromoMatch, getPromoMatches, getSearchMatches, getStats, putMatch } from '../Api';
import FunnelDto from '../models/dtos/FunnelDto';
import FunnelsCard from '../components/FunnelsCard';
import FullOverviewDto from '../models/dtos/FullOverviewDto';
import OverviewChartsDto from '../models/dtos/OverviewChartsDto';
import PromoMatchDto from '../models/dtos/PromoMatchDto';
import PromoMatchCard from '../components/PromoMatchCard';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PromoBannerDto from '../models/dtos/PromoBannerDto';
import MatchDto from '../models/dtos/MatchDto';
import { ChangeEvent, useCallback } from 'react';
import { getLocalizedString } from '../utils/LocalizationUtil';
import PromoBannerCard from '../components/PromoBannerCard';

export interface Cancelable {
    clear(): void;
}

export const formatRevenue = (revenue: number) => {
    return revenue.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
}

export default function AddPromoMatchPage() {
    const [state, setState] = React.useState({
        matches: [] as MatchDto[], banners: [] as PromoBannerDto[], matchInfo: {} as MatchDto, matchPresent: false,
        banner: {} as PromoBannerDto, dialogOpen: false, dialogMsg: '[]',
        countriesAvailable: '["us","ru","md"]', languagesAvailable: '["en","ru"]',
        countriesParsed: 0, languagesParsed: 0,
        promoLink: '', isFinished: false, isActive: true
    });
    const imgPlaceholder = 'https://static.thenounproject.com/png/3674270-200.png';
    const navigate = useNavigate()
    let id = useParams()['id'] as string
    let editedMatchId = id != undefined ? parseInt(id) : null;
    let isEditMode = id != undefined;

    const useQuery = () => new URLSearchParams(useLocation().search)

    // When opening create match page with predefined match id
    const queryParam = useQuery().get('betupMatchId')
    if (queryParam != null && queryParam != undefined){
        editedMatchId = parseInt(queryParam)
    }

    React.useEffect(() => {

        const fetchData = async () => {
            let [banners] = await Promise.all([getPromoBanners()]);
            setState({ ...state, matches: [], banners: banners, matchInfo: { betupId: 0, date: "" } as MatchDto });
            console.log("updated2 " + state.matchInfo.betupId);

            if (editedMatchId != null) {
                await setMatchFromUserIdInput(editedMatchId);
                let promoMatchInfo = await getPromoMatch(editedMatchId);
                if (promoMatchInfo != null) {
                    let promoLink = promoMatchInfo?.clickUrl ?? '';
                    let banner = banners.find(x => x.id == promoMatchInfo?.bannerId) ?? {} as PromoBannerDto;
                    setState(prevState => ({
                        ...prevState, banner: banner, promoLink: promoLink, isActive: promoMatchInfo?.active ?? true,
                        countriesAvailable: JSON.stringify(promoMatchInfo?.countriesAvailable ?? []),
                        languagesAvailable: JSON.stringify(promoMatchInfo?.languagesAvailable ?? []),
                        countriesParsed: promoMatchInfo!.countriesAvailable.length,
                        languagesParsed: promoMatchInfo!.languagesAvailable.length
                    }));
                }
            }
        }

        fetchData();
    }, [])

    const onMatchSelected = async (event: any, newValue: MatchDto | null) => {
        if (newValue != null) {
            setState({ ...state, matchInfo: { ...state.matchInfo, betupId: newValue.betupId } });

            let matchInfo = await getMatchDetails(newValue.betupId);

            if (matchInfo.betupId != undefined) {
                setState(prevState => ({ ...prevState, matchInfo: matchInfo, matchPresent: true, isFinished: matchInfo.finished }));
                await loadPromoLink(matchInfo.betupId);
            }
        }
    }

    const searchForMatch = async (searchTerm: string) => {
        let matches = await getSearchMatches(searchTerm);
        setState({ ...state, matches: matches });
    }

    const onBannerSelected = (bannerId: string) => {
        let banner = state.banners.find(x => x.id == bannerId);
        if (banner != null) {
            setState({ ...state, banner: banner });
        }
    }

    const onBetupIdChanged = useCallback(debounce((newId: number) => {
        setMatchFromUserIdInput(newId);
    }, 500), []);

    const onCreateClicked = async () => {
        var hasError = false;
        let errorMsg = '';

        let countries = [] as string[]
        let languages = [] as string[]

        try {
            countries = JSON.parse(state.countriesAvailable) as string[];
        } catch (e) { }

        try {
            languages = JSON.parse(state.languagesAvailable) as string[];
        } catch (e) { }

        if (!state.matchPresent) {
            hasError = true;
            errorMsg = "No valid match is selected!";
        } else if (state.banner.id == undefined || state.banner.id.length == 0) {
            hasError = true;
            errorMsg = "No valid banner is selected!";
        } else if (countries.length == 0) {
            hasError = true;
            errorMsg = "Promo should be available in at least one country";
        } else if (languages.length == 0) {
            hasError = true;
            errorMsg = "Promo should be available on at least one language";
        } else if (state.promoLink == null || state.promoLink.length == 0) {
            hasError = true;
            errorMsg = "Promo link is not defined for this match";
        }

        if (!hasError) {
            let result = await putMatch(state.matchInfo.betupId, state.banner.id, countries, languages, state.isActive);

            hasError = !result;

            if (!result) {
                errorMsg = "Server error!";
            }
        }

        if (hasError) {
            setState({ ...state, dialogMsg: errorMsg, dialogOpen: true });
        }
        else
            navigate('/promo-matches')
    }

    const onDeleteClicked = async () => {
        var hasError = false;
        let errorMsg = '';

        if (!hasError) {
            let result = await deleteMatch(state.matchInfo.betupId);

            hasError = !result;

            if (!result) {
                errorMsg = "Server error!";
            }
        }

        if (hasError) {
            setState({ ...state, dialogMsg: errorMsg, dialogOpen: true });
        }
        else
            navigate('/promo-matches')
    }

    const onEditBannersClicked = async () => {
        navigate('/promo-banners')
    }

    const handleDialogClose = () => {
        setState({ ...state, dialogOpen: false });
    }

    const loadPromoLink = async (id: number) => {
        let result = await getMatchPromoLink(id);
        if (result == null || result.length == 0)
            setState(prevState => ({ ...prevState, promoLink: '' }));
        else
            setState(prevState => ({ ...prevState, promoLink: result ?? '' }));
    }

    const setMatchFromUserIdInput = async (newId: number) => {
        if (newId > 100000 && newId < 10000000000) {

            let matchInfo = await getMatchDetails(newId);
            console.log("downloaded " + newId);

            if (matchInfo.betupId != undefined) {
                setState(prevState => ({ ...prevState, matchInfo: matchInfo, matchPresent: true, isFinished: matchInfo.finished }));
                if (!isEditMode)
                    await loadPromoLink(matchInfo.betupId);
            } else {
                setState(prevState => ({ ...prevState, matchInfo: { betupId: newId } as MatchDto, matchPresent: false }))
            }
        } else {
            setState(prevState => ({ ...prevState, matchInfo: { betupId: newId } as MatchDto, matchPresent: false }))
        }
    }

    const onCountriesUpdated = (countriesString: string) => {
        let countries = 0
        try {
            countries = (JSON.parse(countriesString) as string[]).length
        } catch (e) { }

        setState(prev => ({ ...prev, countriesAvailable: countriesString, countriesParsed: countries }))
    }

    const onLanguagesUpdated = (languagesString: string) => {
        let languages = 0
        try {
            languages = (JSON.parse(languagesString) as string[]).length
        } catch (e) { }

        setState(prev => ({ ...prev, languagesAvailable: languagesString, languagesParsed: languages }))
    }

    const onPromoButtonClicked = () => {
        if (state.promoLink != undefined)
            window.open(state.promoLink, "_blank", "noreferrer")
    }

    return (
        <div>
            <Stack gap="50px 50px" sx={{ mx: 5, my: 5 }} justifyContent='center' alignItems='center'>

                <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4, lg: 6 }} alignItems='center'>
                    <Grid item xs={0} sm={0} md={1} lg={2} />
                    <Grid item xs={2}>
                        <Typography variant="h3">{isEditMode ? "Edit promo match" : "Add new promo match"}</Typography>
                    </Grid>
                    {isEditMode ? <></> : <React.Fragment>
                        <Box width="100%" />
                        <Grid item xs={0} sm={0} md={1} lg={2} />
                        <Grid item xs={1}>
                            <Typography variant="h6">Search:</Typography>
                        </Grid>
                        <Box width="100%" />
                        <Grid item xs={0} sm={0} md={1} lg={2} />
                        <Grid item xs={2} key="searchField">
                            <Autocomplete
                                id="clear-on-escape"
                                clearOnEscape
                                onInputChange={(event: any, newValue: string | null, reason: string) => {
                                    if (reason != 'reset')
                                        searchForMatch(newValue ?? "");
                                }}
                                getOptionLabel={x => x.homeTeam?.name + " vs " + x.awayTeam?.name + " (" + x.sport?.name + ") " + new Date(x.date).toLocaleString()}
                                onChange={onMatchSelected}
                                options={state.matches.map(x => x)}
                                renderInput={(params) => (
                                    <TextField key="searchField" label="Enter team or league or sport name to search" variant="filled" {...params} fullWidth={true} />)}
                            />
                        </Grid></React.Fragment>}
                    <Box width="100%" />
                    <Grid item xs={0} sm={0} md={1} lg={2} />
                    <Grid item xs={2}>
                        <Typography variant="h6">Match details:</Typography>
                    </Grid>
                    <Box width="100%" />
                    {!state.matchPresent && isEditMode ?
                        <React.Fragment>
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="progress"><CircularProgress size={64} style={{ marginBottom: '32px' }} /></Grid>
                        </React.Fragment> : <React.Fragment>
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={2} key="betupId">
                                <TextField label="betupId" type="number" variant="filled" disabled={isEditMode} value={state.matchInfo.betupId || ''} fullWidth={true}
                                    onChange={e => { const value = parseInt(e.target.value); setState(prevState => ({ ...prevState, matchInfo: { ...prevState.matchInfo, betupId: value } })); onBetupIdChanged(value); }} />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={1}>
                                <Typography sx={{ fontStyle: 'italic' }} variant="h6">{new Date(state.matchInfo.date).toLocaleString()}</Typography>
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={1}>
                                <img width='48' height='48' src={state.matchInfo.homeTeam?.photo_url ?? imgPlaceholder} />
                                <Typography variant="subtitle1">{state.matchInfo.homeTeam?.name} (home)</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <img width='48' height='48' src={state.matchInfo.awayTeam?.photo_url ?? imgPlaceholder} />
                                <Typography variant="subtitle1">{state.matchInfo.awayTeam?.name} (away)</Typography>
                            </Grid>
                            <Grid item xs={2} alignItems='center' container>
                            </Grid>
                            <Box width="100%" />
                            {state.isFinished ? <React.Fragment><Grid item xs={0} sm={0} md={1} lg={2} />
                                <Grid item xs={2}>
                                    <Typography variant="h5">Match is finished. No add/update actions are allowed</Typography></Grid>
                                <Box width='100%' />
                                <Grid item xs={0} sm={0} md={1} lg={2} />
                                <Grid item xs={2} key="deleteMatchContainer">
                                    <Button variant="outlined" fullWidth={true} style={{ height: '54px' }} onClick={() => onDeleteClicked()}>Delete match</Button>
                                </Grid>
                            </React.Fragment>
                                : <React.Fragment>
                                    <Grid item xs={0} sm={0} md={1} lg={2} />
                                    <Grid item xs={2}>
                                        <Typography variant="h6">Promo details:</Typography>
                                    </Grid>

                                    <Box width='100%' />
                                    <Grid item xs={0} sm={0} md={1} lg={2} />
                                    <Grid item xs={2} key="countriesAvailableContent">
                                        <TextField label={`available in countries (${state.countriesParsed})`} variant="filled" value={state.countriesAvailable} fullWidth={true}
                                            onChange={e => onCountriesUpdated(e.target.value)} />
                                    </Grid>
                                    <Box width='100%' />
                                    <Grid item xs={0} sm={0} md={1} lg={2} />
                                    <Grid item xs={2} key="languagesAvailableContent">
                                        <TextField label={`available in languages (${state.languagesParsed})`} variant="filled" value={state.languagesAvailable} fullWidth={true}
                                            onChange={e => onLanguagesUpdated(e.target.value)} />
                                    </Grid>
                                    <Box width='100%' />
                                    <Grid item xs={0} sm={0} md={1} lg={2} />
                                    <Grid item xs={2} key="promoLinkContent">
                                        <TextField label="promoLink" variant="filled" value={state.promoLink} fullWidth={true}
                                            onChange={e => setState({ ...state, promoLink: e.target.value })} />
                                    </Grid>
                                    <Box width='100%' />
                                    <Grid item xs={0} sm={0} md={1} lg={2} />
                                    <Grid item xs={1} key="bannerSelect">
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">{state.banner.name ?? "Select banner"}</InputLabel>
                                            <Select
                                                labelId="banner-simple-select-label"
                                                id="banner-simple-select"
                                                value={state.banner.id}
                                                onChange={e => onBannerSelected(e.target.value)}
                                                label="Banner">
                                                {
                                                    state.banners.map(x => <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid> <Grid item xs={1} key="addBanner">
                                        <Button variant="contained" fullWidth={true} style={{ height: '54px' }} onClick={() => navigate('/promo-banners/')}>Edit banners</Button>
                                    </Grid>
                                    <Box width='100%' />
                                    <Grid item xs={0} sm={0} md={1} lg={2} />
                                    <Grid item xs={2}>
                                        <PromoBannerCard {...{ banner: state.banner, showEdit: true, previewLanguage: 'en', onClick: onPromoButtonClicked }} />
                                    </Grid>
                                    <Box width='100%' />
                                    <Grid item xs={0} sm={0} md={1} lg={2} />
                                    <Grid item xs={2} key="activeContainer">
                                        <FormControlLabel control={<Switch checked={state.isActive} onChange={e => setState(prevState => ({ ...prevState, isActive: e.target.checked }))} />} label="Is Active" />
                                    </Grid>
                                    <Box width='100%' />
                                    <Grid item xs={0} sm={0} md={1} lg={2} />
                                    <Grid item xs={isEditMode ? 1 : 2} key="addMatchContainer">
                                        <Button variant="contained" fullWidth={true} style={{ height: '54px' }} onClick={() => onCreateClicked()}>{isEditMode ? "Update match" : "Add match"}</Button>
                                    </Grid>
                                    {isEditMode ?
                                        <Grid item xs={1} key="deleteMatchContainer">
                                            <Button variant="outlined" fullWidth={true} style={{ height: '54px' }} onClick={() => onDeleteClicked()}>Delete match</Button>
                                        </Grid> : <div />}
                                </React.Fragment>}
                        </React.Fragment>}
                </Grid>

            </Stack >
            <Dialog
                open={state.dialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    Error
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {state.dialogMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Got it</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}