import * as React from 'react';
import Button from '@mui/material/Button';
import { Avatar, Box, Card, CardActions, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Pagination, Select, Snackbar, Stack, Switch, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Typography, debounce } from '@mui/material';
import { addUserEffects, approveAccountDeletionRequest, assignUserIdToDeletionRequest, cancelOffer, changeAdsStatusForUser, getAccountDeletionRequests, getBetlistsPaginated, getFunnelList, getOffers, getPromoBanners, getTemplates, getUserAggregatedInfo, getUsersPaginated, rejectAccountDeletionRequest, updateBetStateForBetlist, updateUserBalance } from '../Api';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PromoBannerDto from '../models/dtos/PromoBannerDto';
import PromoBannerCard from '../components/PromoBannerCard';
import TemplateDto from '../models/dtos/TemplateDto';
import OfferDto from '../models/dtos/OfferDto';
import { DataGrid } from '@mui/x-data-grid/DataGrid/DataGrid';
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import FunnelDto from '../models/dtos/FunnelDto';
import FunnelConfigDto from '../models/dtos/FunnelConfigDto';
import { GridCellParams, GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { useCallback } from 'react';
import AccountDeletionRequestDto from '../models/dtos/AccountDeletionRequestDto';
import { rowSelectionStateInitializer } from '@mui/x-data-grid/internals';
import AccountMainInfoDto from '../models/dtos/AccountMainInfoDto';
import AggregateUserInfoDto from '../models/dtos/AggregateUserInfoDto';
import BetlistShortDto from '../models/dtos/BetlistShortDto';
import BetlistsTable from '../components/BetlistsTable';
import BalanceTable from '../components/BalanceTable';
import SupportChatTable from '../components/SupportChatTable';


export default function UserPage() {
    let userId = parseInt(useParams()['id'] ?? '0');

    const getTabIndexForQueryParams = (tab : string | null) => {
        if (tab != null) {
            switch (tab)
            {
                default: return 0;
                case 'balance': return 1;
                case 'chat': return 2;
            }
        }

        return 0;
    }

    const [searchParams] = useSearchParams();

    const [state, setState] = React.useState({
        toastMsg: '' as string | null,
        tabIndex: getTabIndexForQueryParams(searchParams.get('tab')),
        loaded: false,
        userId: userId,
        user: {} as AggregateUserInfoDto,
        basicInfo: { id: userId, email: '', os: 'android', createdAt: '', countryName: '', countryPhotoUrl: '', experience: 0, appVersion: '', timezone: '', adsEnabled: true, level: 0, lang: '', totalMoneyWon: 0 },
        betlists: [] as BetlistShortDto[]
    });

    const navigate = useNavigate();

    const onUserClicked = async (userId: number) => {
        // todo implement
    }

    const onMessageClicked = async (userId: number) => {
        // todo implement
    }

    const onBalanceUpdateClicked = async () => {
        if (state.user.balance.moneyInfo < 0) {
            alert("Balance cannot be negative");
            return;
        }

        let result = await updateUserBalance(state.userId, state.user.balance.moneyInfo);
        setState(prevState => ({ ...prevState, toastMsg: result ? "Balance was updated!" : "Something went wrong!" }));
    }

    const onAdsChanged = async (value: boolean) => {
        let result = await changeAdsStatusForUser(state.userId, value);
        if (result)
            setState(prevState => ({ ...prevState, user: { ...prevState.user, advanced: { ...prevState.user.advanced, adsEnabled: value } } }));
    }

    const onAddEffectsClicked = async () => {
        let result = await addUserEffects(state.userId);
        setState(prevState => ({ ...prevState, toastMsg: result != null ? "Added " + result + " effects to the user!" : "Something went wrong!" }));
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'id', width: 128 },
        { field: 'email', headerName: 'email', width: 250 },
        {
            field: 'osType', headerName: 'OS', width: 64, renderCell: (params: GridCellParams) =>
                <React.Fragment><img src={params.row.osType == 'android' ? '/android_logo.png' : '/ios_logo.png'} width={24} height={24} /></React.Fragment>
        },
        { field: 'appVersion', headerName: 'AppVersion', width: 100 },
        {
            field: 'createdAt', headerName: 'CreatedAt', width: 200, valueGetter: (params: GridValueGetterParams) =>
                new Date(params.row.createdAt).toLocaleString()
        },
        {
            field: 'countryName', headerName: 'Country', width: 150, renderCell: (params: GridCellParams) =>
                <React.Fragment>{params.row.countryName}&nbsp;<img src={params.row.countryPhotoUrl} width={24} height={24} /></React.Fragment>
        },
        { field: 'timezone', headerName: 'Balance', width: 150 },
        { field: 'experience', headerName: 'Experience', width: 150, },
        { field: 'level', headerName: 'Level', width: 60 },
        { field: 'lang', headerName: 'Lang', width: 60 },
    ];

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setState(prevState => ({ ...prevState, tabIndex: newValue }));
    };

    const fetchData = async (userId: number) => {
        setState(prevState => ({ ...prevState, loaded: false }));
        let [userInfo, betlists] = await Promise.all([getUserAggregatedInfo(userId), getBetlistsPaginated(userId, 0, 100)]);
        let basicInfo = {
            id: userId,
            email: userInfo?.general.email ?? '', os: userInfo?.general.osType ?? '', createdAt: userInfo?.general.createdAt ?? '', countryName: userInfo?.advanced.countryName ?? '', countryPhotoUrl: userInfo?.advanced.countryPhotoUrl ?? '',
            timezone: userInfo?.advanced.timezone ?? '', experience: userInfo?.balance.experience ?? 0, appVersion: userInfo?.advanced.appVersion ?? '', adsEnabled: userInfo?.advanced.adsEnabled ?? true, level: userInfo?.balance.level ?? 0, lang: userInfo?.advanced.lang ?? '', totalMoneyWon: userInfo?.ranks.totalMoneyWon ?? 0
        };
        setState(prevState => ({ ...prevState, user: userInfo ?? { general: { name: 'Not found' } } as AggregateUserInfoDto, loaded: userInfo != null, basicInfo: basicInfo, betlists: betlists?.items ?? [] }));
    }

    const getTabForTabIndex = (index: number) => {
        switch (state.tabIndex) {
            case 0: return <BetlistsTable userId={state.userId} limit={100} />;
            case 1: return <BalanceTable userId={state.userId} limit={500} />;
            default: return <SupportChatTable userId={state.userId} />;
        }
    }

    React.useEffect(() => {
        fetchData(state.userId);
    }, []);

    return (
        <div>
            <Stack gap="50px 50px" sx={{ mx: 5, my: 5 }} justifyContent='center' alignItems='center'>
                <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4, lg: 6 }} alignItems='center'>
                    <Grid item xs={0} sm={1} md={3} lg={5} />
                    <Grid item xs={1} sm={1} md={1} lg={1}>
                        <Button variant="contained" fullWidth={true} style={{ height: '54px' }} onClick={() => navigate('/users')}>Go to Users</Button>
                    </Grid>
                    <Box width="100%" />
                    <Grid item xs={0} sm={0} md={1} lg={2} />
                    <Grid item xs={1} sm={1} md={2} lg={2}>
                        <Stack gap="50px 50px" sx={{ mx: 5, my: 5 }} justifyContent='center' alignItems='center' direction='row'>
                            <Avatar
                                alt={state.user?.general?.name}
                                src={state.user?.general?.photoUrl}
                                sx={{ width: 72, height: 72 }}
                            />
                            <Typography variant="h3">{state.loaded ? state.user.general.name : "Loading user info..."}</Typography>
                        </Stack>
                    </Grid>
                    <Box width="100%" />
                    {!state.loaded ?
                        <React.Fragment>
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={1} sm={2} md={2} lg={2} key="progress">
                                <Stack alignContent='center' alignItems="center" direction="row" sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <CircularProgress size={64} style={{ marginBottom: '32px' }} /></Stack></Grid>

                        </React.Fragment> : <React.Fragment>
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item lg={6} xs={1} sm={2} md={4}>
                                <DataGrid sx={{ width: '100%' }}
                                    rows={[state.basicInfo]}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 25 },
                                        },
                                    }}
                                    pageSizeOptions={[5, 10]}
                                    hideFooterPagination
                                    hideFooter
                                />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={1} sm={1} md={1} lg={1}>
                                <TextField label="Money balance" variant="filled" value={state.user.balance.moneyInfo} fullWidth={true} type='number'
                                    onChange={e => { setState(prevState => ({ ...prevState, user: { ...prevState.user, balance: { ...prevState.user.balance, moneyInfo: parseInt(e.target.value) } } })); }} />
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} lg={1}>
                                <Button variant="contained" fullWidth={true} style={{ height: '54px' }} onClick={() => onBalanceUpdateClicked()}>Update balance</Button>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} lg={1}>
                                <Button variant={state.user.advanced.adsEnabled ? 'outlined' : 'contained'} fullWidth={true} style={{ height: '54px' }} onClick={() => onAdsChanged(!state.user.advanced.adsEnabled)}>{state.user.advanced.adsEnabled ? 'Disable ads' : 'Enable ads'}</Button>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} lg={1}>
                                <Button variant="contained" fullWidth={true} style={{ height: '54px' }} onClick={() => onAddEffectsClicked()}>Add effects</Button>
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item lg={6} xs={1} sm={2} md={4}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={state.tabIndex} onChange={handleTabChange}>
                                        <Tab label="Betlists" />
                                        <Tab label="Balance history" />
                                        <Tab label="Chat with the user" />
                                    </Tabs>
                                </Box>
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item lg={6} xs={1} sm={2} md={4}>
                                {
                                    getTabForTabIndex(state.tabIndex)
                                }
                            </Grid>
                        </React.Fragment>}
                </Grid>
            </Stack>
            <Snackbar
                open={state.toastMsg != null}
                autoHideDuration={1500}
                message={state.toastMsg}
                onClose={() => { setState(prevState => ({ ...prevState, toastMsg: null })) }}
            />
        </div >
    );
}