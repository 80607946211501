import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Rating } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts';

interface VideoStatsCardProps {
    name: string,
    today: string,
    yesterday: string,
    month: string,
    chartDates : Date[],
    chartValues : number[]
}

export default function VideoStatsCard(stat: VideoStatsCardProps) {
    return (
        <Card sx={{ width: '100%' }}  variant='outlined'>
            <CardContent>
                <Typography gutterBottom variant="h4" component="div">
                    {stat.name}
                </Typography>
                <Typography variant="h5" color="text.secondary" sx={{ color: 'darkBlue', textAlign: 'justify' }}>
                    Month: {stat.month}
                </Typography>
                <Typography variant="h6" color="text.secondary" sx={{ color: 'blue', textAlign: 'justify' }}>
                    Today: {stat.today}
                </Typography>
                <Typography variant="h6" color="text.secondary" sx={{ color: 'blue', textAlign: 'justify' }}>
                    Yesterday: {stat.yesterday}
                </Typography>
                <LineChart
                    xAxis={[{ scaleType: 'time', data: stat.chartDates }]}
                    series={[
                        {
                            data: stat.chartValues,                            
                        },
                    ]}
                    height={300}             
                />
            </CardContent>
        </Card>
    );
}