import * as React from 'react';
import Button from '@mui/material/Button';
import { Box, CircularProgress, FormControl, Grid, Stack, TextField, Typography } from '@mui/material';
import StatsCard from '../components/StatsCard';
import PeriodStats from '../models/PeriodStats';
import { getFunnelList, getFunnels, getOverviewCharts, getStats } from '../Api';
import FunnelDto from '../models/dtos/FunnelDto';
import FunnelsCard from '../components/FunnelsCard';
import FullOverviewDto from '../models/dtos/FullOverviewDto';
import OverviewChartsDto from '../models/dtos/OverviewChartsDto';
import { useNavigate } from 'react-router-dom';

export const formatRevenue = (revenue: number) => {
    return revenue.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
}

export default function HomePage() {
    const [state, setState] = React.useState({ stats: [] as PeriodStats[], funnels: [] as FunnelDto[] });

    const navigate = useNavigate();

    React.useEffect(() => {
        const mapStats = (data: FullOverviewDto, charts: OverviewChartsDto) => {
            const result: PeriodStats[] = [];

            result.push({ name: "Revenue", today: formatRevenue(data.Today.revenue), week: formatRevenue(data.Week.revenue), month: formatRevenue(data.Month.revenue), yesterday: formatRevenue(data.Yesterday.revenue), chart: charts.revenue });
            result.push({ name: "Bets", today: data.Today.betsPlaced.toLocaleString(), week: data.Week.betsPlaced.toLocaleString(), month: data.Month.betsPlaced.toLocaleString(), yesterday: data.Yesterday.betsPlaced.toLocaleString(), chart: charts.bets });
            result.push({ name: "Purchases", today: data.Today.purchases.toLocaleString(), week: data.Week.purchases.toLocaleString(), month: data.Month.purchases.toLocaleString(), yesterday: data.Yesterday.purchases.toLocaleString(), chart: charts.purchases });
            result.push({ name: "New users", today: data.Today.usersRegistered.toLocaleString(), week: data.Week.usersRegistered.toLocaleString(), month: data.Month.usersRegistered.toLocaleString(), yesterday: data.Yesterday.usersRegistered.toLocaleString(), chart: charts.users });
            return result;
        }

        const fetchData = async () => {
            let [stats, funnels, charts, listOfFunnels] = await Promise.all([getStats(), getFunnels(), getOverviewCharts(), getFunnelList()]);
            funnels.forEach(x => { let funnelConfig = listOfFunnels.find(f => f.id == x.name); if (funnelConfig != undefined) x.name = funnelConfig.name; });
            setState({ funnels: funnels, stats: mapStats(stats, charts) });
        }

        fetchData();
    }, [])

    return (
        <Stack gap="50px 50px" sx={{ mx: 5, my: 5 }} justifyContent='center' alignItems='center'>
            <Typography variant="h3">Statistics</Typography>
            {state.stats.length == 0 ? <CircularProgress size={64} style={{ marginBottom: '32px' }} /> : <div />}
            <Grid container spacing={5} columns={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 4 }}>{
                state.stats.map(x =>
                    <Grid item xs={1} key={x.name}>
                        <StatsCard name={x.name} today={x.today} yesterday={x.yesterday} week={x.week} month={x.month} chartDates={x.chart.map(c => new Date(c.date))} chartValues={x.chart.map(c => c.value)} />
                    </Grid>)
            }
            </Grid>
            <Typography variant="h3">Funnels | Offers</Typography>
            <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4, lg: 5 }} justifyContent="flex-end">
                <Grid item xs={1} key="editConfiguration" alignContent="center">
                    <Button variant="outlined" fullWidth={true} style={{ height: '54px' }} onClick={() => navigate('/funnels')}>Funnels</Button>
                </Grid>
                <Grid item xs={1} key="viewOffers" alignContent="center">
                    <Button variant="contained" fullWidth={true} style={{ height: '54px' }} onClick={() => navigate('/funnels/offers')}>View offers</Button>
                </Grid>
            </Grid>
            {<Grid container spacing={5} columns={{ xs: 1, sm: 2, md: 2, lg: 3 }}>{
                state.funnels.map(x =>
                    <Grid item xs={1} key={x.name}>
                        <FunnelsCard {...x} />
                    </Grid>)
            }
            </Grid>}
        </Stack >
    );
}