import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Avatar, Button, CardActionArea, CardActions, CardHeader, Divider, Rating } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart, PieChart } from '@mui/x-charts';
import StatsMap from '../models/dtos/StatsMap';
import SupportMessageWithCommentCountDto from '../models/dtos/SupportMessageWithCommentCountDto';
import SupportMessageDto from '../models/dtos/SupportMessageDto';

interface MessageCardProps {
    message: SupportMessageDto,
    align: 'left' | 'right'
}

export default function MessageCard(props: MessageCardProps) {
    let backgroundColor = props.align == 'right' ? 'lightGreen' : 'lightBlue';
    let sendDate = new Date(props.message.sendDate);
    return (
        <Card variant='outlined'>
            <CardHeader
            sx={{ background: backgroundColor }}
                avatar={
                    <Avatar
                        src={props.message.user.avatarUrl}
                        sx={{ width: 32, height: 32 }}
                    />
                }
                title={props.message.user.name}
            />
            <CardContent sx={{ background: backgroundColor }}>
                <Typography gutterBottom variant="body1" component="div">
                    {props.message.message}
                </Typography>
                <Divider /><br />
                <Typography gutterBottom variant="body2" component="div">
                    {sendDate.toLocaleDateString()}, {sendDate.toLocaleTimeString()}
                </Typography>
            </CardContent>
        </Card>
    );
}