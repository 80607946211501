import * as React from 'react';
import Button from '@mui/material/Button';
import { Box, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Pagination, Select, Stack, Typography } from '@mui/material';
import { getPromoBanners, getTopMatches } from '../Api';
import { useNavigate } from 'react-router-dom';
import PromoBannerDto from '../models/dtos/PromoBannerDto';
import PromoBannerCard from '../components/PromoBannerCard';
import ExtendedMatchDto from '../models/dtos/ExtendedMatchDto';
import MatchCard from '../components/MatchCard';

export const formatRevenue = (revenue: number) => {
    return revenue.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
}

export default function TopMatchesPage() {
    const defaultLimit = 12;

    const [state, setState] = React.useState({
        matchesLoaded: false,
        topMatches: [] as ExtendedMatchDto[],
        offset: 0,
        limit: defaultLimit,
        totalPages: 0,
        currentPage: 1,
        currentPeriod: { id: '7d', 'title': '1 Week' },
        periods: [{ id: 'now', title: 'Live' }, { id: '6h', title: '6 Hours' }, { id: '12h', title: '12 Hours' }, { id: '1d', title: '24 Hours' }, { id: '7d', title: '1 Week' }, { id: 'all', title: '14 Days' }]
    });

    const navigate = useNavigate()

    const loadPage = async (offset: number, limit: number, period : string) => {
        let result = await getTopMatches(offset, limit, period);

        if (result != null) {

            const totalPages = Math.ceil(result.totalItems / limit)
            const currentPage = result.totalItems > 0 ? Math.ceil((offset + limit) / limit) : 1;

            if (result != null) {
                setState(prev => ({
                    ...prev,
                    matchesLoaded: true,
                    topMatches: result!.items,
                    offset: offset,
                    limit: limit,
                    totalPages: totalPages,
                    currentPage: currentPage
                }))
            }
        }
    }

    const onPageChanged = async (p: number) => {
        await loadPage((p - 1) * state.limit, state.limit, state.currentPeriod.id);
    }

    const onPeriodChanged = async (newPeriodTitle: string) => {
        let period = state.periods.find(x => x.title == newPeriodTitle)

        if (period == undefined || period == null)
            return;

        await loadPage(0, defaultLimit, period.id);
        setState(prev => ({ ...prev, currentPeriod: { id: period!.id, title: period!.title } }))
    }

    React.useEffect(() => {
        loadPage(state.offset, state.limit, state.currentPeriod.id);
    }, [])

    return (
        <Stack gap="50px 50px" sx={{ mx: 5, my: 5 }} justifyContent='center' alignItems='center'>
            <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4, lg: 5 }} justifyContent="flex-end">
                <Grid item xs={1} key="matchesButton" alignContent="center">
                    <Button variant="contained" fullWidth={true} style={{ height: '54px' }} onClick={() => navigate('/promo-matches')}>Go to promo matches</Button>
                </Grid>
            </Grid>
            <Typography variant="h3">TOP matches</Typography>
            <FormControl fullWidth>
                <InputLabel id="demo-location-select-label">{state.currentPeriod.title ?? "Select period"}</InputLabel>
                <Select
                    labelId="banner-simple-select-label"
                    id="location-simple-select"
                    value={state.currentPeriod.title}
                    onChange={e => onPeriodChanged(e.target.value)}
                    label="Location">
                    {
                        state.periods.map(x => <MenuItem value={x.title} key={x.id}>{x.title}</MenuItem>)
                    }
                </Select>
            </FormControl>
            <Pagination count={state.totalPages} size="medium" onChange={(e, p) => onPageChanged(p)} page={state.currentPage} />
            {!state.matchesLoaded ? <Grid item xs={2}><CircularProgress size='25%' style={{ marginBottom: '32px', marginLeft: '35%' }} /></Grid> : <div />}
            <Grid container spacing={5} columns={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 4 }}>
                <Box width='100%' />
                {
                    state.topMatches.map(x =>
                        <Grid item xs={1} key={x.betupId}>
                            <MatchCard {...x} />
                        </Grid>)
                }
            </Grid>
        </Stack >
    );
}