import * as React from "react";
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { routes as appRoutes } from "./routes";
import { Box, createTheme, ThemeProvider } from "@mui/material";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { checkAuthentication, getAccessToken, getLocalUser } from "./UserStorage";
import ProtectedRoute from "./routing/ProtectedRoute";

export const UserContext = React.createContext({ id: '', name: 'Jorik', email: '', isAuthenticated: false, photoUrl: '', accessToken: '', notifyContextUpdated: ()=>{}});
export interface UserState {
    id: string,
    name: string,
    email: string,
    photoUrl: string,
    isAuthenticated: boolean,
    accessToken: string,
    notifyContextUpdated: () => void
}

const App = () => {

    const calculateState = () : UserState => {
        let isAuthenticated = checkAuthentication();
        let localUser = getLocalUser();
        let accessToken = getAccessToken();
        if (isAuthenticated && localUser != null && accessToken != null) {
            return { notifyContextUpdated: notifyContextUpdated, id: localUser.id, name: localUser.name, email: localUser.email, photoUrl: localUser.photoUrl, accessToken: accessToken, isAuthenticated: true };
        } else {
            return { notifyContextUpdated: notifyContextUpdated, id: '', name: '', email: '', photoUrl: '', isAuthenticated: false, accessToken: '' };
        }
    }

    const notifyContextUpdated = () => {
        setState(calculateState());
    }

    const [state, setState] = React.useState(calculateState());
    const theme = createTheme({
        palette: {
            primary: {
                light: "#63b8ff",
                main: "#0989e3",
                dark: "#005db0",
                contrastText: "#000",
            },
            secondary: {
                main: "#888888",
                light: "#82e9de",
                dark: "#00867d",
                contrastText: "#000",
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <UserContext.Provider value={state}>
                <CssBaseline />
                <Box height="100vh" display="flex" flexDirection="column">
                    <Router>
                        <Navbar />
                        <Routes>
                            {appRoutes.map((route) => (
                                <Route
                                    key={route.key}
                                    path={route.path}
                                    element={<ProtectedRoute authType={route.authType} outlet={route.component}/>}
                                />
                            ))}
                        </Routes>
                        <Footer />
                    </Router>
                </Box>
            </UserContext.Provider>
        </ThemeProvider>
    );
}

export default App;