import * as React from 'react';
import Button from '@mui/material/Button';
import { Avatar, Box, Card, CardActions, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Pagination, Select, Snackbar, Stack, Switch, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, TextField, Typography, debounce } from '@mui/material';
import { addUserEffects, approveAccountDeletionRequest, assignUserIdToDeletionRequest, cancelOffer, changeAdsStatusForUser, getAccountDeletionRequests, getBetlistsPaginated, getFullMatchInfo, getFunnelList, getOffers, getPromoBanners, getTemplates, getUserAggregatedInfo, getUsersPaginated, rejectAccountDeletionRequest, updateBetStateForBetlist, updateUserBalance } from '../Api';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PromoBannerDto from '../models/dtos/PromoBannerDto';
import PromoBannerCard from '../components/PromoBannerCard';
import TemplateDto from '../models/dtos/TemplateDto';
import OfferDto from '../models/dtos/OfferDto';
import { DataGrid } from '@mui/x-data-grid/DataGrid/DataGrid';
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import FunnelDto from '../models/dtos/FunnelDto';
import FunnelConfigDto from '../models/dtos/FunnelConfigDto';
import { GridCellParams, GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { useCallback } from 'react';
import AccountDeletionRequestDto from '../models/dtos/AccountDeletionRequestDto';
import { rowSelectionStateInitializer } from '@mui/x-data-grid/internals';
import AccountMainInfoDto from '../models/dtos/AccountMainInfoDto';
import AggregateUserInfoDto from '../models/dtos/AggregateUserInfoDto';
import BetlistShortDto from '../models/dtos/BetlistShortDto';
import BetlistsTable from '../components/BetlistsTable';
import BalanceTable from '../components/BalanceTable';
import SupportChatTable from '../components/SupportChatTable';
import MatchInfoDto from '../models/dtos/MatchInfoDto';
import MatchVarietyDto from '../models/dtos/MatchVarietyDto';
import MatchAdditionalInfoDto from '../models/dtos/MatchAdditionalInfoDto';
import VarietiesTable from '../components/VarietiesTable';
import BetsForMatchesTable from '../components/BetsForMatchesTable';


export default function MatchPage() {
    let matchId = parseInt(useParams()['id'] ?? '0');

    const getTabIndexForQueryParams = (tab: string | null) => {
        if (tab != null) {
            switch (tab) {
                default: return 0;
                case 'bets': return 1;
            }
        }

        return 0;
    }

    const [searchParams] = useSearchParams();

    const [state, setState] = React.useState({
        toastMsg: '' as string | null,
        tabIndex: getTabIndexForQueryParams(searchParams.get('tab')),
        loaded: false,
        matchId: matchId,
        matchInfo: {} as MatchInfoDto,
        matchStats: { views: 0, bets: 0 } as MatchAdditionalInfoDto,
        varieties: [] as MatchVarietyDto[]
    });

    const navigate = useNavigate();


    const getMatchStateText = (state: number) => {
        switch (state) {
            case 1: return 'Future';
            case 2: return 'Live';
            case 3: return 'Finished';
            default: return 'Processing';
            case 5: return 'Cancelled';
        }
    }


    const columns: GridColDef[] = [
        { field: 'id', headerName: 'id', width: 128 },
        {
            field: 'sport.photo', headerName: 'Sport', width: 100, renderCell: (params: GridCellParams) =>
                <React.Fragment><Avatar
                    src={params.row.sport.photo_url}
                    sx={{ width: 40, height: 40 }}
                /></React.Fragment>
        },
        {
            field: 'sport.name', headerName: '', width: 150, renderCell: (params: GridCellParams) =>
                <React.Fragment>{params.row.sport?.name}</React.Fragment>
        },
        {
            field: 'league.name', headerName: 'League', width: 150, renderCell: (params: GridCellParams) =>
                <React.Fragment>{params.row.league?.name}</React.Fragment>
        },
        {
            field: 'startDate', headerName: 'Date', width: 250, renderCell: (params: GridCellParams) =>
                <React.Fragment>{new Date(params.row.date).toLocaleString()}</React.Fragment>
        },
        {
            field: 'matchState', headerName: 'State', width: 250, renderCell: (params: GridCellParams) =>
                <React.Fragment>{getMatchStateText(params.row.matchState)}</React.Fragment>
        },
        {
            field: 'score', headerName: 'Score', width: 100, renderCell: (params: GridCellParams) =>
                <React.Fragment>{params.row.score_home} - {params.row.score_away}</React.Fragment>
        },
        {
            field: 'rawScore', headerName: 'Raw', width: 300, renderCell: (params: GridCellParams) =>
                <React.Fragment>{params.row.result_raw}</React.Fragment>
        },
        {
            field: 'bets', headerName: 'Bets', width: 100, renderCell: (params: GridCellParams) =>
                <React.Fragment>{state.matchStats.bets}</React.Fragment>
        },
        {
            field: 'views', headerName: 'Views', width: 100, renderCell: (params: GridCellParams) =>
                <React.Fragment>{state.matchStats.views}</React.Fragment>
        }
    ];

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setState(prevState => ({ ...prevState, tabIndex: newValue }));
    };

    const fetchData = async (userId: number) => {
        setState(prevState => ({ ...prevState, loaded: false }));
        let matchInfo = await getFullMatchInfo(userId);
        setState(prevState => ({ ...prevState, matchInfo: matchInfo?.matchInfo ?? { id: matchId } as MatchInfoDto, loaded: matchInfo != null, varieties : matchInfo?.varieties ?? [], matchStats: matchInfo?.stats ?? {views:0, bets:0} }));
    }

    const getTabForTabIndex = (index: number) => {
        switch (state.tabIndex) {
            default: return <VarietiesTable matchId={state.matchId} />;
            case 1 : return <BetsForMatchesTable matchId={state.matchId} varieties={state.varieties}/>;
        }
    }

    React.useEffect(() => {
        fetchData(state.matchId);
    }, []);

    return (
        <div>
            <Stack gap="50px 50px" sx={{ mx: 5, my: 5 }} justifyContent='center' alignItems='center'>
                <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4, lg: 6 }} alignItems='center'>
                    <Grid item xs={0} sm={1} md={3} lg={5} />
                    <Grid item xs={1} sm={1} md={1} lg={1}>
                        <Button variant="contained" fullWidth={true} style={{ height: '54px' }} onClick={() => navigate('/matches')}>Go to Matches</Button>
                    </Grid>
                    <Box width="100%" />
                    <Grid item xs={1} sm={1} md={4} lg={6}>
                        <Stack gap="50px 50px" sx={{ mx: 5, my: 5 }} justifyContent='center' alignItems='center' direction='row'>
                            <Avatar
                                alt={state.matchInfo?.home_team?.name}
                                src={state.matchInfo?.home_team?.photo_url}
                                sx={{ width: 72, height: 72 }}
                            />
                            <Typography variant="h5">{state.loaded ? state.matchInfo.home_team?.name + ' vs ' + state.matchInfo.away_team?.name : "Loading match info..."}</Typography>
                            <Avatar
                                alt={state.matchInfo?.away_team?.name}
                                src={state.matchInfo?.away_team?.photo_url}
                                sx={{ width: 72, height: 72 }}
                            />
                        </Stack>
                    </Grid>
                    <Box width="100%" />
                    {!state.loaded ?
                        <React.Fragment>
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item xs={1} sm={2} md={2} lg={2} key="progress">
                                <Stack alignContent='center' alignItems="center" direction="row" sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <CircularProgress size={64} style={{ marginBottom: '32px' }} /></Stack></Grid>

                        </React.Fragment> : <React.Fragment>
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item lg={6} xs={1} sm={2} md={4}>
                                <DataGrid sx={{ width: '100%' }}
                                    rows={[state.matchInfo]}
                                    columns={columns}
                                    initialState={{
                                        pagination: {
                                            paginationModel: { page: 0, pageSize: 25 },
                                        },
                                    }}
                                    pageSizeOptions={[5, 10]}
                                    hideFooterPagination
                                    hideFooter
                                />
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item lg={6} xs={1} sm={2} md={4}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={state.tabIndex} onChange={handleTabChange}>
                                        <Tab label="Varieties" />
                                        <Tab label="Bets" />
                                    </Tabs>
                                </Box>
                            </Grid>
                            <Box width="100%" />
                            <Grid item xs={0} sm={0} md={1} lg={2} />
                            <Grid item lg={6} xs={1} sm={2} md={4}>
                                {
                                    getTabForTabIndex(state.tabIndex)
                                }
                            </Grid>
                        </React.Fragment>}
                </Grid>
            </Stack>
            <Snackbar
                open={state.toastMsg != null}
                autoHideDuration={1500}
                message={state.toastMsg}
                onClose={() => { setState(prevState => ({ ...prevState, toastMsg: null })) }}
            />
        </div >
    );
}