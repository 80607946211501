import React, { FC, ReactElement, useContext } from "react";
import {
    Box,
    Link,
    Container,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
    Drawer,
    Avatar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { AuthorizationState, routes } from "../routes";
import { NavLink } from "react-router-dom";
import { UserContext } from "../App";
import { checkAuthentication } from "../UserStorage";

const Navbar: FC = (): ReactElement => {
    const [state, setState] = React.useState({ navMenuAnchor: null, userMenuAnchor: null });

    const handleOpenNavMenu = (event: any) => {
        setState({ ...state, navMenuAnchor: event.currentTarget });
    };

    const handleCloseNavMenu = () => {
        setState({ ...state, navMenuAnchor: null });
    };

    const handleOpenUserMenu = (event: any) => {
        setState({ ...state, userMenuAnchor: event.currentTarget });
    };

    const handleCloseUserMenu = () => {
        setState({ ...state, userMenuAnchor: null });
    };

    let context = useContext(UserContext);

    const isItemVisible = (authType: AuthorizationState) => {
        if (authType == AuthorizationState.Any)
            return true;
        if (authType == AuthorizationState.Authorized && context.isAuthenticated)
            return true;

        if (authType == AuthorizationState.Unauthorized && !context.isAuthenticated)
            return true;

        return false;
    }

    const getAvatarName = (name: string) => {
        if (name.trim().length == 0)
            return "A";

        let result = "";
        let words = name.split(" ");
        words.forEach(word => {
            word = word.trim();
            if (word.length > 0) {
                result += word[0];
            }
        });

        return result;
    }

    let userInfoDisplay = context.isAuthenticated ? "flex" : "none";
    let signInDisplay = context.isAuthenticated ? "none" : "flex";
    let isAuthenticated = checkAuthentication();
    return (
        <Box
            sx={{
                width: "100%",
                height: "auto",
                backgroundColor: "secondary.main",
            }}
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        sx={{
                            mr: 2,
                            display: { xs: "none", md: "flex" },
                        }}
                    >
                        {isAuthenticated ?
              "BETUP Dashboard" : "BETUP"}
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={state.navMenuAnchor}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(state.navMenuAnchor)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}
                        >
                            {routes.filter((page) => page.presentOnNavBar && isItemVisible(page.authType)).map((page) => (
                                <Link
                                    key={page.key}
                                    component={NavLink}
                                    to={page.path}
                                    color="black"
                                    underline="none"
                                    variant="button"
                                >
                                    <MenuItem onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center">{page.title}</Typography>
                                    </MenuItem>
                                </Link>
                            ))}
                        </Menu>
                    </Box>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
                    >
                        {isAuthenticated ?
              "BETUP Dashboard" : "BETUP"}
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                marginLeft: "1rem",
                            }}
                        >
                            {routes.filter((page) => page.presentOnNavBar && isItemVisible(page.authType)).map((page) => (
                                <Link
                                    key={page.key}
                                    component={NavLink}
                                    to={page.path}
                                    color="black"
                                    underline="none"
                                    variant="button"
                                    sx={{ fontSize: "large", marginLeft: "2rem" }}>
                                    {page.title}
                                </Link>
                            ))}
                        </Box>
                    </Box>
                    <Box sx={{ flexGrow: 0, display: { xs: userInfoDisplay }, alignItems: "center", justifyContent: "right" }}>
                        <Link
                            key="logout"
                            component={NavLink}
                            to="/logout"
                            color="black"
                            underline="none"
                            variant="button">Logout</Link>
                    </Box>
                </Toolbar>
            </Container>
        </Box>
    );
};

export default Navbar;