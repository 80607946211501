import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Divider, Rating } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart, PieChart } from '@mui/x-charts';
import StatsMap from '../models/dtos/StatsMap';

interface PieStatsCardProps {
    name: string,
    stats: StatsMap
}

export default function PieStatsCard(stat: PieStatsCardProps) {
    let data = Object.keys(stat.stats).map((x, index) => ({ id: index, value: stat.stats[x], label: x }))
    let series = [{ data: data }]
    return (
        <Card sx={{ width: '100%', height: '400px' }} variant='outlined'>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {stat.name}
                </Typography>
                <Divider/><br/>
                <PieChart
                    series={series}
                    width={400}
                    height={200}
                />
            </CardContent>
        </Card>
    );
}