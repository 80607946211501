import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, Button, CardActionArea, CardActions, Divider, Rating } from '@mui/material';
import { formatRevenue } from '../Pages/HomePage';
import PromoMatchDto from '../models/dtos/PromoMatchDto';
import PromoMatchModel from '../models/PromoMatchModel';
import { getLocalizedFromString, getLocalizedString, getLocalizedStringFor } from '../utils/LocalizationUtil';
import { useNavigate } from 'react-router-dom';
import PromoBannerDto from '../models/dtos/PromoBannerDto';
import ShopItemDto from '../models/dtos/ShopItemDto';

interface ShopItemProps {
    item: ShopItemDto,
    previewLanguage: string,
    showEdit: boolean,
    onClick?: () => void
}

export default function ShopItemCard(model: ShopItemProps) {
    const navigate = useNavigate()
    let img = model.item.iconUrl;
    let title = getLocalizedFromString("", model.previewLanguage, model.item.titleText);
    let height = model.item.type == "Banner" ? '300px' : '150px';
    let opacity = model.item.deleted ? 0.5 : 1;
    let effectDescription = getLocalizedFromString("", "en", model.item.effectName);
    if (effectDescription != undefined && effectDescription != null && effectDescription.length > 0){
        effectDescription = effectDescription.replace("%s", model.item.effectDuration?.toString() ?? "0");
    }
    console.log(model.item.titleText);

    return (
        <Card sx={{ width: '100%', opacity: opacity }} >
            <div onClick={model.onClick} style={{ background: '#285e8d', padding: '4px', textAlign: 'center', color: 'white', cursor: 'pointer' }}><b>[{model.item.type}] (interest factor: {model.item.interestFactor}%)</b></div>
            <Typography variant="h6" color="text.secondary" sx={{ color: 'black', textAlign: 'center', margin: '8px' }}>
                {model.item.storeCode}
            </Typography>
            <Divider />
            <CardContent sx={{ height: height, background: model.item.color }}>
                {model.item.type == "Banner" ?
                    <React.Fragment>
                        <br />
                        <img src={img} height={128} width={128} style={{ float: 'left', marginRight: '16px', marginTop: '-4px' }} />
                        {title == "" ? <React.Fragment/> : <React.Fragment><Typography variant="h4" color="inherit" noWrap display="inline">{title}</Typography><br/></React.Fragment>}
                        <Typography variant="h6" color="inherit" noWrap display="inline">{model.item.betcoins} Betcoins</Typography><br/>
                        <React.Fragment><Typography variant="h6" color="inherit" noWrap display="inline">{effectDescription}</Typography><br/></React.Fragment>
                        {model.item.noAds == true ? <React.Fragment><Typography variant="h6" color="inherit" noWrap display="inline">No Ads Forever!</Typography><br/></React.Fragment> : <div/>}
                        <br /> <br />
                        <Button size="medium" variant="contained" sx={{ width: '25%' }}>{model.item.visualPrice} $</Button>
                        <br /> <br />
                        <Divider />
                    </React.Fragment> : <React.Fragment>
                        <br />
                        <img src={img} height={64} width={64} style={{ float: 'left', marginRight: '16px', marginTop: '-4px' }} />
                        <Typography variant="h5" color="inherit" noWrap display="inline">{model.item.betcoins} Betcoins</Typography>&nbsp;&nbsp;&nbsp;
                        <Button size="medium" variant="contained" sx={{ width: '25%' }}>{model.item.visualPrice} $</Button>
                        <br/><br/><br/>
                        <Divider />
                    </React.Fragment>}
                <br />
            </CardContent>
            {model.showEdit ?
                <CardActions >
                    <Button size="small" color="primary" variant="contained" sx={{ marginLeft: 'auto', color: 'white' }} onClick={e => navigate('/shop-items/' + model.item.id + '/edit')}>
                        Edit
                    </Button>
                </CardActions> : <div />}
        </Card>
    );
}
