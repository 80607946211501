import * as React from 'react';
import Button from '@mui/material/Button';
import { Box, CircularProgress, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Pagination, Select, Stack, Switch, TextField, Typography, debounce } from '@mui/material';
import StatsCard from '../components/StatsCard';
import PeriodStats from '../models/PeriodStats';
import { getFunnels, getOverviewCharts, getPromoBanners, getPromoMatches, getStats } from '../Api';
import FunnelDto from '../models/dtos/FunnelDto';
import FunnelsCard from '../components/FunnelsCard';
import FullOverviewDto from '../models/dtos/FullOverviewDto';
import OverviewChartsDto from '../models/dtos/OverviewChartsDto';
import PromoMatchDto from '../models/dtos/PromoMatchDto';
import PromoMatchCard from '../components/PromoMatchCard';
import { useNavigate } from 'react-router-dom';
import PromoMatchModel from '../models/PromoMatchModel';
import PromoBannerDto from '../models/dtos/PromoBannerDto';
import { useCallback } from 'react';

export const formatRevenue = (revenue: number) => {
    return revenue.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
}

export default function PromoMatchesPage() {
    const defaultLimit = 12;

    const [state, setState] = React.useState({
        matchesLoaded: false,
        promoMatches: [] as PromoMatchModel[], banners: [] as PromoBannerDto[],
        onlyActive: true, offset: 0, limit: defaultLimit, totalPages: 0,
        currentPage: 1,
        searchTerm: ''
    });

    const navigate = useNavigate()


    const loadPage = async (onlyActive: boolean, offset: number, limit: number, searchTerm: string) => {
        const [matchResponse] = await Promise.all([getPromoMatches(onlyActive, offset, limit, searchTerm)]);
        const matches = matchResponse?.items ?? [];
        const totalItems = matchResponse?.totalItems ?? 0;
        const totalPages = Math.ceil(totalItems / limit)
        const currentPage = totalItems > 0 ? Math.ceil((offset + limit) / limit) : 1;

        setState(prevState => ({
            ...prevState,
            promoMatches: matches.map(x => ({ match: x, banner: prevState.banners.find(b => b.id == x.bannerId) }) as PromoMatchModel),
            totalPages: totalPages,
            currentPage: currentPage,
            onlyActive: onlyActive,
            matchesLoaded: true,
            searchTerm: searchTerm
        }));
    }

    const onPageChanged = async (p: number) => {
        await loadPage(state.onlyActive, (p - 1) * state.limit, state.limit, state.searchTerm);
    }

    const onActiveChanged = async (active: boolean) => {
        console.log("active changed " + active);
        setState(prevState => ({ ...prevState, offset: 0, limit: defaultLimit, onlyActive: active }));
        await loadPage(active, 0, defaultLimit, state.searchTerm);
    }

    const onMatchSearched = useCallback(debounce((searchTerm: string, active: boolean) => {
        loadPage(active, state.offset, state.limit, searchTerm);
    }, 500), []);

    React.useEffect(() => {
        console.log("fetching...");

        const fetchData = async () => {
            let banners = await getPromoBanners();
            setState(prev => ({ ...prev, banners }));
            await loadPage(state.onlyActive, state.offset, state.limit, state.searchTerm);
        }

        fetchData();
    }, [])


    return (
        <Stack gap="50px 50px" sx={{ mx: 5, my: 5 }} justifyContent='center' alignItems='center'>
            <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4, lg: 5 }} justifyContent="flex-end">
                <Grid item xs={1} key="addButton" alignContent="center">
                    <Button variant="contained" fullWidth={true} style={{ height: '54px' }} onClick={() => navigate('/promo-matches/create')}>Add match</Button>
                </Grid>
                <Grid item xs={1} key="editBanners" alignContent="center">
                    <Button variant="outlined" fullWidth={true} style={{ height: '54px' }} onClick={() => navigate('/promo-banners')}>Edit banners</Button>
                </Grid>
                <Grid item xs={1} key="viewStats" alignContent="center">
                    <Button variant="outlined" fullWidth={true} style={{ height: '54px' }} onClick={() => navigate('/promo-matches/stats')}>View stats</Button>
                </Grid>
                <Grid item xs={1} key="settings" alignContent="center">
                    <Button variant="outlined" fullWidth={true} style={{ height: '54px' }} onClick={() => navigate('/promo-config')}>Settings</Button>
                </Grid>
            </Grid>
            <Typography variant="h3">Promo Matches</Typography>
            {!state.matchesLoaded ? <CircularProgress size={64} style={{ marginBottom: '32px' }} /> : <div />}
            <Grid container spacing={5} columns={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 4 }} alignItems='center'>
                <Grid item xs={1} key="searchField">
                    <TextField type='search' key="searchField" label="Enter team or sport name to search" variant="filled" fullWidth={true} value={state.searchTerm}
                        onChange={e => { console.log('callback = ' + state.onlyActive); setState(prev => ({ ...prev, searchTerm: e.target.value })); onMatchSearched(e.target.value, state.onlyActive); }}
                    />
                </Grid>
                <Grid item xs={1} >
                    <Pagination count={state.totalPages} size="medium" onChange={(e, p) => onPageChanged(p)} page={state.currentPage} />
                </Grid>
                <Grid item xs={1} key="addButton" alignContent="center" alignItems='center'>
                    <FormControlLabel control={<Switch checked={state.onlyActive} onChange={e => onActiveChanged(e.target.checked)} />} label="Show only active matches" />
                </Grid>
                <Box width='100%' />
                {
                    state.promoMatches.map(x =>
                        <Grid item xs={1} key={x.match.betupId}>
                            <PromoMatchCard {...x} />
                        </Grid>)
                }
            </Grid>
        </Stack >
    );
}