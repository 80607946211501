import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Divider, Rating } from '@mui/material';
import { formatRevenue } from '../Pages/HomePage';
import PromoMatchDto from '../models/dtos/PromoMatchDto';
import PromoMatchModel from '../models/PromoMatchModel';
import { getLocalizedString } from '../utils/LocalizationUtil';
import { useNavigate } from 'react-router-dom';

export default function PromoMatchCard(model: PromoMatchModel) {
    const navigate = useNavigate()
    let img = getLocalizedString("https://media.istockphoto.com/id/1435167377/photo/soccer-stadium-field-soccer-background.webp?b=1&s=170667a&w=0&k=20&c=Zvt9E628RczuRjHhFMF6sQLOqQlvFk5MjKoj2gSb7nE=", model.banner?.imgUrls);
    let title = getLocalizedString("No title", model.banner?.titles);
    let description = getLocalizedString("Description text is missing", model.banner?.descriptions);
    let callToAction = getLocalizedString("No call to action text", model.banner?.callToActions);
    let teamLogoSize = 32;
    let match = model.match
    let active = model.match.active
    return (
        <Card sx={{ width: '100%', height: '100%' }} >
            <CardContent sx={{ opacity: active ? 1.0 : 0.5, height: '350px' }}>
                <Typography variant="body1" color="text.secondary" sx={{ color: 'gray', textAlign: 'right' }}>
                    {new Date(match.date).toLocaleString()}
                </Typography>
                <img src={match.homeTeam?.photo_url} width={teamLogoSize} height={teamLogoSize} style={{ float: 'left', marginRight: '16px', marginTop: '-4px' }} />
                <Typography variant="body1" color="text.secondary" sx={{ color: 'back', textAlign: 'left' }}>
                    {match.homeTeam?.name}
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ color: 'black', textAlign: 'center' }}>VS</Typography>
                <img src={match.awayTeam?.photo_url} width={teamLogoSize} height={teamLogoSize} style={{ float: 'right', marginLeft: '16px', marginTop: '-4px' }} />
                <Typography variant="body1" color="text.secondary" sx={{ color: 'black', textAlign: 'right' }}>
                    {match.awayTeam?.name}
                </Typography>
                <br /><br />
                <Divider />
                {model.banner?.location == "Bets" ?
                    <React.Fragment>
                        <br />
                        <img src={img} height={64} width={64} style={{ float: 'left', marginRight: '16px', marginTop: '-4px' }} />
                        <b>{title}</b><br />
                        <p dangerouslySetInnerHTML={{ __html: description }} />
                        <Button size="small" variant="outlined" sx={{ textAlign: 'right' }} onClick={e => { if (match.clickUrl != undefined) window.open(match.clickUrl, "_blank", "noreferrer"); }}>
                            {callToAction}
                        </Button>
                        <br />
                    </React.Fragment> : <React.Fragment>
                    <br />
                        <div onClick={e => {if (match.clickUrl != undefined) window.open(match.clickUrl, "_blank", "noreferrer");}} style={{background:'#285e8d', padding:'4px', textAlign:'center', color: 'white', cursor: 'pointer'}}><b dangerouslySetInnerHTML={{ __html: description}}></b></div>
                        
                        </React.Fragment>}
                <br />
            </CardContent>
            <CardActions >
                <Button size="small" color="primary" variant="contained" sx={{ marginLeft: 'auto', color: 'white' }} onClick={e => navigate('/promo-matches/' + model.match.betupId + '/edit')}>
                    Edit
                </Button>
                <Button size="small" color="primary" variant="outlined" sx={{ marginLeft: 'auto' }} onClick={e => navigate('/promo-matches/' + model.match.betupId + '/stats')}>
                    View stats
                </Button>
            </CardActions>
        </Card>
    );
}