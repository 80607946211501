import * as React from 'react';
import Button from '@mui/material/Button';
import { Avatar, Box, Card, CardActions, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Pagination, Select, Stack, Switch, TextField, Typography, debounce } from '@mui/material';
import { approveAccountDeletionRequest, assignUserIdToDeletionRequest, cancelOffer, getAccountDeletionRequests, getFunnelList, getOffers, getPromoBanners, getTemplates, getUsersPaginated, rejectAccountDeletionRequest } from '../Api';
import { useNavigate } from 'react-router-dom';
import PromoBannerDto from '../models/dtos/PromoBannerDto';
import PromoBannerCard from '../components/PromoBannerCard';
import TemplateDto from '../models/dtos/TemplateDto';
import OfferDto from '../models/dtos/OfferDto';
import { DataGrid } from '@mui/x-data-grid/DataGrid/DataGrid';
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import FunnelDto from '../models/dtos/FunnelDto';
import FunnelConfigDto from '../models/dtos/FunnelConfigDto';
import { GridCellParams, GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { useCallback } from 'react';
import AccountDeletionRequestDto from '../models/dtos/AccountDeletionRequestDto';
import { rowSelectionStateInitializer } from '@mui/x-data-grid/internals';
import AccountMainInfoDto from '../models/dtos/AccountMainInfoDto';

export const formatRevenue = (revenue: number) => {
    return revenue.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
}

export default function UsersPage() {
    let defaultLimit = 25;
    const [state, setState] = React.useState({
        loaded: false,
        currentPage: 0,
        offset: 0,
        limit: defaultLimit,
        totalPages: 0,
        searchTerm: undefined as string | undefined,
        users: [] as AccountMainInfoDto[]
    });

    const navigate = useNavigate();

    const onUserClicked = async (userId: number) => {
        navigate('/users/' + userId);
    }

    const onMessageClicked = async (userId: number) => {
        navigate('/users/' + userId + '?tab=chat');
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'id', width: 128 },
        {
            field: 'photoUrl', headerName: 'avatar ', width: 130, renderCell: (params: GridCellParams) =>
                <React.Fragment><Avatar
                    src={params.row.photoUrl}
                    sx={{ width: 40, height: 40 }}
                /></React.Fragment>
        },
        { field: 'email', headerName: 'email', width: 250 },
        { field: 'name', headerName: 'name', width: 150 },
        {
            field: 'osType', headerName: 'OS', width: 128, renderCell: (params: GridCellParams) =>
                <React.Fragment><img src={params.row.osType == 'android' ? '/android_logo.png' : '/ios_logo.png'} width={40} height={40} /></React.Fragment>
        },
        {
            field: 'createdAt', headerName: 'CreatedAt', width: 200, valueGetter: (params: GridValueGetterParams) =>
                new Date(params.row.createdAt).toLocaleString()
        },
        {
            field: 'actions', headerName: 'Actions', width: 250, renderCell: (params: GridCellParams) =>
                <React.Fragment><Button variant="contained" fullWidth={true} style={{ height: '32px' }} onClick={() => onUserClicked(params.row.id)}>Details</Button>&nbsp;
                    <Button variant="outlined" fullWidth={true} style={{ height: '32px' }} onClick={() => onMessageClicked(params.row.id)}>Chat</Button></React.Fragment>
        },
    ];

    const onUserSearched = useCallback(debounce((searchTerm: string) => {
        fetchData(0, state.limit, searchTerm);
    }, 500), []);


    const fetchData = async (offset: number, limit: number, searchTerm?: string) => {
        setState(prevState => ({ ...prevState, loaded: false }));
        let response = await getUsersPaginated(offset, limit, !searchTerm || searchTerm.length == 0 ? undefined : searchTerm);
        let users = response?.items ?? [];
        let totalItems = response?.totalItems ?? 0;
        const totalPages = Math.ceil(totalItems / limit)
        const currentPage = totalItems > 0 ? Math.ceil((offset + limit) / limit) : 1;
        setState(prevState => ({ ...prevState, users: users, loaded: true, totalPages: totalPages, currentPage: currentPage }));
    }

    const onPageChanged = async (p: number) => {
        await fetchData((p - 1) * state.limit, state.limit, state.searchTerm);
    }

    React.useEffect(() => {
        fetchData(state.offset, state.limit, state.searchTerm);
    }, []);

    return (
        <div><Stack gap="50px 50px" sx={{ mx: 5, my: 5 }} justifyContent='center' alignItems='center'>
            <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4, lg: 6 }} alignItems='center'>
                <Grid item xs={0} sm={1} md={3} lg={5} />
                <Grid item xs={1} sm={1} md={1} lg={1}>
                    <Button variant="contained" fullWidth={true} style={{ height: '54px' }} onClick={() => navigate('/delete-account-requests')}>Account deletion requests</Button>
                </Grid>
            </Grid>
            <Typography variant="h3">Users</Typography>
            <TextField type='search' key="searchField" label="Enter name or email to search" variant="filled" fullWidth={true} value={state.searchTerm}
                onChange={e => { setState(prev => ({ ...prev, searchTerm: e.target.value })); onUserSearched(e.target.value); }}
            />
            <Pagination count={state.totalPages} size="medium" onChange={(e, p) => onPageChanged(p)} page={state.currentPage} />
            {!state.loaded ? <Grid item xs={2}><CircularProgress size='100px' style={{ marginBottom: '32px' }} /></Grid> : <DataGrid sx={{ width: '100%' }}
                rows={state.users}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 25 },
                    },
                }}
                pageSizeOptions={[5, 10]}
                hideFooterPagination

            />}
        </Stack >
        </div>
    );
}