import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, Button, CardActionArea, CardActions, Divider, Rating } from '@mui/material';
import { getLocalizedFromString, getLocalizedString, getLocalizedStringFor } from '../utils/LocalizationUtil';
import { useNavigate } from 'react-router-dom';
import VideoPlacementDto from '../models/dtos/VideoPlacementDto';
import VideoRewardOptionsDto from '../models/dtos/VideoRewardOptionsDto';

interface VideoPlacementProps {
    item: VideoPlacementDto,
    showEdit: boolean
}

const getRewardDescription = (options: VideoRewardOptionsDto) => {
    let description = '';

    if (options.type == 'Betcoins') {
        if (options.definedValue) {
            description = 'User receives ' + options.definedValue + ' Betcoins'
        } else {
            description = 'User receives x' + options.multiplier + ' of the referenced value (defined by the placement context)';
            if (options.continueForMaxAmountExceeded) {
                description += ', but not greater than ' + options.maxAmount + ' Betcoins';
            } else {
                description += ', if the possible calculated reward exceeds ' + options.maxAmount + ' Betcoins, video offer will not be avilable';
            }
        }
    } else {
        description = 'User receives the following bonus: ' + options.type;
    }

    return description;
}

const getTimingDescription = (item: VideoPlacementDto) => {
    let description = '';

    if (item.oneTimeOffer) {
        description = 'This offer is available only once (1 time / user)'
    } else {
        description = `This offer is available not more than ${item.maxTimesPerInterval} times each ${item.intervalInHours} hours`
    }

    return description;
}

const getStatsDescription = (item: VideoPlacementDto) => {
    return `${item.weeklyViews} views from ${item.weeklyUsers} users, paid: ${item.weeklyReward} Betcoins`;
}

export default function VideoPlacementCard(model: VideoPlacementProps) {
    const navigate = useNavigate()
    let opacity = model.item.enabled ? 1 : 0.5;

    let rewardDescription = model.item.rewardOptions.type == 'Betcoins'

    return (
        <Card sx={{ width: '100%', opacity: opacity }} >
            <Box sx={{ display: 'flex', alignItems: 'center', background: 'lightBlue', paddingLeft: '16px', paddingRight: '16px', minHeight: '100px' }}>
                <img src='https://cdn-icons-png.freepik.com/256/2859/2859705.png' width='32px' height='32px' />
                <Typography variant="h5" color="text.secondary" sx={{ color: 'black', marginLeft: '32px' }}>
                    {model.item.placementId}
                </Typography>
            </Box>
            <Divider />
            <CardContent sx={{ minHeight: 350 }}>
                <React.Fragment>
                    <br />
                    <Box sx={{ display: 'flex', alignItems: 'center', borderRadius: '5px', borderStyle: 'solid', background: 'lightGreen', paddingLeft: '16px', paddingRight: '16px', minHeight: '100px' }}>
                        <Typography variant="h6" color="#000000">{getRewardDescription(model.item.rewardOptions)}</Typography><br />
                    </Box>
                    <br />
                    <Box sx={{ display: 'flex', alignItems: 'center', borderRadius: '5px', borderStyle: 'solid', background: 'darkBlue', paddingLeft: '16px', paddingRight: '16px', minHeight: '100px' }}>
                        <Typography variant="h6" color="#ffffff">{getTimingDescription(model.item)}</Typography><br />
                    </Box>
                    <br />
                    {model.showEdit ? <React.Fragment>
                        <Typography variant="h6" display='block'>This week:</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', borderRadius: '5px', borderStyle: 'solid', paddingLeft: '16px', paddingRight: '16px', minHeight: '100px' }}>
                            <Typography variant="h6" display='block'>{getStatsDescription(model.item)}</Typography><br />
                        </Box>
                        <br /> <br /> </React.Fragment> : <div />
                    }
                    <Divider />
                </React.Fragment>
                <br />
            </CardContent>
            <CardActions >
                {
                    model.showEdit ?

                        <React.Fragment>
                            <Button size="small" color="primary" variant="outlined" sx={{ marginLeft: 'auto' }} onClick={e => navigate('/video-placements/' + model.item.placementId + '/stats')}>
                                Stats
                            </Button>
                            <Button size="small" color="primary" variant="contained" sx={{ marginLeft: 'auto', color: 'white' }} onClick={e => navigate('/video-placements/' + model.item.placementId + '/edit')}>
                                Edit
                            </Button></React.Fragment>
                        : <React.Fragment />
                }
            </CardActions>
        </Card >
    );
}
