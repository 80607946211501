import * as React from 'react';
import Button from '@mui/material/Button';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { loginUser } from '../Api';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../App';
import { setAccessToken, updateLocalUser } from '../UserStorage';

interface LoginState {
    email: string,
    password: string,
    emailError: string,
    passwordError: string,
    hasError: boolean
}

export default function LoginPage() {
    const [state, setState] = React.useState({ email: '', password: '', emailError: '', passwordError: '', hasError: false } as LoginState);
    const navigate = useNavigate();
    const context = React.useContext(UserContext);

    const login = () => {
        setState({ ...state, hasError: false, emailError: '', passwordError: '' });

        if (!isValidEmail(state.email)){
            setState({ ...state, emailError: "Invalid username format", hasError: true });
        }
        if (state.password.length < 6){
            setState({ ...state, passwordError: "Password is too short", hasError: true });
        }

        if (state.hasError)
            return;

        const handleLogin = async () => {
            let loginResult = await loginUser(state.email, state.password);
            if (loginResult != null) {
                setAccessToken(loginResult.authorization);
                updateLocalUser({id:"1", name:"Admin", email:"admin@betup.org",photoUrl:"none"});
                context.notifyContextUpdated();
                navigate("/");
            } else {
                setState({ ...state, passwordError: "Incorrect password", hasError: true });
            }
        }

        handleLogin();
    }

    function isValidEmail(email: string) {
        return email.length > 3;
    }

    return (
        <Stack gap="20px" sx={{ mx: 1 }} marginBottom="32px">
            <Typography variant="h2" component="h3" align="center" sx={{ my: 2 }}>Authorization</Typography>
            <Grid container spacing={2} columns={{ xs: 1, md: 3, lg: 5 }} justifyContent="center">
                <Grid item xs={1} md={2} key="email">
                    <TextField id="email" value={state.email} label="Enter the username" error={state.hasError} helperText={state.emailError} variant="filled" fullWidth={true} onChange={e => setState({ ...state, email: e.target.value })} />
                </Grid>
                <Box width="100%" />
                <Grid item xs={1} md={2} key="password">
                    <Box width="100%" />
                    <TextField id="password" value={state.password} label="Enter the password" type="password" error={state.hasError} helperText={state.passwordError} variant="filled" fullWidth={true} onChange={e => setState({ ...state, password: e.target.value })} />
                </Grid>
                <Box width="100%" />
                <Grid item xs={1} md={2} key="loginButton" alignContent="center">
                    <Button id="loginButton" variant="contained" fullWidth={true} style={{ height: '54px' }} onClick={login}>Sign In</Button>
                </Grid>
                <Box width="100%" />
            </Grid>
        </Stack >
    );
}