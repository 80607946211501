import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Divider, Rating } from '@mui/material';
import { formatRevenue } from '../Pages/HomePage';
import { useNavigate } from 'react-router-dom';
import { putFunnel } from '../Api';

interface FunnelConfigCardProps {
    id: string,
    name: string,
    onlyForPurchasers: boolean,
    tierForNonPurchasers?: string,
    funnelType: string,
    minTier: string,
    lifetime: string,
    cooldownTime: string,
    enabled: boolean,
    bonusSize: number,
    dailyLimit: number,
    localizationTemplate: string,
    activationEvents: string[]
}

export default function FunnelConfigCard(funnel: FunnelConfigCardProps) {
    const navigate = useNavigate();
    const [state, setState] = React.useState({funnel : funnel, enabled : funnel.enabled});

    const toggleAvailability = async (f: FunnelConfigCardProps, enabled : boolean) => {
        await putFunnel(f.id, { ...f, enabled: enabled });
        setState({...state, enabled: enabled});
    }

    return (
        <Card sx={{ width: '100%' }} >
            <CardContent sx={{ opacity: state.enabled ? 1.0 : 0.5 }}>
                <Typography gutterBottom variant="h6" component="div">
                    {funnel.name} ({funnel.funnelType}) +{funnel.bonusSize * 100}%
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ color: 'darkGray', textAlign: 'justify' }}>
                    Lifetime: {funnel.lifetime}, Cooldown: {funnel.cooldownTime}, <br/> Daily limit: {funnel.dailyLimit}
                </Typography>
                <Divider />
                <br />
                <Typography variant="body1" color="text.secondary" sx={{ color: 'darkBlue', textAlign: 'justify' }}>
                    OnlyForPurchasers: {String(funnel.onlyForPurchasers)}
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ color: 'darkBlue', textAlign: 'justify' }}>
                    Min tier: {funnel.minTier}
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ color: 'darkBlue', textAlign: 'justify' }}>
                    Tier for non purchasers: {funnel.tierForNonPurchasers}
                </Typography><br />
                <Divider /><br />
                <Typography variant="body1" color="text.secondary" sx={{ color: 'darkGreen', textAlign: 'justify' }}>
                    Activation: {funnel.activationEvents.join(', ')}
                </Typography><br />
                <Divider />
            </CardContent>
            <CardActions >
                <Button size="small" color="primary" variant="contained" sx={{ marginLeft: 'auto', color: 'white' }} onClick={e => toggleAvailability(state.funnel, !state.enabled)}>
                    {state.enabled ? 'Disable' : 'Enable'}
                </Button>
                <Button size="small" color="primary" variant="outlined" sx={{ marginLeft: 'auto' }} onClick={e => navigate('/funnels/' + funnel.id + '/edit')}>
                    Edit
                </Button>
            </CardActions>
        </Card>
    );
}