import * as React from 'react';
import Button from '@mui/material/Button';
import { Box, Card, CardActions, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography, debounce } from '@mui/material';
import { approveAccountDeletionRequest, assignUserIdToDeletionRequest, cancelOffer, getAccountDeletionRequests, getFunnelList, getOffers, getPromoBanners, getTemplates, rejectAccountDeletionRequest } from '../Api';
import { useNavigate } from 'react-router-dom';
import PromoBannerDto from '../models/dtos/PromoBannerDto';
import PromoBannerCard from '../components/PromoBannerCard';
import TemplateDto from '../models/dtos/TemplateDto';
import OfferDto from '../models/dtos/OfferDto';
import { DataGrid } from '@mui/x-data-grid/DataGrid/DataGrid';
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import FunnelDto from '../models/dtos/FunnelDto';
import FunnelConfigDto from '../models/dtos/FunnelConfigDto';
import { GridCellParams, GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { useCallback } from 'react';
import AccountDeletionRequestDto from '../models/dtos/AccountDeletionRequestDto';
import { rowSelectionStateInitializer } from '@mui/x-data-grid/internals';

export const formatRevenue = (revenue: number) => {
    return revenue.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
}

export default function AccountDeletionRequestsPage() {
    let defaultLimit = 1000;
    const [state, setState] = React.useState({
        loaded: false,
        requests: [] as AccountDeletionRequestDto[],
        state: 'Processing',
        idMappings: {} as { [k: string]: string | undefined },
        stateOptions: ['Processing', 'Rejected', 'Canceled'],
        dialogOpen: false,
        dialogTitle: "",
        dialogMsg: ""
    });

    const navigate = useNavigate();

    const onRejectRequestClicked = async (requestId: number) => {
        await rejectAccountDeletionRequest(requestId);
        await fetchData(state.state);
    }

    const onAcceptRequestClicked = async (requestId: number) => {
        let result = await approveAccountDeletionRequest(requestId);

        if (result != true) {
            setState(prevState => ({ ...prevState, dialogOpen: true, dialogMsg: "Something went wrong! Failed to complete account deletion request with id = " + requestId, dialogTitle: 'Error!' }));
        }

        await fetchData(state.state);
    }

    const onViewRequestClicked = async (from: string, msg: string) => {
        setState(prevState => ({ ...prevState, dialogOpen: true, dialogMsg: msg, dialogTitle: 'From : ' + from }));
    }

    const onAssignUserIdClicked = async (requestId: number, userId: string | undefined) => {
        if (userId == undefined || userId.length == 0) {
            setState(prevState => ({ ...prevState, dialogOpen: true, dialogMsg: "Invalid userId (it should a positive int value", dialogTitle: 'Error!' }));
            return;
        }

        let result = await assignUserIdToDeletionRequest(requestId, parseInt(userId));
        if (result != true) {
            setState(prevState => ({ ...prevState, dialogOpen: true, dialogMsg: "Something went wrong! Failed to assin UserId(" + userId + ") to request with id = " + requestId, dialogTitle: 'Error!' }));
        }

        await fetchData(state.state);
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'id', width: 64 },
        { field: 'email', headerName: 'email', width: 128 },
        { field: 'state', headerName: 'state', width: 128 },
        {
            field: 'userId', headerName: 'Identified UserId', width: 350, renderCell: (params: GridCellParams) =>
                <React.Fragment><TextField label="userId" variant="filled" value={state.idMappings[params.row.id]} style={{ width: '200px' }} onChange={e => { let newValue = parseInt(e.target.value).toString(); if (newValue == "NaN") newValue = ""; state.idMappings[params.row.id] = newValue; setState(prevState => ({ ...prevState, idMappings: prevState.idMappings })) }} />
                    <Button variant="outlined" style={{ height: '32px' }} onClick={() => onAssignUserIdClicked(params.row.id, state.idMappings[params.row.id])}>Assign</Button></React.Fragment>
        },
        {
            field: 'username', headerName: 'Identified Username ', width: 130
        },
        {
            field: 'userPhotoUrl', headerName: 'Identified Avatar ', width: 130, renderCell: (params: GridCellParams) =>
                <React.Fragment><img src={params.row.userPhotoUrl} width={32} height={32} /></React.Fragment>
        },
        {
            field: 'createdAt', headerName: 'CreatedAt', width: 200, valueGetter: (params: GridValueGetterParams) =>
                new Date(params.row.createdAt).toLocaleString()
        },
        {
            field: 'updatedAt', headerName: 'UpdatedAt', width: 200, valueGetter: (params: GridValueGetterParams) =>
                new Date(params.row.updatedAt).toLocaleString()
        },
        {
            field: 'message', headerName: 'Message', width: 200, renderCell: (params: GridCellParams) =>
                <React.Fragment><Button variant="contained" fullWidth={true} style={{ height: '32px' }} onClick={() => onViewRequestClicked(params.row.email, params.row.message)}>View</Button></React.Fragment>
        },
        {
            field: 'actions', headerName: 'Actions', width: 250, renderCell: (params: GridCellParams) =>
                params.row.state == 'Processing' ? <React.Fragment><Button variant="outlined" fullWidth={true} style={{ height: '32px' }} onClick={() => onRejectRequestClicked(params.row.id)}>Reject</Button>&nbsp;
                    <Button variant="contained" fullWidth={true} style={{ height: '32px' }} onClick={() => onAcceptRequestClicked(params.row.id)}>Accept</Button></React.Fragment> : <React.Fragment></React.Fragment>
        },
    ];


    const fetchData = async (state: string) => {
        let requests = await getAccountDeletionRequests(state) ?? [] as AccountDeletionRequestDto[];
        let idMappings = Object.fromEntries(requests.map(x => [x.id, x.userId?.toString()]));
        setState(prevState => ({ ...prevState, requests: requests, loaded: true, idMappings: idMappings }));
    }

    React.useEffect(() => {
        fetchData(state.state);
    }, []);


    const handleStateSelectionChanged = async (newState: string) => {
        setState(prevState => ({ ...prevState, state: newState }));
        await fetchData(newState);
    }

    const handleDialogClose = () => {
        setState(prevState => ({ ...prevState, dialogOpen: false }));
    }

    return (
        <div><Stack gap="50px 50px" sx={{ mx: 5, my: 5 }} justifyContent='center' alignItems='center'>
            <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4, lg: 5 }} justifyContent="flex-end">
                <Grid item xs={1} key="matchesButton" alignContent="center">
                    <Button variant="contained" fullWidth={true} style={{ height: '54px' }} onClick={() => navigate('/users')}>Users list</Button>
                </Grid>
            </Grid>
            <Typography variant="h3">Account deletion requests</Typography>
            {!state.loaded ? <Grid item xs={2}><CircularProgress size='25%' style={{ marginBottom: '32px', marginLeft: '35%' }} /></Grid> : <div />}
            <Grid container spacing={1} columns={{ xs: 1, sm: 1, md: 2, lg: 3, xl: 4 }} alignItems='center'>
                <Grid item xs={1} key="requestState" alignContent="center">
                    <FormControl fullWidth={true}>
                        <InputLabel id="demo-simple-select-label">{"Select request state"}</InputLabel>
                        <Select
                            fullWidth={true}
                            labelId="banner-simple-select-label"
                            id="min-tier-simple-select"
                            value={state.state}
                            onChange={e => handleStateSelectionChanged(e.target.value)}
                            label="Purchased">
                            {
                                state.stateOptions.map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Box width='100%' />
                <Grid item xs={0} sm={0} md={1} lg={2} />
                <Box width='100%' />
            </Grid>

            <DataGrid sx={{ width: '100%' }}
                rows={state.requests}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 25 },
                    },
                }}
                pageSizeOptions={[5, 10]}

            />
        </Stack >
            <Dialog
                open={state.dialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {state.dialogTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {state.dialogMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}