import { Box, Button, CircularProgress, Divider, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import BetlistShortDto from "../models/dtos/BetlistShortDto";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getBalancePaginated, getBetlistsPaginated, getMessageTemplates, getSupportMessages, getTemplates, sendMessageToUser } from "../Api";
import BalanceHistoryRecordDto from "../models/dtos/BalanceHistoryRecordDto";
import SupportMessageWithCommentCountDto from "../models/dtos/SupportMessageWithCommentCountDto";
import MessageCard from "./MessageCard";
import MessageTemplateDto from "../models/dtos/MessageTemplateDto";

interface SupportChatTableProps {
    userId: number
}

export default function SupportChatTable(props: SupportChatTableProps) {

    const [state, setState] = React.useState({
        messages: [] as SupportMessageWithCommentCountDto[],
        loaded: false,
        selectedTemplateLanguage: 'ru',
        selectedTemplate: 'Select template',
        templateLanguages: [] as string[],
        templates: [] as string[],
        templateCache: [] as MessageTemplateDto[],
        sendText: ''
    });


    const fetchData = async () => {
        let [records, templates] = await Promise.all([getSupportMessages(props.userId), getMessageTemplates()]);
        let langs = templates?.map(i => i.language) ?? [];
        let templateLanguage = langs[0];
        let templateValues = templates?.find(t => t.language == templateLanguage)?.values ?? [];
        setState(prevState => ({ ...prevState, messages: records?.content ?? [], loaded: records != null, selectedTemplateLanguage: templateLanguage, templates: templateValues, templateLanguages: langs, templateCache: templates ?? [] }));
    }

    const navigate = useNavigate();

    const getBalanceBackground = (oldBalance: number, newBalance: number) => {
        if (oldBalance > newBalance)
            return '#FFCCCB';
        else
            return 'lightGreen';
    }

    const sendMessageClicked = async () => {
        if (state.sendText == null || state.sendText.length < 3) {
            alert("Text is too short!");
            return;
        }
        setState(prevState => ({...prevState, loaded: false, sendText:''}));
        let result = await sendMessageToUser(props.userId, state.sendText);
        if (result) {
            await fetchData();
        } else {
            setState(prevState => ({...prevState, loaded: true}));
            alert("Somthing went wrong!");
        }
    }

    const onTemplateLanguageSelected = (langTemplate: string) => {
        let templateValues = state.templateCache?.find(t => t.language == langTemplate)?.values ?? [];
        setState(prevState => ({ ...prevState, templates: templateValues, selectedTemplateLanguage: langTemplate }));
    }

    const onTemplateSelected = (template: string) => {
        setState(prevState => ({ ...prevState, selectedTemplate: template, sendText: template }));
    }

    React.useEffect(() => {
        fetchData();
    }, []);

    let text = "fdsfdsfsdf";

    return (
        <Stack>
            {!state.loaded ? <CircularProgress size='100px' style={{ marginBottom: '32px' }} /> :
                <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4, lg: 6 }} alignItems='center'>
                    <Grid item xs={1} sm={2} md={4} lg={6}>
                        <TextField fullWidth={true} maxRows={5} rows={5} multiline value={state.sendText} onChange={e => setState(prevState => ({ ...prevState, sendText: e.target.value }))} />
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1}>
                        <Button variant="contained" fullWidth={true} style={{ height: '54px' }} onClick={() => sendMessageClicked()}>Send</Button>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1}>
                        <FormControl fullWidth>
                            <InputLabel id="template-language">{"Select template language"}</InputLabel>
                            <Select
                                fullWidth={true}
                                labelId="tempalte-select-lang"
                                id="t1"
                                value={state.selectedTemplateLanguage}
                                onChange={e => onTemplateLanguageSelected(e.target.value)}
                                label="Language">
                                {
                                    state.templateLanguages.map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1}>
                        <FormControl fullWidth>
                            <InputLabel id="template-value">{"Select template text"}</InputLabel>
                            <Select
                                fullWidth={true}
                                labelId="tempate-select-value"
                                id="t2"
                                value={state.selectedTemplate}
                                onChange={e => onTemplateSelected(e.target.value)}
                                label="Template">
                                {
                                    state.templates.map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Box width="100%" />
                    <Grid item xs={1} sm={2} md={4} lg={6}>
                        <Divider />
                    </Grid>
                    <Box width="100%" />
                    <Grid item xs={1} sm={2} md={4} lg={6}>
                        <Table>
                            <TableBody>
                                {state.messages.map(item => (
                                    <React.Fragment>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: "none", width: '45%' }}>
                                                {item.message.user.id != props.userId ?
                                                    <MessageCard message={item.message} align='left' /> : <div />}
                                            </TableCell>
                                            <TableCell sx={{ borderBottom: "none", width:'10%' }}>
                                            </TableCell>
                                            <TableCell sx={{ borderBottom: "none",  width: '45%' }}>
                                                {item.message.user.id == props.userId ?
                                                    <MessageCard message={item.message} align="right" /> : <div />}
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>}
        </Stack>);
}