import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Divider, Rating } from '@mui/material';
import { formatRevenue } from '../Pages/HomePage';
import PromoMatchDto from '../models/dtos/PromoMatchDto';
import PromoMatchModel from '../models/PromoMatchModel';
import { getLocalizedString } from '../utils/LocalizationUtil';
import { Link, useNavigate } from 'react-router-dom';
import ExtendedMatchDto from '../models/dtos/ExtendedMatchDto';
import { Timelapse } from '@mui/icons-material';

export default function MatchCard(match: ExtendedMatchDto) {
    const navigate = useNavigate()
    let teamLogoSize = 32;
    let timeLabelColor = match.matchState == 2 ? 'white' : 'black';
    let timeLabelBackColor = match.matchState == 2 ? 'red' : 'lightGrey';
    let timeLabel = match.matchState == 2 ? 'LIVE' : 'SCHEDULED';
    let cardBackground = match.hasPromo ? match.promoIsActive ? 'beige' : 'lavenderblush' : 'white';

    return (
        <Card sx={{ width: '100%', height: '100%' }} >
            <CardContent sx={{ height: '350px', background: cardBackground }}>
                <Typography variant="body1" color="text.secondary" sx={{ width: '120px', textAlign: 'center', background: timeLabelBackColor, color: timeLabelColor }}>
                    {timeLabel}
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ color: 'gray', textAlign: 'right' }}>
                    {new Date(match.date).toLocaleString()}
                </Typography>
                <br />
                <Divider />
                <br />
                <Typography variant="subtitle1" color="text.secondary" sx={{ color: 'black', textAlign: 'right' }}>
                    <u>{match.sport?.name}</u>
                </Typography>
                <img src={match.homeTeam?.photo_url} width={teamLogoSize} height={teamLogoSize} style={{ float: 'left', marginRight: '16px', marginTop: '-4px' }} />
                <Typography variant="body1" color="text.secondary" sx={{ color: 'back', textAlign: 'left' }}>
                    {match.homeTeam?.name}
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ color: 'black', textAlign: 'center' }}>VS</Typography>
                <img src={match.awayTeam?.photo_url} width={teamLogoSize} height={teamLogoSize} style={{ float: 'right', marginLeft: '16px', marginTop: '-4px' }} />
                <Typography variant="body1" color="text.secondary" sx={{ color: 'black', textAlign: 'right' }}>
                    {match.awayTeam?.name}
                </Typography>
                <br /><br />
                <Divider />
                <br />
                <Typography variant='body1' sx={{ textAlign: 'center' }}>
                    <b>Bets: {match.bets}  Views: {match.views}</b>
                </Typography>
                <br />
                {
                    match.hasPromo ? <React.Fragment>
                        <Typography variant="subtitle2" color="text.secondary" sx={{ textAlign: 'right' }}>
                            <i>{match.promoIsActive ? "Promo is active" : "Promo is inactive"}</i>
                        </Typography>
                    </React.Fragment> : <React.Fragment></React.Fragment>
                }
            </CardContent>
            <CardActions sx={{ background: cardBackground }}>
                <Button component={Link} size="small" color="primary" variant="contained" sx={{ marginLeft: 'auto', color: 'white' }} to={"/matches/" + match.betupId}>
                    Details
                </Button>
                {
                    !match.hasPromo ? <React.Fragment>
                        <Button size="small" color="primary" variant="contained" sx={{ marginLeft: 'auto', color: 'white' }} onClick={e => navigate('/promo-matches/create?betupMatchId=' + match.betupId)}>
                            Add promo
                        </Button>
                    </React.Fragment> :
                        <React.Fragment>
                            <Button size="small" color="primary" variant="contained" sx={{ marginLeft: 'auto', color: 'white' }} onClick={e => navigate('/promo-matches/' + match.betupId + '/edit')}>
                                Edit existing promo
                            </Button>
                            <Button size="small" color="primary" variant="outlined" sx={{ marginLeft: 'auto' }} onClick={e => navigate('/promo-matches/' + match.betupId + '/stats')}>
                                View stats
                            </Button>
                        </React.Fragment>
                }
            </CardActions>
        </Card>
    );
}