import * as React from 'react';
import Button from '@mui/material/Button';
import { Avatar, Box, Card, CardActions, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Pagination, Select, Stack, Switch, TextField, Typography, debounce } from '@mui/material';
import { approveAccountDeletionRequest, assignUserIdToDeletionRequest, cancelOffer, cancelRecalculateMatch, getAccountDeletionRequests, getFunnelList, getMatchRecalculationRequests, getOffers, getPromoBanners, getTemplates, getUsersPaginated, rejectAccountDeletionRequest } from '../Api';
import { useNavigate } from 'react-router-dom';
import PromoBannerDto from '../models/dtos/PromoBannerDto';
import PromoBannerCard from '../components/PromoBannerCard';
import TemplateDto from '../models/dtos/TemplateDto';
import OfferDto from '../models/dtos/OfferDto';
import { DataGrid } from '@mui/x-data-grid/DataGrid/DataGrid';
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import FunnelDto from '../models/dtos/FunnelDto';
import FunnelConfigDto from '../models/dtos/FunnelConfigDto';
import { GridCellParams, GridValueGetterParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { useCallback } from 'react';
import AccountDeletionRequestDto from '../models/dtos/AccountDeletionRequestDto';
import { rowSelectionStateInitializer } from '@mui/x-data-grid/internals';
import AccountMainInfoDto from '../models/dtos/AccountMainInfoDto';
import RecalculationRequestDto from '../models/dtos/RecalculationRequestDto';
import DetailedRecalculationRequestDto from '../models/dtos/DetailedRecalculationRequestDto';

export const formatRevenue = (revenue: number) => {
    return revenue.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
}

export default function RecalculationRequestsPage() {
    let defaultLimit = 25;
    const [state, setState] = React.useState({
        loaded: false,
        records: [] as DetailedRecalculationRequestDto[]
    });

    const navigate = useNavigate();

    const onCancelClicked = async (id: number) => {
        let result = await cancelRecalculateMatch(id);
        if (result){
            let response = await getMatchRecalculationRequests();
            setState(prevState => ({ ...prevState, records: response ?? [], loaded: response != null, }));
        }
        else
            alert("Something went wrong!");
    }

    const onMatchClicked = async (matchId: number) => {
        navigate('/matches/' + matchId + '?tab=varieties');
    }

    const getRequestStateText = (state: number) => {
        switch (state) {
            case 0: return "PENDING";
            case 1: return "COMPLETED";
            case 3: return "CANCELED";
            default: return "FAILED";
        }
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'id', width: 128 },
        {
            field: 'sport.photoUrl', headerName: 'avatar ', width: 130, renderCell: (params: GridCellParams) =>
                <React.Fragment><Avatar
                    src={params.row.match.sport.photo_url}
                    sx={{ width: 40, height: 40 }}
                /></React.Fragment>
        },
        {
            field: 'homeTeam.photoUrl', headerName: 'Home', width: 64, renderCell: (params: GridCellParams) =>
                <React.Fragment><Avatar
                    src={params.row.match.home_team.photo_url}
                    sx={{ width: 40, height: 40 }}
                /></React.Fragment>
        },
        { field: 'homeTeam.name', headerName: '', width: 150,  renderCell: (params: GridCellParams) =>
            <React.Fragment>{params.row.match.home_team.name}</React.Fragment> },
        {
            field: 'score', headerName: 'Score', width: 128, renderCell: (params: GridCellParams) =>
                <React.Fragment>{params.row.variety.scoreHome} - {params.row.variety.scoreAway}</React.Fragment>
        },
        { field: 'awayTeam.name', headerName: 'Away', width: 150,  renderCell: (params: GridCellParams) =>
            <React.Fragment>{params.row.match.away_team.name}</React.Fragment> },
        {
            field: 'awayTeam.photoUrl', headerName: '', width: 81, renderCell: (params: GridCellParams) =>
                <React.Fragment><Avatar
                    src={params.row.match.away_team.photo_url}
                    sx={{ width: 40, height: 40 }}
                /></React.Fragment>
        },
        {
            field: 'variety', headerName: 'Variety', width: 200, renderCell: (params: GridCellParams) =>
                <React.Fragment>{params.row.variety.name}</React.Fragment>
        },
        {
            field: 'requestedAt', headerName: 'RequestedAt', width: 200, valueGetter: (params: GridValueGetterParams) =>
                new Date(params.row.requestedAt).toLocaleString()
        },
        {
            field: 'state', headerName: 'State', width: 200, valueGetter: (params: GridValueGetterParams) =>
                getRequestStateText(params.row.state)+ ' ('+params.row.retrials+' retrials)'
        },
        {
            field: 'betsAffected', headerName: 'Bets affected', width: 150
        },
        {
            field: 'errorMsg', headerName: 'Error Msg', width: 200
        },
        {
            field: 'actions', headerName: 'Actions', width: 250, renderCell: (params: GridCellParams) =>
                <React.Fragment><Button disabled={params.row.state != 0} variant="contained" fullWidth={true} style={{ height: '32px' }} color='warning' onClick={() => onCancelClicked(params.row.id)}>Cancel</Button>&nbsp;
                    <Button variant="contained" fullWidth={true} style={{ height: '32px' }} onClick={() => onMatchClicked(params.row.match.id)}>Match info</Button></React.Fragment>
        },
    ];


    const fetchData = async () => {
        setState(prevState => ({ ...prevState, loaded: false }));
        let response = await getMatchRecalculationRequests();
        setState(prevState => ({ ...prevState, records: response ?? [], loaded: response != null, }));
    }


    React.useEffect(() => {
        fetchData();
    }, []);

    return (
        <div><Stack gap="50px 50px" sx={{ mx: 5, my: 5 }} justifyContent='center' alignItems='center'>
            <Grid container spacing={2} columns={{ xs: 1, sm: 2, md: 4, lg: 6 }} alignItems='center'>
                <Grid item xs={0} sm={1} md={3} lg={5} />
                <Grid item xs={1} sm={1} md={1} lg={1}>
                    <Button variant="contained" fullWidth={true} style={{ height: '54px' }} onClick={() => navigate('/matches')}>Go to matches</Button>
                </Grid>
            </Grid>
            <Typography variant="h3">Recalculation requests</Typography>
            {!state.loaded ? <Grid item xs={2}><CircularProgress size='100px' style={{ marginBottom: '32px' }} /></Grid> : <DataGrid sx={{ width: '100%' }}
                rows={state.records}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 25 },
                    },
                }}
                pageSizeOptions={[5, 10]}
                hideFooterPagination

            />}
        </Stack >
        </div>
    );
}