import { Button, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import BetlistShortDto from "../models/dtos/BetlistShortDto";
import React from "react";
import { useNavigate } from "react-router-dom";
import { cancelRecalculateMatch, getBalancePaginated, getBetlistsPaginated, getMatchVarietiesWithRequests, recalculateMatch } from "../Api";
import BalanceHistoryRecordDto from "../models/dtos/BalanceHistoryRecordDto";
import MatchVarietyDto from "../models/dtos/MatchVarietyDto";
import VarietyWithRequestsDto from "../models/dtos/VarietyWithRequestsDto";

interface VarietiesTableProps {
    matchId: number
}

export default function VarietiesTable(props: VarietiesTableProps) {

    const [state, setState] = React.useState({
        varieties: [] as VarietyWithRequestsDto[],
        loaded: false
    });

    const navigate = useNavigate();

    const fetchData = async (matchId: number) => {
        let result = await getMatchVarietiesWithRequests(matchId);
        setState(prevState => ({ ...prevState, varieties: result ?? [], loaded: result != null }))

    }

    const getRequestStateText = (state: number) => {
        switch (state) {
            case 0: return "PENDING";
            case 1: return "COMPLETED";
            case 3: return "CANCELED";
            default: return "FAILED";
        }
    }

    const getRequestActionText = (action : number) => {
        switch (action) {
            default : return "UNKNOWN";
            case 1: return 'UPDATE';
            case 2: return 'UPDATE & RECALCULATE';
            case 3: return 'RECALCULATE';
        }
    }

    const recalculate = async (varietyId: number, action: number) => {
        let score = state.varieties.find(x => x.variety.id == varietyId)?.variety.rawResult;
        let result = await recalculateMatch(varietyId, action, score);
        if (result) {
            await fetchData(props.matchId);
        } else {
            alert("Something went wrong!");
        }
    }

    const cancelRequest = async (requestId: number) => {
        let result = await cancelRecalculateMatch(requestId);
        if (result) {
            await fetchData(props.matchId);
        } else {
            alert("Something went wrong!");
        }
    }

    const setVarietyRawResult = (variety: VarietyWithRequestsDto, updatedResult: string) => {
        variety.variety.rawResult = updatedResult;
        setState(prevState => ({ ...prevState, varieties: state.varieties }));
    }

    React.useEffect(() => {
        fetchData(props.matchId);
    }, []);

    return (
        !state.loaded ? <CircularProgress size='100px' style={{ marginBottom: '32px' }} /> : <Table>
            <TableHead>
                <TableRow>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {state.varieties.map(item => (
                    <React.Fragment>
                        <TableRow sx={{ background: '#ececec' }}>
                            <TableCell>
                                <Typography variant="subtitle1">
                                    {item.variety.id}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle1">
                                    xbetId: {item.variety.xbetId}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle1">
                                    {item.variety.name}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle1">
                                    {item.variety.scoreHome} - {item.variety.scoreAway}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle1">
                                    Part: {item.variety.paramP}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle1">
                                    Type: {item.variety.paramT}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle1">
                                    Overtime: {item.variety.paramV}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle1">
                                    {item.variety.finished ? 'FINISHED' : 'PENDING'}</Typography>
                            </TableCell>
                            <TableCell>
                                <TextField label='Raw Result' variant="filled" value={item.variety.rawResult} inputProps={{ readOnly: !item.variety.finished }} fullWidth={true}
                                    onChange={e => setVarietyRawResult(item, e.target.value)} />
                            </TableCell>
                            <TableCell>
                                {item.variety.finished ?
                                    <React.Fragment>
                                        <Button variant='contained' style={{ height: '54px', width:'120px' }} color='info' onClick={() => recalculate(item.variety.id, 1)}>Update</Button>&nbsp;
                                        <Button variant='contained' style={{ height: '54px',width:'120px' }} color='warning' onClick={() => recalculate(item.variety.id, 2)}>Update & Recalculate</Button>
                                    </React.Fragment>
                                    : <div />}
                            </TableCell>
                        </TableRow>
                        {
                            item.requests.length > 0 ?
                                <TableRow>
                                    <TableCell colSpan={5}>Recalculation requests:</TableCell>
                                </TableRow> : <React.Fragment />
                        }
                        {item.requests.map(request => (
                            <TableRow>
                                <TableCell>{request.id}</TableCell>
                                <TableCell></TableCell>
                                <TableCell>{new Date(request.requestedAt).toLocaleString()}</TableCell>
                                <TableCell>{getRequestActionText(request.action)}</TableCell>
                                <TableCell>{getRequestStateText(request.state)} ({request.retrials} retrials)</TableCell>
                                <TableCell>{request.betsAffected} bets affected</TableCell>
                                <TableCell colSpan={2}>{request.newScore}</TableCell>
                                <TableCell>{request.errorMsg ? 'Error: '+request.errorMsg : ''}</TableCell>
                                <TableCell>
                                    {request.state == 0 ?
                                        <Button variant='contained' style={{ height: '54px', width:'120px' }} color='warning' onClick={() => cancelRequest(request.id)}>Cancel</Button>
                                        : <div />}
                                </TableCell>
                            </TableRow>
                        ))}
                    </React.Fragment>
                ))}
            </TableBody>
        </Table>);
}