import LoginUserDto from "./models/dtos/LoginUserDto";
import LoginResponseDto from "./models/dtos/LoginResponseDto";

import User from "./models/User";
import { PeriodType } from "./models/PeriodType";
import FullOverviewDto from "./models/dtos/FullOverviewDto";
import FunnelDto from "./models/dtos/FunnelDto";
import OverviewChartsDto from "./models/dtos/OverviewChartsDto";
import PromoMatchDto from "./models/dtos/PromoMatchDto";
import PromoBannerDto from "./models/dtos/PromoBannerDto";
import MatchDto from "./models/dtos/MatchDto";
import PaginatedMatchesResponseDto from "./models/dtos/PaginatedMatchesResponseDto";
import Localization from "./models/Localization";
import PromoMatchStat from "./models/dtos/PromoMatchStat";
import PromoMatchChart from "./models/dtos/PromoMatchChart";
import PromoMatchesStat from "./models/dtos/PromoMatchesStat";
import EditablePromoConfig from "./models/dtos/EditablePromoConfig";
import PaginatedMatchesDetailedResponseDto from "./models/dtos/PaginatedMatchesDetailedResponseDto";
import { getAccessToken } from "./UserStorage";
import FunnelConfigDto from "./models/dtos/FunnelConfigDto";
import UpdateFunnelDto from "./models/dtos/UpdateFunnelDto";
import FunnelOptions from "./models/dtos/FunnelOptions";
import TemplateDto from "./models/dtos/TemplateDto";
import PaginatedOffersResponseDto from "./models/dtos/PaginatedOffersResponseDto";
import AccountDeletionRequestDto from "./models/dtos/AccountDeletionRequestDto";
import ShopItemDto from "./models/dtos/ShopItemDto";
import ShopEffectDto from "./models/dtos/ShopEffectDto";
import StoreCodeCheckResult from "./models/dtos/StoreCodeCheckResult";
import VideoPlacementDto from "./models/dtos/VideoPlacementDto";
import VideoRewardOptionsDto from "./models/dtos/VideoRewardOptionsDto";
import VideoPlacementStatsDto from "./models/dtos/VideoPlacementStatsDto";
import SummaryVideoPlacementStatsDto from "./models/dtos/SummaryVideoPlacementStatsDto";
import PaginatedUsersResponseDto from "./models/dtos/PaginatedUsersResponseDto";
import AggregateUserInfoDto from "./models/dtos/AggregateUserInfoDto";
import PaginatedBetlistsResponse from "./models/dtos/PaginatedBetlistsResponse";
import PaginatedBalanceHistoryResponseDto from "./models/dtos/PaginatedBalanceHistoryResponseDto";
import BetlistShortDto from "./models/dtos/BetlistShortDto";
import SupportChatWithUserDto from "./models/dtos/SupportChatWithUserDto";
import MessageTemplateDto from "./models/dtos/MessageTemplateDto";
import PaginatedSupportChatsDto from "./models/dtos/PaginatedSupportChatsDto";
import MatchInfoDto from "./models/dtos/MatchInfoDto";
import PaginatedMatchesListResponseDto from "./models/dtos/PaginatedMatchesListResponseDto";
import AggregatedMatchInfoDto from "./models/dtos/AggregatedMatchInfoDto";
import VarietyWithRequestsDto from "./models/dtos/VarietyWithRequestsDto";
import PaginatedMatchBetsDto from "./models/dtos/PaginatedMatchBetsDto";
import RecalculationRequestDto from "./models/dtos/RecalculationRequestDto";
import DetailedRecalculationRequestDto from "./models/dtos/DetailedRecalculationRequestDto";

const BASE_URL = 'https://betup.org:4590';
//const BASE_URL = 'https://localhost:4590';

const fetchFromApi = (path: string) => {
    let accessToken = getAccessToken()
    if (accessToken == null || undefined)
        return fetch(BASE_URL + path)
    else
        return fetch(BASE_URL + path, { method: 'GET', headers: { 'Authorization': accessToken } });
}

export const loginUser = (username: string, password: string) => {
    return fetch(BASE_URL + '/api/dashboard/login', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ username: username, password: password }) }).then(response => response.ok ? response.json() : null).then(data => data as LoginResponseDto);
}

export const getStats = () => {
    return fetchFromApi('/api/dashboard/overview').then(response => response.json().then(data => data as FullOverviewDto));
}

export const getFunnels = () => {
    return fetchFromApi('/api/dashboard/offers/funnels').then(response => response.json().then(data => data as FunnelDto[]));
}

export const getFunnelList = () => {
    return fetchFromApi('/api/dashboard/offers/funnels/list').then(response => response.json().then(data => data as FunnelConfigDto[]));
}

export const getFunnel = (id: string) => {
    return fetchFromApi('/api/dashboard/offers/funnels/' + id + '/info').then(response => response.json().then(data => data as FunnelConfigDto));
}

export const getFunnelOptions = () => {
    return fetchFromApi('/api/dashboard/offers/funnels/options').then(response => response.json().then(data => data as FunnelOptions));
}

export const getTemplates = () => {
    return fetchFromApi('/api/dashboard/offers/funnels/templates').then(response => response.json().then(data => data as TemplateDto[]));
}

export const getTemplate = (id: string) => {
    return fetchFromApi('/api/dashboard/offers/funnels/templates/' + id).then(response => response.json().then(data => data as TemplateDto));
}

export const getOverviewCharts = () => {
    return fetchFromApi('/api/dashboard/overview/charts').then(response => response.json().then(data => data as OverviewChartsDto));
}

export const getUserAggregatedInfo = (id: number) => {
    return fetchFromApi('/api/dashboard/accounts/users/' + id).then(response => response.ok ? response.json().then(data => data as AggregateUserInfoDto) : null);
}

export const getUsersPaginated = (offset: number, limit: number, searchTerm?: string) => {
    return fetchFromApi('/api/dashboard/accounts/users?offset=' + offset + '&limit=' + limit + (searchTerm != null ? '&searchTerm=' + searchTerm : '')).then(response => response.ok ? response.json().then(data => data as PaginatedUsersResponseDto) : null);
}

export const getSupportChatsPaginated = (offset: number, limit: number) => {
    return fetchFromApi('/api/dashboard/support?offset=' + offset + '&pageSize=' + limit).then(response => response.ok ? response.json().then(data => data as PaginatedSupportChatsDto) : null);
}

export const getBetlistsPaginated = (userId: number, offset: number, limit: number) => {
    return fetchFromApi('/api/dashboard/accounts/users/' + userId + '/betlists?offset=' + offset + '&limit=' + limit).then(response => response.ok ? response.json().then(data => data as PaginatedBetlistsResponse) : null);
}

export const getBalancePaginated = (userId: number, offset: number, limit: number) => {
    return fetchFromApi('/api/dashboard/accounts/users/' + userId + '/balanceHistoryRecords?offset=' + offset + '&limit=' + limit).then(response => response.ok ? response.json().then(data => data as PaginatedBalanceHistoryResponseDto) : null);
}

export const getSupportMessages = (userId: number) => {
    return fetchFromApi('/api/dashboard/support/' + userId + '/messages').then(response => response.ok ? response.json().then(data => data as SupportChatWithUserDto) : null);
}

export const getMessageTemplates = () => {
    return fetchFromApi('/api/dashboard/accounts/messageTemplates').then(response => response.ok ? response.json().then(data => data as MessageTemplateDto[]) : null);
}

export const getPromoMatches = (onlyActive: boolean, offset: number, limit: number, searchTerm: string) => {
    return fetchFromApi('/api/dashboard/promo/matches' + (onlyActive ? '' : '/all') + '?offset=' + offset + '&limit=' + limit + '&searchTerm=' + searchTerm).then(response => response.ok ? response.json().then(data => data as PaginatedMatchesResponseDto) : null);
}

export const getOffers = (offset: number, limit: number, userId?: string, funnelId?: string, purchased?: boolean, onlyActive?: Boolean) => {
    return fetchFromApi('/api/dashboard/offers/recent?' + 'offset=' + offset + '&limit=' + limit + (userId != null ? '&userId=' + userId : '') + (funnelId != null ? '&funnelId=' + funnelId : '') + (purchased != null ? '&purchased=' + purchased : '') + '&onlyActive=' + (onlyActive ?? false)).then(response => response.ok ? response.json().then(data => data as PaginatedOffersResponseDto) : null);
}

export const getTopMatches = (offset: number, limit: number, period: string) => {
    return fetchFromApi('/api/dashboard/matches/top?offset=' + offset + '&limit=' + limit + '&periodType=' + period).then(response => response.ok ? response.json().then(data => data as PaginatedMatchesDetailedResponseDto) : null);
}

export const getPromoMatch = (matchId: number) => {
    return fetchFromApi('/api/dashboard/promo/matches/' + matchId).then(response => response.ok ? response.json().then(data => data) : null);
}

export const getPromoBanners = () => {
    return fetchFromApi('/api/dashboard/promo/banners').then(response => response.ok ? response.json().then(data => data as PromoBannerDto[]) : []);
}

export const getPromoBanner = (bannerId: string) => {
    return fetchFromApi('/api/dashboard/promo/banners/' + bannerId).then(response => response.ok ? response.json().then(data => data as PromoBannerDto) : null);
}

export const getPromoConfig = () => {
    return fetchFromApi('/api/dashboard/promo/config').then(response => response.ok ? response.json().then(data => data as EditablePromoConfig) : null);
}

export const getSearchMatches = (searchTerm: string) => {
    return fetchFromApi('/api/dashboard/search/matches?searchTerm=' + searchTerm).then(response => response.json().then(data => data as MatchDto[]));
}

export const paginateMatches = (offset: number, limit: number, before: string, after: string, matchState: number, searchTerm?: string) => {
    return fetchFromApi('/api/dashboard/matchCalculation/matches?' + (searchTerm ? 'searchTerm=' + searchTerm + '&' : '') + 'offset=' + offset + '&limit=' + limit + '&before=' + before + '&after=' + after).then(response => response.json().then(data => data as PaginatedMatchesListResponseDto));
}

export const getMatchDetails = (matchId: number) => {
    return fetchFromApi('/api/dashboard/matches/' + matchId).then(response => response.json().then(data => data as MatchDto));
}

export const getMatchPromoLink = (matchId: number) => {
    return fetchFromApi('/api/dashboard/promo/matches/' + matchId + '/link').then(response => response.ok ? response.text() : null);
}

export const getPromoMatchStats = (matchId: number, period: string) => {
    return fetchFromApi('/api/dashboard/promo/matches/' + matchId + '/stats/' + period).then(response => response.ok ? response.json().then(data => data as PromoMatchStat) : null);
}

export const getPromoMatchesStats = (period: string) => {
    return fetchFromApi('/api/dashboard/promo/matches/stats/' + period).then(response => response.ok ? response.json().then(data => data as PromoMatchesStat) : null);
}

export const getPromoMatchCharts = (matchId: number) => {
    return fetchFromApi('/api/dashboard/promo/matches/' + matchId + '/charts').then(response => response.ok ? response.json().then(data => data as PromoMatchChart) : null);
}

export const getPromoMatchesCharts = () => {
    return fetchFromApi('/api/dashboard/promo/matches/charts').then(response => response.ok ? response.json().then(data => data as PromoMatchChart) : null);
}

export const getFullMatchInfo = (matchId: number) => {
    return fetchFromApi('/api/dashboard/matches/' + matchId + '/full').then(response => response.ok ? response.json().then(data => data as AggregatedMatchInfoDto) : null);
}

export const getMatchVarietiesWithRequests = (matchId: number) => {
    return fetchFromApi('/api/dashboard/matches/' + matchId + '/requests').then(response => response.ok ? response.json().then(data => data as VarietyWithRequestsDto[]) : null);
}

export const getMatchBets = (matchId: number, offset : number, limit : number) => {
    return fetchFromApi('/api/dashboard/matches/' + matchId + '/bets?offset='+offset+'&limit='+limit).then(response => response.ok ? response.json().then(data => data as PaginatedMatchBetsDto) : null);
}

export const putMatch = async (matchId: number, bannerId: string, countriesAvailable: string[], languagesAvailable: string[], active?: Boolean) => {
    try {
        let activeState = active ?? true;
        let accessToken = getAccessToken()
        if (accessToken == null)
            return false

        let result = await fetch(BASE_URL + '/api/dashboard/promo/matches/' + matchId, { method: 'PUT', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken }, body: JSON.stringify({ bannerId: bannerId, countriesAvailable: countriesAvailable, languagesAvailable: languagesAvailable, active: activeState }) }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}

export const createBanner = async (name: string, titles: Localization, descriptions: Localization, imgUrls: Localization, callToActions: Localization, location: string) => {
    try {
        let accessToken = getAccessToken()
        if (accessToken == null)
            return false
        let result = await fetch(BASE_URL + '/api/dashboard/promo/banners', { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken }, body: JSON.stringify({ name: name, descriptions: descriptions, titles: titles, callToActions: callToActions, imgUrls: imgUrls, location: location }) }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}

export const putBanner = async (bannerId: string, name: string, titles: Localization, descriptions: Localization, imgUrls: Localization, callToActions: Localization, location: string) => {
    try {
        let accessToken = getAccessToken()
        if (accessToken == null)
            return false
        let result = await fetch(BASE_URL + '/api/dashboard/promo/banners/' + bannerId, { method: 'PUT', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken }, body: JSON.stringify({ name: name, descriptions: descriptions, titles: titles, callToActions: callToActions, imgUrls: imgUrls, location: location }) }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}

export const putFunnel = async (id: string, dto: UpdateFunnelDto) => {
    try {
        let accessToken = getAccessToken()
        if (accessToken == null)
            return false
        let result = await fetch(BASE_URL + '/api/dashboard/offers/funnels/' + id, { method: 'PUT', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken }, body: JSON.stringify(dto) }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}

export const cancelOffer = async (id: string) => {
    try {
        let accessToken = getAccessToken()
        if (accessToken == null)
            return false

        let result = await fetch(BASE_URL + '/api/dashboard/offers/' + id + '/cancel', { method: 'PATCH', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken } }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}

export const createFunnel = async (dto: UpdateFunnelDto) => {
    try {
        let accessToken = getAccessToken()
        if (accessToken == null)
            return false
        let result = await fetch(BASE_URL + '/api/dashboard/offers/funnels', { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken }, body: JSON.stringify(dto) }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}

export const createOrUpdateTemplate = async (dto: TemplateDto) => {
    try {
        let accessToken = getAccessToken()
        if (accessToken == null)
            return false
        let result = await fetch(BASE_URL + '/api/dashboard/offers/funnels/templates', { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken }, body: JSON.stringify(dto) }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}

export const deleteTemplate = async (id: string) => {
    try {
        let accessToken = getAccessToken()
        if (accessToken == null)
            return false
        let result = await fetch(BASE_URL + '/api/dashboard/offers/funnels/templates/' + id, { method: 'DELETE', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken } }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}

export const deleteFunnel = async (id: string) => {
    try {
        let accessToken = getAccessToken()
        if (accessToken == null)
            return false
        let result = await fetch(BASE_URL + '/api/dashboard/offers/funnels/' + id, { method: 'DELETE', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken } }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}

export const updateConfig = async (enabled: boolean, baseUrl: string) => {
    try {
        let accessToken = getAccessToken()
        if (accessToken == null)
            return false
        let result = await fetch(BASE_URL + '/api/dashboard/promo/config', { method: 'PUT', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken }, body: JSON.stringify({ enabled: enabled, baseUrl: baseUrl }) }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}

export const deleteMatch = async (matchId: number) => {
    try {
        let accessToken = getAccessToken()
        if (accessToken == null)
            return false
        let result = await fetch(BASE_URL + '/api/dashboard/promo/matches/' + matchId, { method: 'DELETE', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken } }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}


export const deleteBanner = async (bannerId: string) => {
    try {
        let accessToken = getAccessToken()
        if (accessToken == null)
            return false
        let result = await fetch(BASE_URL + '/api/dashboard/promo/banners/' + bannerId, { method: 'DELETE', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken } }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}

export const sendDeleteAccountRequest = async (email: string, message: string) => {
    try {
        let result = await fetch(BASE_URL + '/api/dashboard/accounts/deletionRequests', { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ email: email, message: message }) }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}

export const getAccountDeletionRequests = (state: string) => {
    return fetchFromApi('/api/dashboard/accounts/deletionRequests?' + 'state=' + state).then(response => response.ok ? response.json().then(data => data as AccountDeletionRequestDto[]) : null);
}

export const approveAccountDeletionRequest = async (id: number) => {
    try {
        let accessToken = getAccessToken()
        if (accessToken == null)
            return false
        let result = await fetch(BASE_URL + '/api/dashboard/accounts/deletionRequests/' + id + '/complete', { method: 'PATCH', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken }, body: JSON.stringify({}) }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}

export const rejectAccountDeletionRequest = async (id: number) => {
    try {
        let accessToken = getAccessToken()
        if (accessToken == null)
            return false
        let result = await fetch(BASE_URL + '/api/dashboard/accounts/deletionRequests/' + id + '/reject', { method: 'PATCH', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken }, body: JSON.stringify({}) }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}

export const assignUserIdToDeletionRequest = async (id: number, userId: number) => {
    try {
        let accessToken = getAccessToken()
        if (accessToken == null)
            return false
        let result = await fetch(BASE_URL + '/api/dashboard/accounts/deletionRequests/' + id + '/users/' + userId + '/assign', { method: 'PATCH', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken }, body: JSON.stringify({}) }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}


export const getShopProducts = () => {
    return fetchFromApi('/api/dashboard/shop/products').then(response => response.ok ? response.json().then(data => data as ShopItemDto[]) : null);
}

export const getShopEffects = () => {
    return fetchFromApi('/api/dashboard/shop/effects').then(response => response.ok ? response.json().then(data => data as ShopEffectDto[]) : null);
}

export const getShopProduct = (id: number) => {
    return fetchFromApi('/api/dashboard/shop/products/' + id).then(response => response.ok ? response.json().then(data => data as ShopItemDto) : null);
}

export const createShopProduct = async (storeCode: string, type: string, betcoins: number, visualPrice: string, noAds: boolean, interestFactor: number, effectId?: number, titleText?: string, iconUrl?: string, color?: string, effectDuration?: number) => {
    try {
        let accessToken = getAccessToken();
        if (accessToken == null)
            return false;
        let result = await fetch(BASE_URL + '/api/dashboard/shop/products', {
            method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken },
            body: JSON.stringify({ storeCode: storeCode, iconUrl: iconUrl, type: type, titleText: titleText, color: color, betcoins: betcoins, visualPrice: visualPrice, effectId: effectId, noAds: noAds, interestFactor: interestFactor, effectDuration: effectDuration })
        }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}

export const updateShopProduct = async (id: number, storeCode: string, type: string, betcoins: number, visualPrice: string, noAds: boolean, interestFactor: number, effectId?: number, titleText?: string, iconUrl?: string, color?: string, effectDuration?: number) => {
    try {
        let accessToken = getAccessToken();
        if (accessToken == null)
            return false;
        let result = await fetch(BASE_URL + '/api/dashboard/shop/products/' + id, {
            method: 'PATCH', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken },
            body: JSON.stringify({ storeCode: storeCode, iconUrl: iconUrl, type: type, titleText: titleText, color: color, betcoins: betcoins, visualPrice: visualPrice, effectId: effectId, noAds: noAds, interestFactor: interestFactor, effectDuration: effectDuration })
        }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}

export const disableShopProduct = async (id: number) => {
    try {
        let accessToken = getAccessToken();
        if (accessToken == null)
            return false
        let result = await fetch(BASE_URL + '/api/dashboard/shop/products/' + id, { method: 'DELETE', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken } }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}

export const enableShopProduct = async (id: number) => {
    try {
        let accessToken = getAccessToken();
        if (accessToken == null)
            return false
        let result = await fetch(BASE_URL + '/api/dashboard/shop/products/' + id + '/enable', { method: 'PATCH', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken } }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}

export const checkStoreCode = async (storeCode: string, productIdAssigned: number) => {
    return fetchFromApi('/api/dashboard/shop/checkStoreCode?storeCode=' + storeCode + "&productIdAssigned=" + productIdAssigned).then(response => response.ok ? response.json().then(data => data as StoreCodeCheckResult) : null);
}

export const getVideoPlacements = () => {
    return fetchFromApi('/api/dashboard/videoPlacements').then(response => response.ok ? response.json().then(data => data as VideoPlacementDto[]) : null);
}

export const getVideoPlacement = (id: string) => {
    return fetchFromApi('/api/dashboard/videoPlacements/' + id).then(response => response.ok ? response.json().then(data => data as VideoPlacementDto) : null);
}

export const getVideoPlacementStats = (id: string) => {
    return fetchFromApi('/api/dashboard/videoPlacements/' + id + '/stats').then(response => response.ok ? response.json().then(data => data as VideoPlacementStatsDto) : null);
}

export const getVideoPlacementsStats = (period: string) => {
    return fetchFromApi('/api/dashboard/videoPlacements/stats?periodType=' + period).then(response => response.ok ? response.json().then(data => data as SummaryVideoPlacementStatsDto) : null);
}

export const getMatchRecalculationRequests = () => {
    return fetchFromApi('/api/dashboard/matchCalculation/requests').then(response => response.ok ? response.json().then(data => data as DetailedRecalculationRequestDto[]) : null);
}

export const updateVideoPlacement = async (id: string, intervalInHours: number, maxTimesPerInterval: number, oneTimeOffer: boolean, options: VideoRewardOptionsDto, enabled: boolean) => {
    try {
        let accessToken = getAccessToken();
        if (accessToken == null)
            return false
        let result = await fetch(BASE_URL + '/api/dashboard/videoPlacements/' + id,
            {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json', 'Authorization': accessToken },
                body: JSON.stringify({ intervalInHours: intervalInHours, maxTimesPerInterval: maxTimesPerInterval, oneTimeOffer: oneTimeOffer, rewardOptions: options, enabled: enabled })
            }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}

export const enableVideoPlacement = async (id: string) => {
    try {
        let accessToken = getAccessToken();
        if (accessToken == null)
            return false
        let result = await fetch(BASE_URL + '/api/dashboard/videoPlacements/' + id + '/enable', { method: 'PATCH', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken } }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}

export const disableVideoPlacement = async (id: string) => {
    try {
        let accessToken = getAccessToken();
        if (accessToken == null)
            return false
        let result = await fetch(BASE_URL + '/api/dashboard/videoPlacements/' + id + '/disable', { method: 'PATCH', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken } }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}

export const changeAdsStatusForUser = async (id: number, enabled: boolean) => {
    try {
        let accessToken = getAccessToken();
        if (accessToken == null)
            return false
        let result = await fetch(BASE_URL + '/api/dashboard/accounts/users/' + id + '/ads?adsEnabled=' + enabled, { method: 'PUT', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken } }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}

export const updateUserBalance = async (id: number, balance: number) => {
    try {
        let accessToken = getAccessToken();
        if (accessToken == null)
            return false
        let result = await fetch(BASE_URL + '/api/dashboard/accounts/users/' + id + '/balance?newValue=' + balance, { method: 'PUT', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken } }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}

export const addUserEffects = async (id: number) => {
    try {
        let accessToken = getAccessToken();
        if (accessToken == null)
            return false
        let result = await fetch(BASE_URL + '/api/dashboard/accounts/users/' + id + '/effects', { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken } }).then(response => response.ok ? response.text() : null).then(data => data ? parseInt(data) : null);
        return result;
    } catch (e) {
        return false;
    }
}

export const updateBetStateForBetlist = async (userId: number, betlistId: number, betId: number, state: number) => {
    try {
        let accessToken = getAccessToken();
        if (accessToken == null)
            return false
        let result = await fetch(BASE_URL + '/api/dashboard/accounts/users/' + userId + '/betlists/' + betlistId + '/bets/' + betId + '?newBetState=' + state, { method: 'PATCH', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken } }).then(response => response.ok ? response.json() : null).then(data => data as BetlistShortDto);
        return result;
    } catch (e) {
        return false;
    }
}


export const recalculateMatch = async (varietyId: number, action: number, newScore?: string) => {
    try {
        let accessToken = getAccessToken();
        if (accessToken == null)
            return false
        let result = await fetch(BASE_URL + '/api/dashboard/matchCalculation/requests', { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken }, body: JSON.stringify({ varietyId: varietyId, action: action, newScore: newScore }) }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}

export const cancelRecalculateMatch = async (requestId: number) => {
    try {
        let accessToken = getAccessToken();
        if (accessToken == null)
            return false
        let result = await fetch(BASE_URL + '/api/dashboard/matchCalculation/requests/'+requestId, { method: 'DELETE', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken } }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}

export const sendMessageToUser = async (userId: number, message: string) => {
    try {
        let accessToken = getAccessToken();
        if (accessToken == null)
            return false
        let result = await fetch(BASE_URL + '/api/dashboard/support/' + userId + '/messages', { method: 'POST', headers: { 'Content-Type': 'application/json', 'Authorization': accessToken }, body: message }).then(response => response.ok ? true : false);
        return result;
    } catch (e) {
        return false;
    }
}