import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../App";

export default function LogoutPage() {
    let context = useContext(UserContext);
    if (context.isAuthenticated) {
        localStorage.clear();
        context.notifyContextUpdated();
    }
    return <Navigate to='/' />;
}